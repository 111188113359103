import React, { useCallback, useMemo, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { createUseStyles } from 'react-jss';

import { Button } from '../../Button';
import { useComparisonProviderContext } from '../../../providers/ComparisonProvider';
import type { LcpnTheme } from '../../../styles/theme';
import { ClipboardButton } from '../../ClipboardButton';

import { HeatmapComparisonTable } from './HeatmapComparisonTable';
import { PoiComparisonTable } from './PoiComparisonTable';
import { Disclaimer } from './Disclaimer';
import { Footer } from './Footer';

interface Props {
  type: 'heatmap' | 'poi';
}

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  comparisonContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 147px)',
    background: '#F0F0F0',
    overflowY: 'scroll',
  },
  comparisonTableWrapper: {
    boxSizing: 'border-box',
    '& *': { boxSizing: 'border-box' },
    backgroundColor: 'white',
    maxWidth: 1200,
    margin: '32px auto',
    padding: 32,
  },
  printContainer: {
    '& *': { boxSizing: 'border-box !important' },
    webkitPrintColorAdjust: 'exact !important',
    printColorAdjust: 'exact !important',
    padding: 0,
  },
  buttons: {
    marginBottom: 16,
    '& > *': {
      padding: '8px 16px !important',
    },
  },
}));

export function ComparisonTable(props: Props) {
  const { type } = props;
  const classes = useStyles();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: classes.printContainer,
    pageStyle: '@page { size: auto, margin: 10mm;  }',
  });

  const {
    heatMapComparison,
    setHeatMapComparison,
    poiComparison,
    setPoiComparison,
  } = useComparisonProviderContext();

  const resetPicker = useCallback(() => {
    switch (type) {
      case 'heatmap': {
        setHeatMapComparison({});
        break;
      }
      case 'poi': {
        setPoiComparison({});
        break;
      }
      default: {
        break;
      }
    }
  }, [type, setHeatMapComparison, setPoiComparison]);

  const content = useMemo(() => {
    switch (type) {
      case 'heatmap': {
        return <HeatmapComparisonTable />;
      }
      case 'poi': {
        return <PoiComparisonTable />;
      }
      default: {
        return null;
      }
    }
  }, [type]);

  const shareUrl = useMemo(() => {
    switch (type) {
      case 'heatmap': {
        return (
          process.env.REACT_APP_CAMPAIGN_URL +
          '?compareHeatMap=' +
          `${heatMapComparison.fromCountryCode},${heatMapComparison.toCountryCode}`
        );
      }
      case 'poi': {
        return (
          process.env.REACT_APP_CAMPAIGN_URL +
          '?comparePoi=' +
          `${poiComparison.fromStudyId},${poiComparison.toStudyId}`
        );
      }
      default: {
        return '';
      }
    }
  }, [type, heatMapComparison, poiComparison]);

  return (
    <div className={classes.comparisonContainer}>
      <div className={classes.comparisonTableWrapper}>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          justifyContent={'flex-end'}
          className={classes.buttons}
        >
          <Button
            bgColor={'21, 81, 129'}
            style={{
              marginRight: 'auto',
              color: 'white',
            }}
            label={'Reset/Back'}
            onClick={resetPicker}
          />
          <Button
            label={'Print'}
            onClick={handlePrint}
            style={{ border: '2px solid #155181' }}
          />
          <ClipboardButton
            clipboardText={shareUrl}
            style={{ border: '2px solid #155181' }}
          >
            Share
          </ClipboardButton>
        </Stack>
        <div ref={componentRef}>
          <Stack spacing={4}>
            <Box>{content}</Box>

            <Disclaimer />

            <Footer />
          </Stack>
        </div>
      </div>
    </div>
  );
}
