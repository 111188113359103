import Slider from '@mui/material/Slider';
import type { FC } from 'react';
import { useCallback, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { RangeFilterConfig } from './filter-types';
import { NestedFilterOption } from './FilterOption';
import { useFilterItemStyles } from './use-filter-item-styles';

interface Props {
  rangeFilterConfig: RangeFilterConfig;
  rangeStart?: number;
  rangeFinish?: number;
}

export const RangeFilter: FC<Props> = ({
  rangeFilterConfig,
  rangeStart,
  rangeFinish,
}) => {
  const { icon, title, key, rangeFrom, rangeTo, onUpdate } = rangeFilterConfig;

  const [selectedRange, setSelectedRange] = useState([
    rangeStart ?? rangeFrom.min,
    rangeFinish ?? rangeTo.max,
  ]);
  const [isInDebounce, setIsInDebounce] = useState(false);

  useEffect(() => {
    const rangeHasBeenFiltered =
      selectedRange[0] !== rangeFrom.min || selectedRange[1] !== rangeTo.max;

    if (
      rangeStart === undefined &&
      rangeFinish === undefined &&
      rangeHasBeenFiltered &&
      !isInDebounce
    ) {
      setSelectedRange([rangeFrom.min, rangeTo.max]);
    }
  }, [
    rangeFinish,
    isInDebounce,
    rangeFrom.min,
    rangeTo.max,
    selectedRange,
    rangeStart,
  ]);

  const classes = useFilterItemStyles();
  const marks = [
    {
      value: rangeFrom.min,
      label: `${rangeFrom.min}`,
    },

    {
      value: rangeTo.max,
      label: `${rangeTo.max}`,
    },
  ];

  const debouncedUpdateFilterOptions = useDebouncedCallback(
    (updatedFilters: Record<string, string>) => {
      onUpdate(updatedFilters);
      setIsInDebounce(false);
    },
    300,
  );

  const handleOnChange = useCallback(
    (_: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) return;
      const minDistanceApart = 5;
      setIsInDebounce(true);

      if (newValue[1] - newValue[0] < minDistanceApart) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], rangeTo.max - minDistanceApart);
          const clampedLeftValue = [clamped, clamped + minDistanceApart];
          debouncedUpdateFilterOptions({
            [rangeFrom.key]: String(clampedLeftValue[0]),
            [rangeTo.key]: String(clampedLeftValue[1]),
          });
          setSelectedRange(clampedLeftValue as [number, number]);
        } else {
          const clamped = Math.max(
            newValue[1],
            minDistanceApart + rangeFrom.min,
          );
          const clampedRightValue = [clamped - minDistanceApart, clamped];
          debouncedUpdateFilterOptions({
            [rangeFrom.key]: String(clampedRightValue[0]),
            [rangeTo.key]: String(clampedRightValue[1]),
          });
          setSelectedRange(clampedRightValue as [number, number]);
        }
      } else {
        debouncedUpdateFilterOptions({
          [rangeFrom.key]: String(newValue[0]),
          [rangeTo.key]: String(newValue[1]),
        });
        setSelectedRange(newValue as [number, number]);
      }
    },
    [
      debouncedUpdateFilterOptions,
      rangeFrom.key,
      rangeFrom.min,
      rangeTo.key,
      rangeTo.max,
    ],
  );

  return (
    <div className={classes.item} key={title + key}>
      <NestedFilterOption icon={icon} title={title}>
        <Slider
          getAriaLabel={() => 'Year range'}
          getAriaValueText={String}
          sx={{ width: '350px', margin: '0 20px' }}
          min={rangeFrom.min}
          max={rangeTo.max}
          value={selectedRange}
          onChange={handleOnChange}
          marks={marks}
          step={1}
          valueLabelDisplay="auto"
          disableSwap
        />
      </NestedFilterOption>
    </div>
  );
};
