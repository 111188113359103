import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';

export const useFilterItemStyles = createUseStyles((theme: LcpnTheme) => ({
  item: {
    width: '100%',
    '&&:last-child': {
      width: '100% !important',
    },
  },

  [`@media screen and (min-width: ${theme.breakPoints.tablet}px)`]: {
    item: {
      '&&': {
        width: '50%',
      },
    },
  },

  [`@media screen and (min-width: ${theme.breakPoints.widescreen}px)`]: {
    item: {
      '&&': {
        width: '33.333%',
      },
    },
  },
}));
