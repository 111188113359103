import type {
  HeatMapRange,
  HeatMapRangeKey,
  HeatMapSexFilterOption,
} from './map-data-types';

export interface LegendRange {
  color: string;
  label: string;
}

export interface LegendMeta {
  description: string;
}

export type HeatMapLegend = Record<HeatMapRangeKey, LegendRange>;
type HeatMapByFilter = Record<HeatMapSexFilterOption, HeatMapLegend>;

type HeatMapLegends = Record<HeatMapRange, HeatMapByFilter>;

export const heatMapLegends: HeatMapLegends = {
  incidence: {
    Combined: {
      60: {
        color: '#0a4e9a',
        label: '≥ 26.9',
      },
      50: {
        color: '#3082bd',
        label: '17.1 - 26.9',
      },
      40: {
        color: '#6baed5',
        label: '9.0 - 17.1',
      },
      30: {
        color: '#9dc9e1',
        label: '3.7 - 9.0',
      },
      20: {
        color: '#c6dbf2',
        label: '< 3.7',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
    Male: {
      60: {
        color: '#0a4e9a',
        label: '≥ 39.3',
      },
      50: {
        color: '#3082bd',
        label: '26.2 - 39.3',
      },
      40: {
        color: '#6baed5',
        label: '13.5 - 26.2',
      },
      30: {
        color: '#9dc9e1',
        label: '5.2 - 13.5',
      },
      20: {
        color: '#c6dbf2',
        label: '< 5.2',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
    Female: {
      60: {
        color: '#0a4e9a',
        label: '≥ 15.6',
      },
      50: {
        color: '#3082bd',
        label: '7.8 - 15.6',
      },
      40: {
        color: '#6baed5',
        label: '4.9 - 7.8',
      },
      30: {
        color: '#9dc9e1',
        label: '2.8 - 4.9',
      },
      20: {
        color: '#c6dbf2',
        label: '< 2.8',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
  },
  mortality: {
    Combined: {
      60: {
        color: '#a31016',
        label: '≥ 20.6',
      },
      50: {
        color: '#df2c26',
        label: '14.6 - 20.6',
      },
      40: {
        color: '#fc694b',
        label: '7.9 - 14.6',
      },
      30: {
        color: '#fd9372',
        label: '3.4 - 7.9',
      },
      20: {
        color: '#fbbea3',
        label: '< 3.4',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
    Male: {
      60: {
        color: '#a31016',
        label: '≥ 32.9',
      },
      50: {
        color: '#df2c26',
        label: '21.5 - 32.9',
      },
      40: {
        color: '#fc694b',
        label: '11.5 - 21.5',
      },
      30: {
        color: '#fd9372',
        label: '4.8 - 11.5',
      },
      20: {
        color: '#fbbea3',
        label: '< 4.8',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
    Female: {
      60: {
        color: '#a31016',
        label: '≥ 11.9',
      },
      50: {
        color: '#df2c26',
        label: '6.8 - 11.9',
      },
      40: {
        color: '#fc694b',
        label: '4.2 - 6.8',
      },
      30: {
        color: '#fd9372',
        label: '2.6 - 4.2',
      },
      20: {
        color: '#fbbea3',
        label: '< 2.6',
      },
      10: {
        color: '#ece1cb',
        label: 'No data',
      },
    },
  },
};
