import { useCallback, useEffect, useState } from 'react';
import type { MapLayerMouseEvent, ViewStateChangeEvent } from 'react-map-gl';
import { Map as MapBox, useMap } from 'react-map-gl';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { HeatmapLegend } from '../HeatMapLegend';
import { HeatMapCancerSeverityFilter } from '../HeatMapCancerSeverityFilter';
import { CountryCard } from '../Card/CountryCard';
import { Typography } from '../Typography';
import type {
  HeatMapSexFilterOption,
  HeatMapCountryMetaData,
  MapBoxGecodingResponse,
  LongitudeLatitude,
  HeatMapRange,
} from '../../map-data/map-data-types';
import type { LegendMeta } from '../../map-data/heatmap-legend-config';
import { heatMapLegends } from '../../map-data/heatmap-legend-config';
import { useMapData } from '../../map-data/use-map-data';
import { useHeatMapSelectedLocations } from '../HeatMapExperience/HeatMapSelectedLocationsProvider';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import { Button } from '../Button';
import { useHeatMapFilterOptions } from '../../map-data/use-heat-map-filter-options';

import { HeatMapSource } from './HeatMapSource';

const useStyles = createUseStyles({
  filterGroup: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    display: 'flex',
    gap: 8,
    color: 'white',
  },
});

const MapContainer = styled.div`
  position: relative;
`;

const mapBoxToken = process.env.REACT_APP_MAP_BOX_TOKEN;

type Props = {
  visible?: boolean;
};

export function capitalize(value: string): string {
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

const mapMovementDuration = 1600; // milliseconds

export type HeatMapMeta = Record<HeatMapRange, LegendMeta>;

export function HeatMap(props: Props) {
  const classes = useStyles();
  const [countryInfo, setCountryInfo] = useState<HeatMapCountryMetaData>();
  const [offsetCard, setOffsetCard] = useState(false);
  const { error: errorFetchingMapData, countryHeatMapMetaData } = useMapData();
  const { heatMap } = useMap();
  const { t } = useTranslation();
  const genders = [
    { label: t('heatMap.genders.bothSexes'), value: 'Combined', key: 'sex' },
    { value: 'Male', label: t('heatMap.genders.male'), key: 'sex' },
    { value: 'Female', label: t('heatMap.genders.female'), key: 'sex' },
  ];

  const heatMapMeta: HeatMapMeta = {
    incidence: {
      description: t('heatMap.lungCancerCases'),
    },
    mortality: {
      description: t('heatMap.lungCancerDeaths'),
    },
  };

  const {
    updateFilterOptions,
    filterTypeStatus,
    setRangeType,
    rangeType,
    setFilterTypeSex,
    filterTypeSex,
  } = useHeatMapFilterOptions();

  const {
    setShowCountryCard,
    showCountryCard,
    selectedCountryCode,
    setSelectedCountryCode,
  } = useHeatMapSelectedLocations();

  const legendConfig = heatMapLegends[rangeType][filterTypeSex];
  const legendMeta = heatMapMeta[rangeType];

  const { updateSelectedNavigationItem } = useUpdateNavigationBar();

  // reset map is resized when it becomes visible
  useEffect(() => {
    if (props.visible) {
      heatMap?.resize();
      setOffsetCard(false);
    }
  }, [props.visible, heatMap]);

  const moveMapToLongLat = useCallback(
    (longLat: LongitudeLatitude) => {
      const zoom = heatMap?.getZoom();
      heatMap?.flyTo({
        center: longLat,
        essential: true,
        zoom: zoom,
        duration: mapMovementDuration,
      });
    },
    [heatMap],
  );

  useEffect(() => {
    const { boundaryBox } =
      countryHeatMapMetaData?.[selectedCountryCode ?? ''] ?? {};

    // if there is a selected country code and related boundary box the zoom
    // into that (country)
    if (boundaryBox) {
      heatMap?.fitBounds(
        [
          [boundaryBox[0] ?? 0, boundaryBox[1] ?? 0],
          [boundaryBox[2] ?? 0, boundaryBox[3] ?? 0],
        ],
        { padding: 40, duration: 2000 },
      );

      return;
    }

    // if no select code then assume all selection closed and reset map
    if (!selectedCountryCode) {
      heatMap?.flyTo({
        center: [0, 20],
        essential: true,
        zoom: 0.7,
        screenSpeed: 2,
      });
    }
  }, [countryHeatMapMetaData, heatMap, selectedCountryCode]);

  const handleMapClick = useCallback(
    async ({ lngLat }: MapLayerMouseEvent) => {
      try {
        const geoCodeData: MapBoxGecodingResponse = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?types=country&access_token=${mapBoxToken}`,
        ).then(data => data.json());

        const countryIso3166_2Code =
          geoCodeData.features[0]?.properties.short_code;

        const countryMetaData =
          countryHeatMapMetaData?.[countryIso3166_2Code?.toUpperCase() ?? ''];

        if (!countryIso3166_2Code || !countryMetaData) {
          // if unable to find country and related metadata just move map to click location as assume not clicked on a country or one included in data set
          moveMapToLongLat([lngLat.lng, lngLat.lat]);
          setShowCountryCard(false);
          return;
        }

        setCountryInfo(countryMetaData);
        setSelectedCountryCode(countryIso3166_2Code.toUpperCase());
        heatMap?.fitBounds(countryMetaData.boundaryBox, {
          padding: 40,
          duration: mapMovementDuration,
        });

        setShowCountryCard(true);
        updateSelectedNavigationItem(undefined);
      } catch (exception) {
        console.error(
          `unable to get geo code data for [${lngLat.lng}, ${lngLat.lat}] coordinates`,
          exception,
        );
      }
    },
    [
      countryHeatMapMetaData,
      heatMap,
      moveMapToLongLat,
      setSelectedCountryCode,
      setShowCountryCard,
      updateSelectedNavigationItem,
    ],
  );

  // offset the map when the info box is open
  useEffect(() => {
    const boxWidth = offsetCard ? 300 : 0;
    heatMap?.easeTo(
      {
        padding: { top: 0, left: boxWidth, right: 0, bottom: 0 },
        duration: 1000,
      },
      {
        offsetChanged: true,
      },
    );
  }, [offsetCard, heatMap]);

  if (errorFetchingMapData) {
    // handle errors requesting data here (should be extremely rare)
    return null;
  }

  return (
    <MapContainer>
      <MapBox
        initialViewState={{
          longitude: 0,
          latitude: 20,
          zoom: 0.7,
        }}
        id="heatMap"
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/lcpn-interactive-map/cl70htojf000u14p783pk7gph"
        mapboxAccessToken={mapBoxToken}
        maxZoom={5}
        minZoom={0.7}
        dragRotate={false}
        onClick={handleMapClick}
        onMoveEnd={(event: ViewStateChangeEvent & mapboxgl.EventData) => {
          if (event.originalEvent) {
            // do nothing
          } else if (event.offsetChanged !== true) {
            // check the offset was not changed (indicated by custom event data)
            // to debounce
            setOffsetCard(true);
          }
        }}
      >
        <HeatMapSource
          rangeType={rangeType}
          filterTypeSex={filterTypeSex}
          filterTypeStatus={filterTypeStatus}
        />
        <div className={classes.filterGroup}>
          {genders.map((gender, index) => (
            <Button
              label={gender.label}
              key={index}
              selected={gender.value === filterTypeSex}
              bgColor={'0,139,127'}
              onClick={() => {
                updateFilterOptions({ [gender.key]: gender.label });
                setFilterTypeSex(gender.value as HeatMapSexFilterOption);
              }}
            />
          ))}
        </div>
      </MapBox>

      <HeatMapCancerSeverityFilter
        rangeFilterType={rangeType}
        setRangeFilterType={setRangeType}
      />

      <HeatmapLegend
        legendConfig={legendConfig}
        title={rangeType}
        description={legendMeta.description}
        filterType={filterTypeSex}
      />

      {showCountryCard && countryInfo && selectedCountryCode && (
        <div style={{ position: 'absolute', top: '20%', left: '1%' }}>
          <CountryCard
            countryName={countryInfo.countryName}
            countryCode={selectedCountryCode}
            closeCard={() => {
              setShowCountryCard(false);
              setOffsetCard(false);
            }}
          >
            <Typography variant="p3">
              <>
                {t('heatMap.lungCancer', {
                  replace: {
                    type: rangeType === 'incidence' ? 'cases' : 'deaths',
                  },
                })}
                <br />
                {t('heatMap.perPopulation')}
              </>
            </Typography>
            <Typography variant="p2">
              <ul>
                <li>
                  {filterTypeSex === 'Combined' && (
                    <span>{t('heatMap.genders.bothSexes')}:&nbsp;</span>
                  )}
                  {filterTypeSex === 'Male' && (
                    <span>{t('heatMap.genders.male')}:&nbsp;</span>
                  )}
                  {filterTypeSex === 'Female' && (
                    <span>{t('heatMap.genders.female')}:&nbsp;</span>
                  )}
                  <span style={{ color: '#5A5A5A', fontSize: '1rem' }}>
                    {countryInfo[`${rangeType}${filterTypeSex}`] > 0
                      ? countryInfo[`${rangeType}${filterTypeSex}`].toFixed(1)
                      : t('heatMap.noData')}
                  </span>
                </li>
              </ul>
            </Typography>
            <Typography variant="p3">{t('heatMap.globocan')}</Typography>
          </CountryCard>
        </div>
      )}
    </MapContainer>
  );
}
