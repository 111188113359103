import type { FC, ReactNode, Dispatch, SetStateAction } from 'react';
import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useMap } from 'react-map-gl';
interface HeatMapSelectedLocationsContextProps {
  selectedCountryCode: string | undefined;
  setSelectedCountryCode: Dispatch<SetStateAction<string | undefined>>;
  showCountryCard: boolean;
  setShowCountryCard: Dispatch<SetStateAction<boolean>>;
  resetExperience: VoidFunction;
}

type HeatMapSelectedLocationsContextPropsUndefined =
  | HeatMapSelectedLocationsContextProps
  | undefined;
const HeatMapSelectedLocationsContext =
  createContext<HeatMapSelectedLocationsContextPropsUndefined>(undefined);

interface Props {
  children: ReactNode;
}

export const useHeatMapSelectedLocations = () => {
  const filteredMapData = useContext(HeatMapSelectedLocationsContext);

  if (!filteredMapData) {
    throw new Error(
      'useMapData hook it being used outside of the MapDataProvider',
    );
  }

  return filteredMapData;
};

export const HeatMapSelectedLocationsProvider: FC<Props> = ({ children }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();
  const [showCountryCard, setShowCountryCard] = useState(false);

  const { heatMap } = useMap();

  const resetExperience = useCallback(() => {
    setSelectedCountryCode('');
    setShowCountryCard(false);
    heatMap?.flyTo({
      center: [0, 20],
      essential: true,
      zoom: 0.7,
      screenSpeed: 2,
    });
  }, [heatMap]);

  const memorizedContextValue = useMemo(
    () => ({
      selectedCountryCode,
      setSelectedCountryCode,
      showCountryCard,
      setShowCountryCard,
      resetExperience,
    }),
    [selectedCountryCode, showCountryCard, resetExperience],
  );

  return (
    <HeatMapSelectedLocationsContext.Provider value={memorizedContextValue}>
      {children}
    </HeatMapSelectedLocationsContext.Provider>
  );
};
