import { createUseStyles } from 'react-jss';
import React from 'react';

import type { IconVariant } from '../Icon';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import type { LcpnTheme } from '../../styles/theme';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  filterOption: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 70,
    gap: 8,
    borderBottom: `${theme.colors.white} solid 2px`,
    padding: '8px 0px 8px 16px',
    flex: 1,
  },

  nestedFilterOption: {
    '&&': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 8,
      padding: '10px 0 10px 16px',
      borderBottom: `${theme.colors.white} solid 2px`,
      minHeight: 66,
      border: 'none',
      height: 'fit-content',
      marginBottom: -2,
    },
  },

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginLeft: 'auto',
    gap: 8,
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },

  [`@media screen and (max-width: ${theme.breakPoints.desktop}px)`]: {
    filterOption: {
      '&&': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        minHeight: 5,
        padding: 8,
        border: 'none',
      },
    },
    nestedFilterOption: {
      '&&': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        minHeight: 5,
        padding: 8,
        border: 'none',
      },
    },
    group: {
      '&&': {
        flexDirection: 'row',
        alignItems: 'center',
        '& > img': {
          width: 18,
          height: 18,
        },
      },
    },
    buttonGroup: {
      '&&': {
        marginLeft: 0,
        width: '100%',
        marginRight: 0,
        flexWrap: 'wrap',
      },
    },
    textGroup: {
      '&&': {
        marginLeft: 8,
        '& > div > p': {
          fontSize: theme.fontSizes.p3,
        },
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    filterOption: {
      '&&': {
        padding: '8px 16px',
      },
    },
    nestedFilterOption: {
      '&&': {
        padding: '10px 0 10px 16px',
      },
    },
  },
}));

type Props = {
  icon: IconVariant;
  title: string;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
};

export function FilterOption(props: Props) {
  const classes = useStyles();
  const { icon = 'Target', title, subtitle, children } = props;
  return (
    <div className={classes.filterOption}>
      <div className={classes.group}>
        <Icon variant={icon} width={24} height={24} />
        <div className={classes.textGroup}>
          <Typography variant="p2">{title}</Typography>
          <Typography variant="p3">{subtitle}</Typography>
        </div>
      </div>
      <div className={classes.buttonGroup}>{children}</div>
    </div>
  );
}

export function NestedFilterOption(props: Omit<Props, 'subtitle'>) {
  const classes = useStyles();
  const { icon = 'Target', title, children } = props;
  return (
    <div className={classes.nestedFilterOption}>
      <div className={classes.group}>
        <Icon variant={icon} width={20} height={20} />
        <div className={classes.textGroup}>
          <Typography variant="p2">{title}</Typography>
          <div className={classes.buttonGroup}>{children}</div>
        </div>
      </div>
    </div>
  );
}
