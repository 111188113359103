import type { FC } from 'react';
import { useMemo } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createUseStyles } from 'react-jss';

import type { HeatMapSexFilterOption } from '../../map-data/map-data-types';
import type { HeatMapLegend } from '../../map-data/heatmap-legend-config';
import type { LcpnTheme } from '../../styles/theme';
import { Typography } from '../Typography';

import { KeyColor } from './KeyColor';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  legend: {
    position: 'absolute',
    bottom: 50,
    right: 25,
    background: 'rgba(255, 255, 255, 0.85)',
    borderRadius: 12.5,
    minWidth: 250,
    padding: 8,
  },

  heading: {
    display: 'flex',
    padding: 4,
    borderBottom: `solid 1px ${theme.colors['grey-100']}`,
    width: '100%',
  },

  description: {
    textAlign: 'center',
    padding: [[5, 4]],
    width: '100%',
    maxWidth: '12em;',
    margin: '0 auto',
  },

  data: {
    marginTop: 4,
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  filterType: {
    alignSelf: 'center',
    minWidth: '5em',
  },

  values: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '8em',
  },

  item: {
    display: 'flex',
    gap: 8,
    fontSize: 14,
  },
}));

type Props = {
  legendConfig: HeatMapLegend;
  title: string;
  description: string;
  filterType: HeatMapSexFilterOption;
};

function correctTitle(value: HeatMapSexFilterOption) {
  switch (value) {
    case 'Combined': {
      return 'Both sexes';
    }

    case 'Male': {
      return 'Male';
    }

    case 'Female': {
      return 'Female';
    }

    default: {
      return 'both sexes';
    }
  }
}

export const HeatmapLegend: FC<Props> = ({
  legendConfig,
  filterType,
  description,
}) => {
  const rows = useMemo(() => Object.values(legendConfig), [legendConfig]);
  const classes = useStyles();
  return (
    <div className={classes.legend}>
      <div className={classes.data}>
        <div className={classes.filterType}>
          <Typography variant="p2">{correctTitle(filterType)}</Typography>
        </div>
        <div className={classes.values}>
          {rows.map((row, index) => (
            <div key={index} className={classes.item}>
              <KeyColor color={row.color} />
              <span>{row.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.description}>
        <Typography variant="p3">{description}</Typography>
      </div>
    </div>
  );
};
