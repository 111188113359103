import {
  Hits,
  Pagination,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

import type { PoiResultItemComponentProps } from './PoiResultItem';

const SearchResultsWrapper = styled.div`
  position: absolute;
  min-height: 38px;
  background: white;
  width: 100%;
  z-index: 1000;
`;

const ResultsList = styled(Hits)`
  & li {
    padding: 0;
  }
` as typeof Hits;

interface PoiSearchResultsProps {
  resultItemComponent: FC<Omit<PoiResultItemComponentProps, 'onResultClick'>>;
}

export const PoiSearchResults: FC<PoiSearchResultsProps> = ({
  resultItemComponent,
}) => {
  const { results, indexUiState } = useInstantSearch();
  const { t } = useTranslation();

  if (!indexUiState.query) {
    return null;
  }

  if (!results?.nbHits) {
    return (
      <SearchResultsWrapper>
        <span style={{ paddingLeft: '1em', lineHeight: 2 }}>
          {t('poiSearch.noResults', { defaultValue: 'No results found' })}
        </span>
      </SearchResultsWrapper>
    );
  }

  return (
    <SearchResultsWrapper>
      <ResultsList hitComponent={resultItemComponent} />
      {results.nbPages > 1 && <Pagination />}
    </SearchResultsWrapper>
  );
};
