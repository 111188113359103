import {
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  FormLabel,
  Button,
} from '@mui/material';
import type { FC } from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

import type { LcpnTheme } from '../../../styles/theme';

interface PickerSelectProps {
  onUpdate: (itemValue: string) => void;
  selectedValue: string;
  label: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  onClear?: VoidFunction;
}

export const PickerSelect: FC<PickerSelectProps> = ({
  onUpdate,
  selectedValue,
  label,
  placeholder,
  options,
  disabled = false,
  onClear,
}) => {
  const labelId = `picker-select-label-${label.replace(' ', '-')}`;
  const theme = useTheme<LcpnTheme>();
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel
        id={labelId}
        disabled={disabled}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {label}
        {selectedValue && options.length > 1 && (
          <Button
            variant={'text'}
            size="small"
            sx={{
              lineHeight: 1,
              fontSize: 12,
              fontFamily: 'Be Vietnam Pro',
              textTransform: 'none',
              textDecoration: 'underline',
              color: theme.colors['blue-100'],
            }}
            onClickCapture={onClear}
          >
            {t('comparisonPicker.clearSelection')}
          </Button>
        )}
      </FormLabel>
      <Select<string | undefined>
        value={selectedValue}
        label={label}
        displayEmpty
        labelId={labelId}
        aria-labelledby={labelId}
        disabled={disabled}
        sx={{
          width: '100%',
          backgroundColor: 'white',
          color: '#5a5a5a',
          height: '3.9375rem',
        }}
        input={<OutlinedInput />}
        onChange={event => {
          if (event.target.value) onUpdate(event.target.value);
        }}
        renderValue={selected => {
          if (selected === '' && !!placeholder) {
            return (
              <em style={{ fontStyle: 'normal', fontSize: '12px' }}>
                {placeholder}
              </em>
            );
          }

          return options.find(option => option.value === selected)?.label;
        }}
      >
        {options.map(({ label, value }, index) => (
          <MenuItem value={value} key={label + index}>
            <em style={{ fontStyle: 'normal' }}>{label}</em>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
