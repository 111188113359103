import {
  Hits,
  Pagination,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import styled from 'styled-components';
import type { FC } from 'react';

import type { HeatMapResultItemComponentProps } from './HeatMapResultItem';

const SearchResultsWrapper = styled.div`
  position: absolute;
  min-height: 38px;
  background: white;
  width: 100%;
  z-index: 1000;
`;

const ResultsList = styled(Hits)`
  & li {
    padding: 0;
  }
` as typeof Hits;

interface HeatMapSearchResultsProps {
  resultItemComponent: FC<
    Omit<HeatMapResultItemComponentProps, 'onResultClick'>
  >;
}

export const HeatMapSearchResults: FC<HeatMapSearchResultsProps> = ({
  resultItemComponent,
}) => {
  const { results, indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return null;
  }

  if (!results?.nbHits) {
    return (
      <SearchResultsWrapper>
        <span style={{ paddingLeft: '1em', lineHeight: 2 }}>
          No results found
        </span>
      </SearchResultsWrapper>
    );
  }

  return (
    <SearchResultsWrapper>
      <ResultsList hitComponent={resultItemComponent} />
      {results.nbPages > 1 && <Pagination />}
    </SearchResultsWrapper>
  );
};
