import styled from 'styled-components';
import { memo, useCallback } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

import { theme } from '../../styles/theme';
import { HeatMap } from '../HeatMap';
// import the data view
import { HeatMapCountryList } from '../HeatMapCountryList';
import { HeatMapSearch } from '../HeatMapSearch';
import { HeatMapSearchResults as SearchResults } from '../HeatMapSearch/HeatMapSearchResults';
import type { IsoCountryCodes } from '../../map-types';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import { HeatMapResultItem } from '../HeatMapSearch/HeatMapResultItem';

import { useHeatMapSelectedLocations } from './HeatMapSelectedLocationsProvider';

const SearchWrapper = styled.div`
  position: absolute;
  margin: 1rem;
  z-index: 1;
  width: calc(50% - 3.625rem);
  overflow: visible;
  display: none;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    display: block;
  }
`;

const MapContainer = styled.div<{ isVisible: boolean }>`
  height: 100%;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  @media (min-width: ${theme.breakPoints.desktop}px) {
    padding: 1rem;
    box-sizing: border-box;
  }
`;

const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: 0px;

  > div {
    flex: 50%;
  }

  > div:last-of-type {
    display: none;
  }

  @media (min-width: ${theme.breakPoints.laptop}px) {
    flex-direction: row;
    gap: 20px;
    > div:last-of-type {
      display: block;
    }
  }
  @media (max-width: ${theme.breakPoints.desktop}px) {
    height: 100vh;
    max-height: '100vh';
    margin-top: 0px;
    flex-direction: column-reverse;
    gap: 0;
    max-height: 100%;
  }
`;

const ListWrapper = styled.div`
  background: white;
  padding: 1rem;
  box-sizing: border-box;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    padding: 4.125rem 1rem 1rem 1rem;
  }
`;

type Props = {
  visible?: boolean;
};

const HeatMapSearchResults = () => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const { resetNavigationBar } = useUpdateNavigationBar();
  const { setSelectedCountryCode, setShowCountryCard } =
    useHeatMapSelectedLocations();

  const handleSearchResultClick = useCallback(
    (code: IsoCountryCodes) => {
      setIndexUiState({
        ...indexUiState,
        query: undefined,
      });
      setSelectedCountryCode(code);
      setShowCountryCard(true);
      resetNavigationBar();
    },
    [
      indexUiState,
      resetNavigationBar,
      setIndexUiState,
      setSelectedCountryCode,
      setShowCountryCard,
    ],
  );

  return (
    <SearchResults
      resultItemComponent={props => (
        <HeatMapResultItem {...props} onResultClick={handleSearchResultClick} />
      )}
    />
  );
};

export const HeatMapExperience = memo(({ visible = true }: Props) => {
  return (
    <MapContainer isVisible={visible}>
      {visible && (
        <SearchWrapper>
          <HeatMapSearch placeholder="Search by country or status">
            <HeatMapSearchResults />
          </HeatMapSearch>
        </SearchWrapper>
      )}
      <MapContent>
        {visible && (
          <ListWrapper>
            <HeatMapCountryList />
          </ListWrapper>
        )}
        <HeatMap visible={visible} />
      </MapContent>
    </MapContainer>
  );
});

HeatMapExperience.displayName = 'HeatMapExperience';
