import type { FC } from 'react';
import { Source, Layer } from 'react-map-gl';

import type {
  FilteredHeatMapData,
  HeatMapSexFilterOption,
  HeatMapRange,
  HeatMapRangeKey,
  HeatMapStatusFilterOption,
} from '../../map-data/map-data-types';
import type { HeatMapLegend } from '../../map-data/heatmap-legend-config';
import { heatMapLegends } from '../../map-data/heatmap-legend-config';
import { useMapData } from '../../map-data/use-map-data';

type Props = {
  filterTypeSex: HeatMapSexFilterOption;
  rangeType: HeatMapRange;
  filterTypeStatus: HeatMapStatusFilterOption;
};

const generateLayerProps = (
  key: HeatMapRangeKey,
  filteredMapData: FilteredHeatMapData,
  legendConfig: HeatMapLegend,
) => ({
  filter: [
    'all',
    [
      'any',
      ['==', 'all', ['get', 'worldview']],
      ['in', 'US', ['get', 'worldview']],
    ],
    ['match', ['get', 'iso_3166_1_alpha_3'], filteredMapData[key], true, false],
  ],

  id: `country-boundaries-${key}`,
  'source-layer': 'country_boundaries',
  beforeId: 'admin-0-boundary',
  paint: {
    'fill-color': legendConfig[key].color,
    'fill-opacity': 1,
  },
  type: 'fill' as const,
});

export const HeatMapSource: FC<Props> = ({
  rangeType,
  filterTypeSex,
  filterTypeStatus,
}) => {
  const legendConfig = heatMapLegends[rangeType][filterTypeSex];
  const { countryHeatMapData, loading } = useMapData();

  if (!countryHeatMapData || loading) {
    // handle loading state here.
    return null;
  }

  const filteredMapData =
    countryHeatMapData?.[`${rangeType}${filterTypeSex}${filterTypeStatus}`];

  return (
    <Source type="vector" url="mapbox://mapbox.country-boundaries-v1">
      {!!filteredMapData[60]?.length && (
        <Layer {...generateLayerProps(60, filteredMapData, legendConfig)} />
      )}
      {!!filteredMapData[50]?.length && (
        <Layer {...generateLayerProps(50, filteredMapData, legendConfig)} />
      )}
      {!!filteredMapData[40]?.length && (
        <Layer {...generateLayerProps(40, filteredMapData, legendConfig)} />
      )}
      {!!filteredMapData[30]?.length && (
        <Layer {...generateLayerProps(30, filteredMapData, legendConfig)} />
      )}
      {!!filteredMapData[20]?.length && (
        <Layer {...generateLayerProps(20, filteredMapData, legendConfig)} />
      )}
      {filteredMapData[10]?.length && (
        <Layer {...generateLayerProps(10, filteredMapData, legendConfig)} />
      )}
    </Source>
  );
};
