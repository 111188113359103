import { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';

import { FullScreen, useFullScreenHandle } from './components/FullScreen';
import { useAnalytics } from './components/Analytics';
import { HeatMapExperience } from './components/HeatMapExperience';
import { NavigationBar } from './components/NavigationBar';
import {
  useNavigationBarData,
  useUpdateNavigationBar,
} from './components/NavigationBar/NavigationBarProvider';
import { theme } from './styles/theme';
import { Icon } from './components/Icon';
import { useWindowSize } from './utils/UseWindowSize';
import { PointOfInterestExperience } from './components/PointOfInterestExperience';
import { useComparisonProviderContext } from './providers/ComparisonProvider';
import { useVirtualTour } from './components/VirtualTour';
import { useMapData } from './map-data/use-map-data';
import { TourUpdateTab } from './components/VirtualTour/TourUpdateTab';
import { ComparisonTool } from './components/ComparisonTool';

const MapLayoutContainer = styled.main`
  background-color: #f0f0f0;
  display: flex;
  height: 100vh;
  flex-direction: column-reverse;
  box-sizing: border-box;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    flex-direction: column;
  }
`;

const FullscreenNavigationContainer = styled.div`
  display: block;
  position: fixed;
  bottom: 73px;
  right: 0;
  width: 45px;
  height: 45px;
  z-index: 9000;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    bottom: 0;
  }
`;

const MapHeader = styled.div`
  display: block;
  width: 100%;
  overflow: visible;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

export function App() {
  const { selectedNavigationItem, selectedExperience, setSelectedExperience } =
    useNavigationBarData();
  const { loading: mapDataLoading } = useMapData();

  const { updateSelectedNavigationItem } = useUpdateNavigationBar();

  const { i18n, t } = useTranslation();
  useAnalytics();
  const handle = useFullScreenHandle(document.querySelector('body'));

  const size = useWindowSize();

  const {
    heatMapComparison,
    poiComparison,
    setHeatMapComparison,
    setPoiComparison,
  } = useComparisonProviderContext();

  useVirtualTour();

  useEffect(() => {
    if (
      size.width! < theme.breakPoints.desktop &&
      selectedNavigationItem === 'comparison'
    ) {
      updateSelectedNavigationItem(undefined);
    }
  }, [selectedNavigationItem, size, updateSelectedNavigationItem]);

  const isPinMap = selectedExperience !== 'heatMap';
  const isComparisonDisplay = selectedNavigationItem === 'comparison';

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('poiId')) setSelectedExperience('pinMap');
    if (params.get('heatmapId')) setSelectedExperience('heatMap');
    if (params.get('compareHeatMap')) {
      const items = params.get('compareHeatMap')!.split(',');

      if (items.length === 2) {
        setHeatMapComparison({
          display: true,
          fromCountryCode: items[0],
          toCountryCode: items[1],
        });
        updateSelectedNavigationItem('comparison');
        setSelectedExperience('heatMap');
      }
    }
    if (params.get('comparePoi')) {
      const items = params.get('comparePoi')!.split(',');

      if (items.length === 2) {
        setPoiComparison({
          display: true,
          fromStudyId: items[0],
          toStudyId: items[1],
        });
        updateSelectedNavigationItem('comparison');
        setSelectedExperience('pinMap');
      }
    }
  }, [
    setHeatMapComparison,
    setPoiComparison,
    setSelectedExperience,
    updateSelectedNavigationItem,
  ]);

  if (mapDataLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  // Loading the data set into the map is expensive.
  // The code below ensures both maps are loaded by
  // default, and then when once becomes visible it
  // gives the map a nudge to ensure it's correctly
  // sized.

  // Detect - fullscreen API and close button
  return (
    <>
      <Helmet
        titleTemplate="%s - LCPN Interactive Map"
        defaultTitle="LCPN Interactive Map"
        htmlAttributes={{
          lang: i18n.language,
          dir: i18n.dir(i18n.language),
        }}
      >
        <meta
          name="description"
          content={t('global.meta.description', {
            defaultValue: 'LCPN interactive map',
          })}
        />
      </Helmet>
      <FullScreen handle={handle}>
        <MapLayoutContainer id="map-full-screen-container">
          <FullscreenNavigationContainer>
            {document.fullscreenEnabled &&
              (handle.active ? (
                <Button onClick={handle.exit} title="Exit fullscreen">
                  <Icon width={45} height={45} variant="FullscreenContract" />
                </Button>
              ) : (
                <Button onClick={handle.enter} title="Enter fullscreen">
                  <Icon width={45} height={45} variant="FullscreenExpand" />
                </Button>
              ))}
          </FullscreenNavigationContainer>
          <MapHeader>
            <NavigationBar />
          </MapHeader>
          {isComparisonDisplay && (
            <ComparisonTool
              isPinMapView={isPinMap}
              showHeatMapTable={!!heatMapComparison.display}
              showPoiTable={!!poiComparison.display}
            />
          )}
          {!isComparisonDisplay && <TourUpdateTab />}
          <HeatMapExperience visible={!isPinMap && !isComparisonDisplay} />
          <PointOfInterestExperience
            visible={isPinMap && !isComparisonDisplay}
          />
        </MapLayoutContainer>
      </FullScreen>
    </>
  );
}
