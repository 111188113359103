import type { FC, ReactNode } from 'react';
import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { poiFilterOptionComparatorMap } from './map-data-filter-maps';
import type {
  PoiFilterOptions,
  PoiStudyData,
  PoiCityMetaData,
  StudiesById,
  PoiFilterConfig,
} from './map-data-types';
import { useMapData } from './use-map-data';
import { fetchDataFromPublicDir } from './fetch-data-from-public-dir';

interface Props {
  children: ReactNode;
}

interface FilteredPoiMapData {
  filterOptions: PoiFilterOptions;
  updateFilterOptions: (newOptions: PoiFilterOptions) => void;
  resetFilterOptions: VoidFunction;
  filteredPoiCityMetaData?: PoiCityMetaData;
  filteredStudiesById?: StudiesById;
  filteredPoiStudyData?: PoiStudyData;
  filterConfig?: PoiFilterConfig;
}

type FilteredPoiMapDataUndef = FilteredPoiMapData | undefined;
export const FilteredPoiMapDataContext =
  createContext<FilteredPoiMapDataUndef>(undefined);

const filterStudyKeyMap = {
  status: 'status',
  sex: 'sex',
  riskModellingUsed: 'riskModellingUsed',
  smokersNeverEligible: 'smokersNeverEligible',
  targetOutreach: 'targetOutreach',
  biomarkersCollected: 'biomarkersCollected',
  smokingCessationOffered: 'smokingCessationOffered',
  useCade: 'useCade',
  resultsAvailable: 'resultsAvailable',
  design: 'design',
  startFinish: 'finish',
  endFinish: 'finish',
  continent: 'continent',
  politicalRegion: 'politicalRegion',
} as const;

/**
 * FilteredPoiMapDataProvider
 *
 * Hold state on all things filtered map data. This includes:
 * Options:
 *   filterOptions: filters as set by the user
 *   updateFilterOptions: setter for the filter options
 * FilterMapData:
 *   filteredPoiCityMetaData: city data with filtered array of studies per city
 *   filteredStudiesById: filtered study by id in that the study is removed if any attribute that is filtered is truthy within that study
 *
 * Both states kept in same provider as they will always update together.
 */
export const FilteredPoiMapDataProvider: FC<Props> = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<PoiFilterOptions>({});
  const [filterConfig, setFilterConfig] = useState<PoiFilterConfig>();

  const { poiCityMetaData, studiesById, poiStudyData } = useMapData();

  // updates filter options with a merge so consumer does not need to know
  // context of current state to update
  const updateFilterOptions = useCallback(
    (newPartialFilterOptions: Partial<PoiFilterOptions>) => {
      setFilterOptions(prevOptions => ({
        ...prevOptions,
        ...newPartialFilterOptions,
      }));
    },
    [setFilterOptions],
  );

  const resetFilterOptions = useCallback(() => {
    setFilterOptions({});
  }, []);

  useEffect(() => {
    fetchDataFromPublicDir('poi', 'poi-filter-config.json').then(
      filterConfig => {
        setFilterConfig(filterConfig);
      },
    );
  }, []);

  // upon change of filter options to filter out all studies, to include by city, that are affected.
  const { filteredPoiCityMetaData, filteredStudiesById, filteredPoiStudyData } =
    useMemo(() => {
      const activeFilterOptions = Object.entries(filterOptions).filter(
        ([, value]) => !!value,
      ) as [keyof PoiFilterOptions, PoiFilterOptions[keyof PoiFilterOptions]][];

      // if not filter options set or no raw data then pass through existing state rather than attempting
      // to filter
      if (
        activeFilterOptions.length === 0 ||
        !studiesById ||
        !poiCityMetaData ||
        !poiStudyData
      ) {
        return {
          filteredPoiCityMetaData: poiCityMetaData,
          filteredStudiesById: studiesById,
          filteredPoiStudyData: poiStudyData,
        };
      }

      const filteredPoiStudyData: PoiStudyData = { studies: [] };

      const filteredStudiesByIdArray = Object.entries(studiesById).filter(
        ([studyId, studyDetails]) => {
          // check every property within study that has an active filter and check the study
          // property has a matching value to filter value.
          const studyHasFilterableValues = activeFilterOptions.every(
            ([filterKey, filterValue]) => {
              const comparatorFunction =
                poiFilterOptionComparatorMap[filterKey];

              return comparatorFunction?.(
                filterConfig,
                studyDetails[filterStudyKeyMap[filterKey]],
                filterValue,
              );
            },
          );

          // if study isn't to be filtered then updated the filter study data to include it.
          if (studyHasFilterableValues) {
            filteredPoiStudyData.studies.push({
              latitude: studyDetails.latitude,
              longitude: studyDetails.longitude,
              id: studyId,
              city: studyDetails.city,
              country: studyDetails.country,
              region: studyDetails.region,
            });
          }

          return studyHasFilterableValues;
        },
      );

      const filteredStudiesById = Object.fromEntries(filteredStudiesByIdArray);

      const filteredPoiCityMetaData: PoiCityMetaData = {};
      for (const [cityName, relatedStudies] of Object.entries(
        poiCityMetaData,
      )) {
        const filteredCityStudies = relatedStudies.filter(
          studyId => filteredStudiesById[studyId],
        );

        filteredPoiCityMetaData[cityName] = filteredCityStudies;
      }

      return {
        filteredStudiesById,
        filteredPoiCityMetaData,
        filteredPoiStudyData,
      };
    }, [
      filterOptions,
      studiesById,
      poiCityMetaData,
      poiStudyData,
      filterConfig,
    ]);

  // memorize state object in case parent is re-rendered.
  const memorizedFilteredContextState = useMemo(
    () => ({
      updateFilterOptions,
      resetFilterOptions,
      filterOptions,
      filteredPoiCityMetaData,
      filteredStudiesById,
      filteredPoiStudyData,
      filterConfig,
    }),
    [
      updateFilterOptions,
      resetFilterOptions,
      filterOptions,
      filteredPoiCityMetaData,
      filteredStudiesById,
      filteredPoiStudyData,
      filterConfig,
    ],
  );

  return (
    <FilteredPoiMapDataContext.Provider value={memorizedFilteredContextState}>
      {children}
    </FilteredPoiMapDataContext.Provider>
  );
};
