import { clsx } from 'clsx';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import selectionPattern from './assets/select_pattern.svg';
import { useNavigationBarData } from './NavigationBarProvider';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  option: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 16,
    gap: 16,
    textAlign: 'left',
    color: theme.colors['green-100'],
    backgroundColor: theme.colors['green-55'],
    '&:focus': {
      backgroundColor: theme.colors['green-100'],
      backgroundImage: `url(${selectionPattern})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      color: theme.colors.white,
    },
  },

  active: {
    backgroundColor: theme.colors['green-100'],
    backgroundImage: `url(${selectionPattern})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: theme.colors.white,
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export function MapSelection() {
  const classes = useStyles();

  const { selectedExperience, setSelectedExperience } = useNavigationBarData();

  return (
    <div className={classes.container}>
      <button
        className={clsx(classes.option, {
          [classes.active]: selectedExperience !== 'heatMap',
        })}
        onClick={() => setSelectedExperience('pinMap')}
      >
        <div className={classes.group}>
          <Typography variant="p1">Pin map</Typography>
          {selectedExperience === 'heatMap' ? (
            <Icon variant="Circle" width={24} height={24} />
          ) : (
            <Icon variant="ActiveCheck" width={24} height={24} />
          )}
        </div>
        <Typography variant="p3">
          Implementation studies and programmes
        </Typography>
      </button>
      <button
        className={clsx(classes.option, {
          [classes.active]: selectedExperience === 'heatMap',
        })}
        onClick={() => setSelectedExperience('heatMap')}
      >
        <div className={classes.group}>
          <Typography variant="p1">Heat map</Typography>
          {selectedExperience === 'heatMap' ? (
            <Icon variant="ActiveCheck" width={24} height={24} />
          ) : (
            <Icon variant="Circle" width={24} height={24} />
          )}
        </div>
        <Typography variant="p3">
          The policy context for implementation
        </Typography>
      </button>
    </div>
  );
}
