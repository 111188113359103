type MapDataType = 'poi' | 'heatmap' | 'virtualTour';

const getFilePathPrefix = (type: MapDataType) => {
  if (type === 'poi' && process.env.REACT_APP_POI_DATA_LOCATION)
    return process.env.REACT_APP_POI_DATA_LOCATION;
  if (type === 'heatmap' && process.env.REACT_APP_HEATMAP_DATA_LOCATION)
    return process.env.REACT_APP_HEATMAP_DATA_LOCATION;
  if (
    type === 'virtualTour' &&
    process.env.REACT_APP_VIRTUAL_TOUR_DATA_LOCATION
  )
    return process.env.REACT_APP_VIRTUAL_TOUR_DATA_LOCATION;

  return `/map/${type}`;
};

export const fetchDataFromPublicDir = (type: MapDataType, path: string) => {
  return fetch(`${getFilePathPrefix(type)}/${path}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }).then(response => response.json());
};
