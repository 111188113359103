import type { FC } from 'react';
import styled from 'styled-components';

import { useMapData } from '../../map-data/use-map-data';
import type { PointOfInterestRecord } from '../../map-types';

export interface PoiResultItemComponentProps {
  hit: {
    objectID: string;
  };
  onResultClick?: (record: PointOfInterestRecord) => void;
}

const ResultWrapper = styled.div`
  cursor: pointer;
  padding: 0.9rem;
  width: 100%;
`;

export const PoiResultItem: FC<PoiResultItemComponentProps> = ({
  hit,
  onResultClick,
}) => {
  const { loading, studiesById } = useMapData();

  const record = studiesById?.[hit.objectID];

  if (loading || !record) {
    return null;
  }

  return (
    <ResultWrapper
      onClick={() => onResultClick?.({ ...record, id: hit.objectID })}
    >{`${record.studyName}`}</ResultWrapper>
  );
};
