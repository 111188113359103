import { clsx } from 'clsx';
import { createUseStyles } from 'react-jss';
import { useEffect } from 'react';

import type { LcpnTheme } from '../../../styles/theme';
import type { HeatMapCountryMetaData } from '../../../map-data/map-data-types';
import { Icon } from '../../Icon';
import { Typography } from '../../Typography';
import { COUNTRY_LIST_SUB_HEADING } from '../constants';
import { getHeatmapCountryListScrollContainer } from '../get-heatmap-country-list-scroll-container';

import { CountryMetaData } from './CountryMetaData';

/* Warning, this has been duplicated from the ProgrammeRow */
const useStyles = createUseStyles((theme: LcpnTheme) => ({
  programmeRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors['grey-55'],
    borderTop: `${theme.colors['white']} solid 2px`,
    padding: 4,
    cursor: 'pointer',
    width: '100%',
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginLeft: 'clamp(32px, 5.5%, 32px)',
  },

  title: {
    color: theme.colors['green-100'],
  },

  titleDefault: {
    color: theme.colors.default,
  },

  icon: {
    marginRight: 24,
  },

  status: {
    padding: '16px 16px 16px 0px',
  },

  listItemWrapper: {
    margin: '0',
  },

  open: {},

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    programmeRow: {
      '&&': {
        borderBottom: `solid 1px ${theme.colors['grey-100']}`,
        padding: '4px 0px 4px 0px',
        marginBottom: 0,
      },
    },
    group: {
      '&&': {
        marginLeft: 16,
      },
    },
    open: {
      '&&': {
        backgroundColor: theme.colors['grey-55'],
      },
    },
    icon: {
      '&&': {
        marginRight: 4,
      },
    },
  },
}));

type Props = {
  active: boolean;
  record: HeatMapCountryMetaData;
  handleClick: () => void;
  countryIndex: number;
};

const COUNTRY_LIST_ITEM_ID_PREFIX = 'country-list-item';

export function CountryRowAccordion({
  active,
  record,
  handleClick,
  countryIndex,
}: Props) {
  const classes = useStyles();

  useEffect(() => {
    if (!active) return;

    // get list item height
    const listItemHeight =
      document.querySelector<HTMLLIElement>(`#${COUNTRY_LIST_ITEM_ID_PREFIX}-0`)
        ?.offsetHeight ?? 0;

    // get list sub heading height
    const subHeadingHeight =
      document.querySelector<HTMLDivElement>(`#${COUNTRY_LIST_SUB_HEADING}`)
        ?.offsetHeight ?? 0;

    const scrollContainer = getHeatmapCountryListScrollContainer();

    const currentListItemHeightFromTopOfList =
      countryIndex * listItemHeight + subHeadingHeight;

    scrollContainer?.scrollTo({
      behavior: 'smooth',
      top: currentListItemHeightFromTopOfList,
    });
  }, [active, countryIndex]);

  //if (active) return <HeatmapPrintTable country={record} />;
  return (
    <li
      className={classes.listItemWrapper}
      id={`${COUNTRY_LIST_ITEM_ID_PREFIX}-${countryIndex}`}
    >
      <button
        className={clsx(classes.programmeRow, { [classes.open]: active })}
        onClick={handleClick}
      >
        <div className={classes.group}>
          <Icon
            alt={record.countryStatus}
            variant={active ? 'CollapseList' : 'ExpandList'}
            stroke="#6a6a6a"
            fill="#fff"
            width={24}
            height={24}
          />
          <div
            className={clsx(
              { [classes.titleDefault]: !active },
              { [classes.title]: active },
            )}
          >
            <Typography variant="p2">{record.countryName}</Typography>
          </div>
        </div>
        <div className={classes.status}>
          <Typography variant="p3">{record.countryStatus}</Typography>
        </div>
      </button>
      {active && <CountryMetaData record={record} active={active} />}
    </li>
  );
}
