import { clsx } from 'clsx';
import { useEffect, type FC, useMemo } from 'react';
import { createUseStyles } from 'react-jss';

import { Typography } from '../Typography';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import type { LcpnTheme } from '../../styles/theme';
import { Icon } from '../Icon';

import { StudyByRegionList } from './StudyByRegionList';
import {
  POI_COUNTRY_LIST_ITEM_ID_PREFIX,
  POI_SUBDIVISION_LIST_ITEM_ID_PREFIX,
  SubDivision,
} from './constants';
import type { CountryCount } from './PointOfInterestCountryList';
import { getCountryListScrollContainer } from './get-list-scroll-container';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  subDivisionList: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors['grey-10'],
  },

  subDivision: {
    paddingLeft: '48px',
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    borderTop: `${theme.colors['grey-100']} solid 1px`,
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      background: `${theme.colors['blue-100']}`,
      color: `${theme.colors['white']}`,
    },
    '&:focus-visible': {
      outline: 'auto',
    },
    '&:hover $subdivisionCount': { color: `${theme.colors['white']}` },
  },

  active: {
    paddingLeft: '13px',
    background: theme.colors['blue-100'],
    color: `${theme.colors['white']}`,
    '&:hover $subdivisionCount': { display: 'none' },
  },

  listItem: {
    marginBottom: 0,
  },

  subdivisionCount: {
    color: '#505050',
    opacity: 0.6,
    paddingLeft: '0.4rem',
  },
}));

interface Props {
  subDivisions: string[];
  subDivisionType: SubDivision;
  studyCountry: string;
  studyCountByCountry: Record<string, CountryCount>;
  countryIndex: number;
}

export const SubDivisionList: FC<Props> = ({
  subDivisions,
  subDivisionType,
  studyCountry,
  studyCountByCountry,
  countryIndex,
}) => {
  const classes = useStyles();

  const { currentListItemHeightFromTopOfList, listItemHeight } = useMemo(() => {
    // get list item height
    const listItemHeight =
      document.querySelector<HTMLLIElement>(
        `#${POI_COUNTRY_LIST_ITEM_ID_PREFIX}-${countryIndex}`,
      )?.offsetHeight ?? 0;

    // Take height from bottom of list item and will add the offset later so we
    // use the value to determine height of the study by region list sub items
    const currentListItemHeightFromTopOfList =
      (countryIndex + 1) * listItemHeight;

    return { currentListItemHeightFromTopOfList, listItemHeight };
  }, [countryIndex]);

  const {
    selectedCountrySubDivisionName,
    setSelectedCountrySubDivisionName,
    setShowCountryCard,
    setShowCityCard,
    setShowProgramCard,
  } = usePoiSelectedLocations();

  // handle scroll to list item on open and child study be region list doesn't have an
  // open item
  useEffect(() => {
    const scrollContainer = getCountryListScrollContainer();

    if (
      !selectedCountrySubDivisionName?.city &&
      !selectedCountrySubDivisionName?.region
    ) {
      scrollContainer?.scrollTo({
        behavior: 'smooth',
        top: currentListItemHeightFromTopOfList - listItemHeight,
      });
    }
  }, [
    currentListItemHeightFromTopOfList,
    listItemHeight,
    selectedCountrySubDivisionName,
  ]);

  const { resetNavigationBar } = useUpdateNavigationBar();

  const handleClick = (countrySubdivision: string) => {
    setSelectedCountrySubDivisionName(prevSelected =>
      prevSelected?.[subDivisionType] === countrySubdivision
        ? undefined
        : { [subDivisionType]: countrySubdivision },
    );
    resetNavigationBar();

    if (subDivisionType === SubDivision.city) {
      setShowCountryCard(false);
      setShowCityCard(true);
      setShowProgramCard(false);
    }
  };

  return (
    <ul className={classes.subDivisionList}>
      {subDivisions.map((subDivision, index) => {
        const isSelected =
          subDivision === selectedCountrySubDivisionName?.[subDivisionType];
        const subDivisionStudyCount =
          studyCountByCountry[studyCountry][subDivisionType][subDivision] ??
          'err';

        return (
          <li key={index} className={classes.listItem}>
            <button
              className={clsx(classes.subDivision, {
                [classes.active]: isSelected,
              })}
              onClick={() => handleClick(subDivision)}
              id={`${POI_SUBDIVISION_LIST_ITEM_ID_PREFIX}-${index}`}
            >
              {isSelected && (
                <Icon
                  variant="CollapseList"
                  width={24}
                  height={24}
                  stroke="#6a6a6a"
                  fill="#fff"
                  style={{ paddingRight: '11px' }}
                />
              )}
              <Typography variant="p1">
                {subDivision}
                <span className={classes.subdivisionCount}>
                  ({subDivisionStudyCount})
                </span>
              </Typography>
            </button>
            {isSelected && (
              <StudyByRegionList
                selectedLocale={subDivision}
                localeType={subDivisionType}
                listCountry={studyCountry}
                subDivisionIndex={index}
                parentListHeight={currentListItemHeightFromTopOfList}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
