import type { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { Typography } from '../Typography';

const useStyles = createUseStyles({
  card: {
    background: 'rgba(255, 255, 255, 0.85)',
    color: '#6a6a6a',
    borderRadius: 12.5,
    padding: '1rem',
  },

  cardHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
  },

  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },

  closeBtn: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
    cursor: 'pointer',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
  },

  expandButton: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'flex-end',
    textDecoration: 'underline',
    padding: 16,
  },

  information: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  scrollbar: {
    height: 177,
  },
});

type Props = {
  closeCard: (value: boolean) => void;
  cityName: string;
  countryName?: string;
  totalNationalProgrammes?: number;
  totalRegionalProgrammes?: number;
  totalStudiesProgrammes?: number;
  totalClinicalProgrammes?: number;
};

export const CityCard: FC<Props> = ({
  closeCard,
  cityName,
  countryName,
  totalNationalProgrammes,
  totalRegionalProgrammes,
  totalClinicalProgrammes,
  totalStudiesProgrammes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.card}>
      <div className={classes.cardHead}>
        <div>
          <div className={classes.cardTitle}>
            <div className={classes.cardGroup}>
              <Typography variant="p3" fontWeight={400}>
                {t('card.city')}
              </Typography>
              <Typography variant="p1">{cityName}</Typography>
            </div>
          </div>
          {countryName && (
            <div className={classes.cardTitle}>
              <div className={classes.cardGroup}>
                <Typography variant="p3" fontWeight={400}>
                  {t('card.country')}
                </Typography>
                <Typography variant="p1">{countryName}</Typography>
              </div>
            </div>
          )}
        </div>
        <button onClick={() => closeCard(false)} className={classes.closeBtn}>
          <Icon variant="Close" width={24} height={24} />
        </button>
      </div>
      <div className={classes.cardBody}>
        <Typography variant="p2">
          <ul>
            <li>
              <span
                style={{
                  color: '#5A5A5A',
                  fontSize: '1rem',
                }}
              >
                {t('card.nationalProgrammes')}: {totalNationalProgrammes ?? 0}
              </span>
            </li>
            <li>
              <span
                style={{
                  color: '#5A5A5A',
                  fontSize: '1rem',
                }}
              >
                {t('card.regionalProgrammes')}: {totalRegionalProgrammes ?? 0}
              </span>
            </li>
            <li>
              <span
                style={{
                  color: '#5A5A5A',
                  fontSize: '1rem',
                }}
              >
                {t('card.implementationStudies')}: {totalStudiesProgrammes ?? 0}
              </span>
            </li>
            <li>
              <span
                style={{
                  color: '#5A5A5A',
                  fontSize: '1rem',
                }}
              >
                {t('card.clinicalTrials')}: {totalClinicalProgrammes ?? 0}
              </span>
            </li>
          </ul>
        </Typography>
      </div>
    </div>
  );
};
