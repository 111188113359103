import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { useMapData } from '../../../map-data/use-map-data';
import { useComparisonProviderContext } from '../../../providers/ComparisonProvider';
import type { HeatMapCountryMetaData } from '../../../../lcpn-uploader/server/services/parse-map-records/heat-map/heat-map-types';
import { Typography } from '../../Typography';

import { DataDisplayTable } from './DataTable';
import { useHeatMapDataTableDefinitions } from './UseHeatMapDataTableDefinitions';

export function HeatmapComparisonTable() {
  const { t } = useTranslation();

  const { countryHeatMapMetaData } = useMapData();

  const [countries, setCountries] = useState<
    [HeatMapCountryMetaData | undefined, HeatMapCountryMetaData | undefined]
  >([undefined, undefined]);
  const { heatMapComparison, setHeatMapComparison } =
    useComparisonProviderContext();

  const { headings, sections } = useHeatMapDataTableDefinitions();

  useEffect(() => {
    if (!!countryHeatMapMetaData && !!heatMapComparison) {
      if (
        !!countryHeatMapMetaData[heatMapComparison.fromCountryCode!] &&
        !!countryHeatMapMetaData[heatMapComparison.toCountryCode!]
      ) {
        setCountries([
          countryHeatMapMetaData[heatMapComparison.fromCountryCode!],
          countryHeatMapMetaData[heatMapComparison.toCountryCode!],
        ]);
      } else {
        setHeatMapComparison({
          fromCountryCode: undefined,
          toCountryCode: undefined,
        });
      }
    }
  }, [countryHeatMapMetaData, heatMapComparison, setHeatMapComparison]);

  const [countryFrom, countryTo] = countries;

  return (
    <Stack spacing={4}>
      <div style={{ color: '#008B7F' }}>
        <Typography variant="h2">
          <strong>{t('comparisonTable.heatMap.title')}:</strong>
          <span>
            <br />
            {t('comparisonTable.heatMap.subtitle')}
          </span>
        </Typography>
      </div>
      {countryFrom && countryTo && (
        <DataDisplayTable<HeatMapCountryMetaData>
          headings={headings}
          type={'heatmap'}
          items={[countryFrom, countryTo]}
          sections={sections}
        />
      )}
    </Stack>
  );
}
