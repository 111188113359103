import { useRef, type FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../Icon';
import type { LcpnTheme } from '../../../styles/theme';
import { HeatmapPrintTable } from '../../ComparisonTool/ComparisonTable/HeatmapPrintTable';
import { ClipboardButton } from '../../ClipboardButton';
import { ShareLink } from '../../DefinitionTable/ShareLink';
import { DefinitionTable } from '../../DefinitionTable';
import { getRgb } from '../../PinMap/get-rgb';
import type { HeatMapCountryMetaData } from '../../../map-data/map-data-types';

import { StatusBar } from './StatusBar';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  printContainer: {
    '& *': { boxSizing: 'border-box !important' },
    width: '100%',
    height: '100%',
    webkitPrintColorAdjust: 'exact !important',
    printColorAdjust: 'exact',
    padding: 0,
    display: 'block',
    '@media screen': {
      '&&': {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
      },
    },
  },
}));

interface Props {
  record: HeatMapCountryMetaData;
  active: boolean;
}

export const CountryMetaData: FC<Props> = ({ record, active }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: classes.printContainer,
    pageStyle: '@page { size: auto, margin: 10mm;  }',
  });

  const shareUrl =
    process.env.REACT_APP_CAMPAIGN_URL +
    '?heatmapId=' +
    record.countryIso3166_1_2Name;

  const tweetUrl = new URL('https://twitter.com/intent/tweet?');
  tweetUrl.searchParams.append('text', t('countryRow.tweetText'));
  tweetUrl.searchParams.append('url', shareUrl);
  tweetUrl.searchParams.append('hashtags', 'LDCT,LungCancer,LCSM');
  tweetUrl.searchParams.append('via', 'LungPolicy');

  return (
    <div>
      <StatusBar
        country={record.countryName}
        status={record.countryStatus}
        footnote={record.countryStatusFootnote}
      />
      <DefinitionTable
        backgroundColor={`rgb(${getRgb('')})`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t('countryRow.definitionTables.participantOutcomesLDCT.title')}
        rows={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesLDCT.previousTrials',
            ),
            value: record.previousLcdtScreening,
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesLDCT.pilots',
            ),
            value: record.pilotsLcdtScreening,
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesLDCT.organisedProgrammes',
            ),
            value: record.hasOrganisedLdctProgramme,
          },
        ]}
      />
      <DefinitionTable
        backgroundColor={`rgb(${getRgb('')})`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t('countryRow.definitionTables.participantOutcomesNCCP.title')}
        rows={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesNCCP.availability',
            ),
            value: record.nccpAvailable,
            fallback: '—',
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesNCCP.lastPublished',
            ),
            value: record.nccpLastUpdated,
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesNCCP.earlyDetection',
            ),
            value: record.earlyDetectionInNccp,
          },
          {
            key: t('countryRow.definitionTables.participantOutcomesNCCP.PBCR'),
            value: record.pbcr,
            fallback: '—',
          },
        ]}
      />
      <DefinitionTable
        backgroundColor={`rgb(34, 94, 163)`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t(
          'countryRow.definitionTables.participantOutcomesIncidence.title',
        )}
        rows={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesIncidence.bothSexes',
            ),
            value:
              record.incidenceCombined > 0
                ? record.incidenceCombined.toFixed(1)
                : '—',
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesIncidence.male',
            ),
            value:
              record.incidenceMale > 0 ? record.incidenceMale.toFixed(1) : '—',
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesIncidence.female',
            ),
            value:
              record.incidenceFemale > 0
                ? record.incidenceFemale.toFixed(1)
                : '—',
          },
        ]}
        footnotes={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesIncidence.footnotesTitle',
            ),
            value: t(
              'countryRow.definitionTables.participantOutcomesIncidence.footnotesBody',
            ),
            isOpen: true,
          },
        ]}
      />
      {/** TODO: START - ONLY DISPLAY ON MOBILE **/}
      <DefinitionTable
        backgroundColor={`rgb(163, 16, 22)`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t(
          'countryRow.definitionTables.participantOutcomesMortality.title',
        )}
        rows={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesMortality.bothSexes',
            ),
            value:
              record.mortalityCombined > 0
                ? record.mortalityCombined.toString()
                : '—',
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesMortality.male',
            ),
            value:
              record.mortalityMale > 0 ? record.mortalityMale.toString() : '—',
          },
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesMortality.female',
            ),
            value:
              record.mortalityFemale > 0
                ? record.mortalityFemale.toString()
                : '—',
          },
        ]}
        footnotes={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesMortality.footnotesTitle',
            ),
            value: t(
              'countryRow.definitionTables.participantOutcomesMortality.footnotesBody',
            ),
            isOpen: true,
          },
        ]}
      />

      {/** TODO: END - ONLY DISPLAY ON MOBILE **/}
      <DefinitionTable
        backgroundColor={`rgb(70, 76, 89)`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t(
          'countryRow.definitionTables.participantOutcomesAdditional.title',
        )}
        rows={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesAdditional.lastUpdated',
            ),
            value: record.updated ? record.updated.toString() : '',
          },
          {
            key: t(
              'programmeRow.definitionTables.participantOutcomesAdditional.publicationApproved',
            ),
            value: record.publicationApproved === '3' ? 'Yes' : 'No',
          },
        ]}
        footnotes={[
          {
            key: t(
              'countryRow.definitionTables.participantOutcomesAdditional.footnotesTitle',
            ),
            value: record.references,
          },
        ]}
      />
      <ShareLink>
        <ClipboardButton
          clipboardText={shareUrl}
          sx={{ textTransform: 'none' }}
          color={'inherit'}
          size={'small'}
          variant={'text'}
          startIcon={<Icon variant="ShareLink" width={24} height={24} />}
        >
          {t('programmeRow.share.shareLink')}
        </ClipboardButton>
        <Button
          sx={{ textTransform: 'none' }}
          color={'inherit'}
          size={'small'}
          onClick={() => {
            window.open(tweetUrl, '_blank')?.focus();
          }}
          variant={'text'}
          startIcon={<Icon variant="Twitter" width={22} height={22} />}
        >
          {t('programmeRow.share.tweet')}
        </Button>
        {active && (
          <>
            <Button
              sx={{ textTransform: 'none' }}
              color={'inherit'}
              size={'small'}
              onClick={handlePrint}
              variant={'text'}
              startIcon={<Icon variant="Print" width={22} height={22} />}
            >
              {t('programmeRow.share.print')}
            </Button>
            <div className={classes.printContainer} ref={componentRef}>
              <HeatmapPrintTable country={record} />
            </div>
          </>
        )}
      </ShareLink>
    </div>
  );
};
