import { useContext, useMemo } from 'react';

import { FilteredPoiMapDataContext } from './FilteredPoiMapDataProvider';

/**
 * useFilteredMapData
 *
 * Simple hook to expose the filtered map data state as held in the FilteredMapDataContext
 */
export const useFilterMapData = () => {
  const filteredMapData = useContext(FilteredPoiMapDataContext);

  if (!filteredMapData) {
    throw new Error(
      'useMapData hook it being used outside of the MapDataProvider',
    );
  }

  const { filteredPoiCityMetaData, filteredStudiesById, filteredPoiStudyData } =
    useMemo(
      () => ({
        filteredPoiCityMetaData: filteredMapData.filteredPoiCityMetaData,
        filteredStudiesById: filteredMapData.filteredStudiesById,
        filteredPoiStudyData: filteredMapData.filteredPoiStudyData,
      }),
      [
        filteredMapData.filteredPoiCityMetaData,
        filteredMapData.filteredStudiesById,
        filteredMapData.filteredPoiStudyData,
      ],
    );

  return {
    filteredPoiCityMetaData,
    filteredStudiesById,
    filteredPoiStudyData,
  };
};
