import type { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { FlagIcon } from '../Flag';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

const useStyles = createUseStyles({
  card: {
    background: 'rgba(255, 255, 255, 0.85)',
    color: '#6a6a6a',
    borderRadius: 12.5,
    padding: '1rem',
    width: '12rem',
  },

  cardHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  closeBtn: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },

  cardTitle: {
    marginBottom: 10,
  },

  listValue: {
    color: '#5A5A5A',
    fontSize: '1rem',
  },
});

type Props = {
  countryCode: string;
  countryName: string;
  totalNationalProgrammes?: number;
  totalRegionalProgrammes?: number;
  totalStudiesProgrammes?: number;
  totalClinicalProgrammes?: number;
  closeCard: (value: boolean) => void;
  children?: JSX.Element | JSX.Element[];
};

export const CountryCard: FC<Props> = ({
  countryName,
  countryCode,
  totalNationalProgrammes,
  totalRegionalProgrammes,
  totalStudiesProgrammes,
  totalClinicalProgrammes,
  closeCard,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.card}>
      <div className={classes.cardHead}>
        <div className={classes.cardTitle}>
          <Typography variant="p1">
            <>
              {t('card.informationCard')}:
              <br />
              {countryName}
            </>
          </Typography>
        </div>
        <button onClick={() => closeCard(false)} className={classes.closeBtn}>
          <Icon variant="Close" width={24} height={24} />
        </button>
      </div>
      <div className={classes.cardBody}>
        <FlagIcon countryCode={countryCode} />
        {children ?? (
          <>
            <Typography variant="p2">
              <ul>
                <li>
                  {t('card.nationalProgrammes')}:{' '}
                  <span className={classes.listValue}>
                    {totalNationalProgrammes ?? 0}
                  </span>
                </li>
                <li>
                  {t('card.regionalProgrammes')}:{' '}
                  <span className={classes.listValue}>
                    {totalRegionalProgrammes ?? 0}
                  </span>
                </li>
                <li>
                  {t('card.implementationStudies')}:{' '}
                  <span className={classes.listValue}>
                    {totalStudiesProgrammes ?? 0}
                  </span>
                </li>
                <li>
                  {t('card.clinicalTrials')}:{' '}
                  <span className={classes.listValue}>
                    {totalClinicalProgrammes ?? 0}
                  </span>
                </li>
              </ul>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
