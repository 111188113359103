import { Scrollbars as ReactScrollbar } from 'react-custom-scrollbars-2';
import { createUseStyles } from 'react-jss';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

const useStyles = createUseStyles({
  scrollBarTrackY: {
    backgroundColor: 'rgba(255,255,255,0)',
    boxSizing: 'border-box',
    bottom: 0,
    top: 0,
    right: 0,
    borderRadius: '0',
    width: ['6px', '!important'],
  },
  scrollBarThumbY: {
    cursor: 'pointer',
    borderRadius: '0',
    backgroundColor: 'rgba(0,0,0,.4)',
    width: ['6px', '!important'],
    margin: 'auto',
  },
});

type ScrollbarProps = {
  children: React.ReactNode;
  renderBoxProps?: BoxProps;
};

export function Scrollbar({ children, renderBoxProps = {} }: ScrollbarProps) {
  const classes = useStyles();

  return (
    <ReactScrollbar
      renderView={props => <Box {...props} {...renderBoxProps} />}
      renderTrackVertical={props => (
        <div {...props} className={classes.scrollBarTrackY} />
      )}
      renderThumbVertical={props => (
        <div {...props} className={classes.scrollBarThumbY} />
      )}
      thumbSize={300}
    >
      <div className="scrollbarContent">{children}</div>
    </ReactScrollbar>
  );
}
