import { useEffect } from 'react';

import './tour.css';

import { useMapData } from '../../map-data/use-map-data';

import { getVirtualTourCompleteStatusFromLocalStorage } from './virtual-tour-local-storage';
import { useVirtualTourConfig } from './VirtualTourProvider';
import { virtualTour } from './shepherd-tours';

/**
 * useVirtualTour
 *
 * Instantiates Shepherd virtual tour, if not already completed, and extended tour dependant if also
 * there are updates.
 */
export const useVirtualTour = () => {
  const { loading: mapDataLoading } = useMapData();
  const { isReady } = useVirtualTourConfig();

  // start virtual tour if not run before and is ready
  useEffect(() => {
    const tourStatus = getVirtualTourCompleteStatusFromLocalStorage();

    if (!tourStatus && !virtualTour.isActive() && isReady && !mapDataLoading) {
      virtualTour.start();
    }
  }, [mapDataLoading, isReady]);
};
