import { clsx } from 'clsx';
import { createUseStyles } from 'react-jss';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../Typography';
import type { LcpnTheme } from '../../../styles/theme';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  wrapper: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.white,
    borderTop: `${theme.colors['grey-100']} solid 2px`,
    paddingLeft: 4,
    paddingRight: 4,
  },

  title: {
    marginLeft: 'clamp(32px, 5.5%, 32px)',
    color: theme.colors['green-100'],
    paddingTop: 16,
    paddingBottom: 24,
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 600,
    borderBottom: `${theme.colors['green-10']} solid 2px`,
    paddingRight: '5em',
  },

  status: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginLeft: 'clamp(32px, 5.5%, 32px)',
    boxSizing: 'border-box',
    paddingBottom: 16,
    borderBottomWidth: '4px',
    borderBottomColor: theme.colors.white,
    borderBottomStyle: 'solid',
  },

  footnote: {
    marginLeft: 'clamp(32px, 5.5%, 32px)',
    paddingTop: 16,
    paddingBottom: 16,
  },

  active: {
    color: theme.colors['green-100'],
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    status: {
      '&&': {
        marginLeft: 8,
      },
    },
    title: {
      '&&': {
        marginLeft: 8,
      },
    },
  },
}));

interface StatusBarProps {
  country: string;
  status: string;
  footnote?: string;
}

export const StatusBar: FC<StatusBarProps> = ({
  country,
  status,
  footnote,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // array of values (should be exposed from types)
  const STATUSES = [
    {
      key: 'Unknown status',
      title: t('countryRow.statusBar.unknownStatus'),
      color: '222, 112, 107',
    },
    {
      key: 'Implementation research',
      title: t('countryRow.statusBar.implementationResearch'),
      color: '158, 18, 66',
    },
    {
      key: 'Formal commitment',
      title: t('countryRow.statusBar.formalCommitment'),
      color: '75, 171, 214',
    },
    // Removed from data-set, but to be added in the future
    // { key: 'Regional roll-out',  title: t('countryRow.statusBar.regionalRollOut'),color: '222, 112, 107' },
    // { key: 'National roll-out', title: t('countryRow.statusBar.nationalRollOut'), color: '244, 180, 50' },
    {
      key: 'Implementation roll-out',
      title: t('countryRow.statusBar.implementationRollOut'),
      color: '244, 180, 50',
    },
  ] as const;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Typography variant="p3">
          {t('countryRow.statusBar.stage', { replace: { country } })}
        </Typography>
      </div>
      <div className={classes.group}>
        {STATUSES.map((value, i) => (
          <div
            className={clsx(classes.status, {
              [classes.active]: status === value.key,
            })}
            style={{
              borderBottomColor:
                status === value.key
                  ? 'rgba(' + value.color + ')'
                  : 'transparent',
              color:
                status === value.key ? 'rgba(' + value.color + ')' : 'initial',
            }}
            key={`status` + i}
          >
            <Typography variant="p3">{value.title}</Typography>
          </div>
        ))}
      </div>
      {footnote && (
        <div className={classes.footnote}>
          <Typography variant="p3">{footnote}</Typography>
        </div>
      )}
    </div>
  );
};
