import { clsx } from 'clsx';
import type { FC } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { Scrollbar } from '../Scrollbar';
import { Typography } from '../Typography';

const TRUNCATE_MAX_LENGTH = 160;

const useStyles = createUseStyles({
  card: {
    background: 'rgba(255, 255, 255, 0.85)',
    borderRadius: 12.5,
    padding: '1rem',
    width: '100%',
    boxSizing: 'border-box',
    '&.expanded': {
      paddingBottom: '4rem',
    },
  },

  cardHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
  },

  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
  },

  closeBtn: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
    cursor: 'pointer',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 8,
  },

  information: {
    marginTop: 16,
    textOverflow: 'hidden',
  },

  expandButton: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'flex-end',
    marginBottom: 60,
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  collapseButton: {
    marginTop: 'auto',
    marginLeft: 'auto',
    textDecoration: 'underline',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  scrollbarHeight: {
    height: '100%',
  },

  scrollbarBody: {
    height: '80%',
  },
});

type Props = {
  closeCard: (value: boolean) => void;
  cityName: string;
  countryName: string;
  implementation: string;
  information: string;
  expand: boolean | null;
  handleExpand: (value: boolean | null) => void;
  region: string | undefined;
};

export const ProgrammeCard: FC<Props> = ({
  closeCard,
  cityName,
  countryName,
  implementation,
  information,
  expand,
  region,
  handleExpand,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [closeHover, setCloseHover] = useState(false);

  return (
    <div
      className={clsx(classes.card, {
        [classes.scrollbarHeight]: expand,
        expanded: expand,
      })}
    >
      <div className={classes.cardHead}>
        <div className={classes.cardTitle}>
          <div className={classes.container}>
            <div className={classes.cardTitle}>
              <div className={classes.cardGroup}>
                <Typography variant="p3" fontWeight={400}>
                  {t('card.entryType')}
                </Typography>
                <Typography variant="p1" color="black">
                  {implementation}
                </Typography>
              </div>
            </div>

            <div className={classes.cardTitle}>
              <div className={classes.cardGroup}>
                <Typography variant="p3" fontWeight={400}>
                  {t('card.country')}
                </Typography>
                <Typography variant="p1">{countryName}</Typography>
              </div>
            </div>
            <div className={classes.cardTitle}>
              <div className={classes.cardGroup}>
                <Typography variant="p3" fontWeight={400}>
                  {t('card.region')}
                </Typography>
                <Typography variant="p1">{region ?? '—'}</Typography>
              </div>
            </div>
            <div className={classes.cardTitle}>
              <div className={classes.cardGroup}>
                <Typography variant="p3" fontWeight={400}>
                  {t('card.city')}
                </Typography>
                <Typography variant="p1">{cityName}</Typography>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => closeCard(false)}
          className={classes.closeBtn}
          onMouseEnter={() => setCloseHover(true)}
          onMouseLeave={() => setCloseHover(false)}
        >
          {closeHover ? (
            <Icon variant="CloseHover" width={24} height={24} />
          ) : (
            <Icon variant="Close" width={24} height={24} />
          )}
        </button>
      </div>
      {information && (
        <div
          className={clsx(classes.cardBody, {
            [classes.scrollbarBody]: expand,
          })}
        >
          {expand ? (
            <div
              className={clsx(classes.information, {
                [classes.scrollbarHeight]: expand,
              })}
            >
              <Scrollbar renderBoxProps={{ sx: { pb: 8, pr: 4 } }}>
                <Typography variant="p3" fontWeight={400}>
                  {information}
                </Typography>
              </Scrollbar>
            </div>
          ) : (
            <div>
              <Typography variant="p3" fontWeight={400}>
                {`${information.slice(0, TRUNCATE_MAX_LENGTH).trim()}...`}
              </Typography>
            </div>
          )}
          <button
            className={classes.expandButton}
            onClick={() => handleExpand(!expand)}
          >
            <Typography variant="p3">
              {expand ? t('card.showLess') : t('card.showMore')}
            </Typography>
          </button>
        </div>
      )}
    </div>
  );
};
