import type { SVGProps } from 'react';

import { ReactComponent as ActiveComparison } from './active_comparison.svg';
import { ReactComponent as ActiveCheck } from './active_check.svg';
import { ReactComponent as ActiveFilter } from './active_filter.svg';
import { ReactComponent as ActiveExpand } from './active_expand.svg';
import { ReactComponent as ActiveMap } from './active_map.svg';
import { ReactComponent as ActiveStart } from './active_start.svg';
import { ReactComponent as ActiveWorld } from './active_world.svg';
import { ReactComponent as BlackTriangle } from './black_triangle.svg';
import { ReactComponent as Calculator } from './calculator.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as CircleChevron } from './circle_chevron.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as CloseHover } from './close_hover.svg';
import { ReactComponent as CollapseList } from './collapse_list.svg';
import { ReactComponent as Computer } from './computer.svg';
import { ReactComponent as DateRange } from './date_range.svg';
import { ReactComponent as EligibilityChecker } from './eligibility_checker.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as ExpandList } from './expand_list.svg';
import { ReactComponent as FactCheck } from './fact_check.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as FingerPrint } from './finger_print.svg';
import { ReactComponent as FullscreenContract } from './fullscreen-contract.svg';
import { ReactComponent as FullscreenExpand } from './fullscreen-expand.svg';
import { ReactComponent as FurtherDetails } from './further_detail.svg';
import { ReactComponent as Find } from './find.svg';
import { ReactComponent as Globe } from './globe.svg';
import { ReactComponent as GreyFilter } from './grey_filter.svg';
import { ReactComponent as GreyMap } from './grey_map.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as MapInverted } from './map_inverted.svg';
import { ReactComponent as MapInvertedBlue } from './map_inverted_blue.svg';
import { ReactComponent as ParticipantOutcomes } from './participant_outcomes.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as Print } from './print.svg';
import { ReactComponent as ShareLink } from './share_link.svg';
import { ReactComponent as SmokeFree } from './smoke_free.svg';
import { ReactComponent as Start } from './start.svg';
import { ReactComponent as StudyDesignCheck } from './studydesign_check.svg';
import { ReactComponent as Target } from './target.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as World } from './world.svg';
import { ReactComponent as Add } from './add.svg';

const iconMap = {
  ActiveCheck,
  ActiveComparison,
  ActiveExpand,
  ActiveFilter,
  ActiveMap,
  ActiveStart,
  ActiveWorld,
  BlackTriangle,
  Calculator,
  Circle,
  CircleChevron,
  Check,
  Close,
  CloseHover,
  CollapseList,
  Computer,
  DateRange,
  EligibilityChecker,
  Expand,
  ExpandList,
  FactCheck,
  Filter,
  FingerPrint,
  FullscreenContract,
  FullscreenExpand,
  FurtherDetails,
  Find,
  Globe,
  GreyFilter,
  GreyMap,
  Info,
  Map,
  MapInverted,
  MapInvertedBlue,
  ParticipantOutcomes,
  Person,
  Print,
  ShareLink,
  SmokeFree,
  Start,
  StudyDesignCheck,
  Target,
  Twitter,
  World,
  Add,
} as const;

export type IconVariant = keyof typeof iconMap;

interface Props extends SVGProps<SVGSVGElement> {
  alt?: string;
  variant?: IconVariant;
  width: number;
  height: number;
}

export function Icon({
  variant,
  width = 77,
  height = 74,
  alt = '',
  ...rest
}: Props) {
  if (!variant) {
    return null;
  }

  const IconComponent = iconMap[variant];

  return <IconComponent width={width} height={height} {...rest} />;
}
