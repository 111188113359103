import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import {
  Configure,
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-hooks-web';

import { usePoiFilterOptions } from '../../map-data/use-filter-options';
import { algoliaSearchClient } from '../../algolia-search';

interface PoiSearchProps {
  children: ReactNode;
  placeholder: string;
}

export const PoiSearch: FC<PoiSearchProps> = ({ children, placeholder }) => {
  const { filterOptions } = usePoiFilterOptions();
  const poiStudyIndex = process.env.REACT_APP_ALGOLIA_INDEX_POI || '';
  const facetFilters = useMemo(() => {
    return Object.entries(filterOptions).map(
      ([key, value]) => `${key}: ${value}`,
    );
  }, [filterOptions]);

  return (
    <InstantSearch searchClient={algoliaSearchClient} indexName={poiStudyIndex}>
      <SearchBox placeholder={placeholder} />
      <Configure facetFilters={facetFilters} />
      {children}
    </InstantSearch>
  );
};
