import type { FC, ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useState, useMemo, useContext } from 'react';

export type MapExperience = 'heatMap' | 'pinMap';
export type NavigationItem = 'filter' | 'map' | 'comparison';

interface NavigationBarContextProps {
  selectedNavigationItem?: NavigationItem;
  selectedExperience?: MapExperience;
  setSelectedExperience: Dispatch<SetStateAction<MapExperience | undefined>>;
}

interface UpdateNavigationBarContextProps {
  setSelectedNavigationItem: Dispatch<
    SetStateAction<NavigationItem | undefined>
  >;
}

const NavigationBarContext = createContext<
  NavigationBarContextProps | undefined
>(undefined);
const UpdateNavigationBarContext = createContext<
  UpdateNavigationBarContextProps | undefined
>(undefined);

interface Props {
  children: ReactNode;
}

export const useNavigationBarData = () => {
  const filteredData = useContext(NavigationBarContext);

  if (!filteredData) {
    throw new Error(
      'useNavigationBarData hook it being used outside of the NavigationBarProvider',
    );
  }

  return filteredData;
};

export const useUpdateNavigationBar = () => {
  const filteredData = useContext(UpdateNavigationBarContext);

  if (!filteredData) {
    throw new Error(
      'useNavigationBarData hook it being used outside of the NavigationBarProvider',
    );
  }

  const { setSelectedNavigationItem } = filteredData;

  const updateNavigationCallbacks = useMemo(() => {
    return {
      resetNavigationBar: () => setSelectedNavigationItem(undefined),
      updateSelectedNavigationItem: setSelectedNavigationItem,
    };
  }, [setSelectedNavigationItem]);

  return updateNavigationCallbacks;
};

export const NavigationBarProvider: FC<Props> = ({ children }) => {
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<
    NavigationItem | undefined
  >(undefined);
  const [selectedExperience, setSelectedExperience] = useState<
    MapExperience | undefined
  >('pinMap');

  const memorizedContextValue = useMemo(
    () => ({
      selectedNavigationItem,
      selectedExperience,
      setSelectedExperience,
    }),
    [selectedNavigationItem, selectedExperience],
  );

  const memorizedUpdateContextValue = useMemo(
    () => ({
      setSelectedNavigationItem,
    }),
    [],
  );

  return (
    <UpdateNavigationBarContext.Provider value={memorizedUpdateContextValue}>
      <NavigationBarContext.Provider value={memorizedContextValue}>
        {children}
      </NavigationBarContext.Provider>
    </UpdateNavigationBarContext.Provider>
  );
};
