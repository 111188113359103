import { Select, MenuItem, FormControl, OutlinedInput } from '@mui/material';
import type { FC } from 'react';

import type { SelectFilterConfig } from './filter-types';
import { NestedFilterOption } from './FilterOption';
import { useFilterItemStyles } from './use-filter-item-styles';

interface Props {
  selectFilterConfig: SelectFilterConfig;
  selectedValue: string;
}

export const SelectFilter: FC<Props> = ({
  selectFilterConfig,
  selectedValue,
}) => {
  const classes = useFilterItemStyles();
  const {
    icon,
    title,
    keyOptions: filterOptions,
    key,
    placeholder,
    onUpdate,
  } = selectFilterConfig;

  return (
    <div className={classes.item} key={title + key}>
      <NestedFilterOption icon={icon} title={title}>
        <FormControl>
          <Select<string | undefined>
            value={selectedValue}
            label={title}
            displayEmpty
            sx={{
              width: '300px',
              backgroundColor: 'white',
              color: '#5a5a5a',
              height: '48px',
            }}
            input={<OutlinedInput />}
            onChange={event => {
              onUpdate({ region: event.target.value });
            }}
            renderValue={selected => {
              if (selected === '' && !!placeholder) {
                return <em style={{ fontStyle: 'normal' }}>{placeholder}</em>;
              }

              return filterOptions.find(option => option.value === selected)
                ?.label;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {filterOptions.map(({ label, value }, index) => (
              <MenuItem value={value} key={label + index}>
                <em style={{ fontStyle: 'normal' }}>{label}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </NestedFilterOption>
    </div>
  );
};
