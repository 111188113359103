import type { ButtonProps } from '@mui/material';
import { Alert, Button, Snackbar } from '@mui/material';
import React, { useState } from 'react';

export interface Props extends ButtonProps {
  clipboardText: string;
}

export function ClipboardButton(props: Props) {
  const { clipboardText, ...rest } = props;

  const [open, setOpen] = useState(false);

  const handleClick = (href: string) => {
    navigator.clipboard.writeText(href);
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button {...rest} onClick={() => handleClick(clipboardText ?? '#')} />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="info">Link copied to clipboard</Alert>
      </Snackbar>
    </>
  );
}
