import type { FC } from 'react';
import { Box, Button } from '@mui/material';

import type { MultiChoiceFilterConfig } from './filter-types';
import { FilterOption } from './FilterOption';
import { useFilterItemStyles } from './use-filter-item-styles';

interface Props {
  multiChoiceFilterConfig: MultiChoiceFilterConfig;
  selectedValue: string;
}

const getColorProperties = (
  selected: boolean,
  color?: string,
  fallback = 'primary.main',
) => {
  if (color) {
    return selected
      ? [`rgba(${color})`, 'white']
      : [`rgba(${color}, 0.5)`, 'text.primary'];
  } else {
    return selected ? [fallback, 'white'] : ['white', fallback];
  }
};

export const MultiChoiceFilter: FC<Props> = ({
  multiChoiceFilterConfig,
  selectedValue,
}) => {
  const classes = useFilterItemStyles();
  const {
    icon,
    title,
    subtitle,
    keyOptions: filterOptions,
    key,
    onUpdate,
  } = multiChoiceFilterConfig;

  return (
    <Box className={classes.item} key={title + key}>
      <FilterOption icon={icon} title={title} subtitle={subtitle}>
        {filterOptions.map(({ label, value, color }, index) => (
          <Box key={label + index}>
            <Button
              size={'small'}
              variant={!color && selectedValue === value ? 'contained' : 'text'}
              color={color ? 'inherit' : 'primary'}
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 80,
                backgroundColor: getColorProperties(
                  selectedValue === value,
                  color,
                )[0],
                color: getColorProperties(selectedValue === value, color)[1],
                '&:hover': {
                  backgroundColor: getColorProperties(
                    selectedValue === value,
                    color,
                  )[0],
                },
              }}
              onClick={() => {
                onUpdate({ [key]: value });
              }}
            >
              {label}
            </Button>
          </Box>
        ))}
      </FilterOption>
    </Box>
  );
};
