import { useTranslation } from 'react-i18next';

import { Filter } from '../Filter';
import { usePoiFilterOptions } from '../../map-data/use-filter-options';
import { useNavigationBarData } from '../NavigationBar/NavigationBarProvider';
import type { FilterConfig } from '../Filter/filter-types';

export function PointOfInterestFilters() {
  const { selectedNavigationItem } = useNavigationBarData();
  const { t } = useTranslation();
  const {
    resetFilterOptions,
    updateFilterOptions,
    filterConfig: poiFilterDataOptions,
  } = usePoiFilterOptions();

  const filterConfig: FilterConfig = [
    [
      {
        icon: 'Check',
        title: t('poiExperience.filters.titles.status'),
        key: 'status',
        keyOptions: [
          {
            value: undefined,
            label: t('poiExperience.filters.options.seeAll'),
          },
          { value: 'Active', label: t('poiExperience.filters.options.active') },
          {
            value: 'Inactive',
            label: t('poiExperience.filters.options.inactive'),
          },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'FactCheck',
        title: t('poiExperience.filters.titles.neverSmokers'),
        subtitle: t('poiExperience.filters.subtitles.neverSmokers'),
        key: 'smokersNeverEligible',
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'SmokeFree',
        title: t('poiExperience.filters.titles.smokingCessation'),
        key: 'smokingCessationOffered',
        subtitle: t('poiExperience.filters.subtitles.smokingCessation'),
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'Person',
        title: t('poiExperience.filters.titles.sex'),
        key: 'sex',
        keyOptions: [
          {
            value: undefined,
            label: t('poiExperience.filters.options.seeAll'),
          },
          { value: 'Male', label: t('poiExperience.filters.options.male') },
          { value: 'Female', label: t('poiExperience.filters.options.female') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'Target',
        title: t('poiExperience.filters.titles.methods'),
        subtitle: t('poiExperience.filters.subtitles.methods'),
        key: 'targetOutreach',
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'Computer',
        title: t('poiExperience.filters.titles.tools'),
        key: 'useCade',
        subtitle: t('poiExperience.filters.subtitles.tools'),
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'Calculator',
        title: t('poiExperience.filters.titles.riskModelling'),
        subtitle: t('poiExperience.filters.subtitles.riskModelling'),
        key: 'riskModellingUsed',
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'FingerPrint',
        title: t('poiExperience.filters.titles.biomarkers'),
        key: 'biomarkersCollected',
        subtitle: t('poiExperience.filters.subtitles.biomarkers'),
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'Find',
        title: t('poiExperience.filters.titles.results'),
        key: 'resultsAvailable',
        subtitle: t('poiExperience.filters.subtitles.results'),
        keyOptions: [
          {
            label: t('poiExperience.filters.options.seeAll'),
            value: undefined,
          },
          { value: 'Yes', label: t('poiExperience.filters.options.yes') },
          { value: 'No', label: t('poiExperience.filters.options.no') },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
      {
        icon: 'DateRange',
        title: t('poiExperience.filters.titles.dateRange'),
        key: 'dateRange',
        rangeFrom: {
          min: poiFilterDataOptions?.dateRange.minFinish ?? 2000,
          key: 'startFinish',
        },
        rangeTo: {
          max: poiFilterDataOptions?.dateRange.maxFinish ?? 2040,
          key: 'endFinish',
        },
        onUpdate: ({ startFinish, endFinish }) => {
          const filters: Record<string, string | undefined> = {};
          const hasChangedStartDate =
            !!startFinish &&
            Number(startFinish) !== poiFilterDataOptions?.dateRange.minFinish;
          const hasChangedEndDate =
            !!endFinish &&
            Number(endFinish) !== poiFilterDataOptions?.dateRange.maxFinish;

          filters['startFinish'] = hasChangedStartDate
            ? startFinish
            : undefined;

          filters['endFinish'] = hasChangedEndDate ? endFinish : undefined;

          updateFilterOptions(filters);
        },
        filterType: 'range',
      },
      {
        icon: 'Globe',
        title: t('poiExperience.filters.titles.region'),
        key: ['continent', 'politicalRegion'],
        placeholder: t('poiExperience.filters.placeholder.region'),
        keyOptions: [
          { label: t('poiExperience.filters.options.all'), value: '' },
          ...(poiFilterDataOptions?.continents ?? []).map(continent => ({
            label: continent,
            value: continent,
          })),
          ...(poiFilterDataOptions?.politicalRegions ?? []).map(
            politicalRegion => ({
              label: politicalRegion,
              value: politicalRegion,
            }),
          ),
        ],
        onUpdate: props => {
          const updatedValue = Object.values(props)[0];

          if (!updatedValue) {
            updateFilterOptions({
              continent: undefined,
              politicalRegion: undefined,
            });
            return;
          }

          if (poiFilterDataOptions?.continents.includes(updatedValue)) {
            updateFilterOptions({
              continent: updatedValue,
              politicalRegion: undefined,
            });
          }

          if (poiFilterDataOptions?.politicalRegions.includes(updatedValue)) {
            updateFilterOptions({
              continent: undefined,
              politicalRegion: updatedValue,
            });
          }
        },
        filterType: 'select',
      },
    ],
  ];

  if (selectedNavigationItem !== 'filter') {
    return null;
  }

  return (
    <Filter
      resetFilterOptions={resetFilterOptions}
      filterConfig={filterConfig}
    />
  );
}
