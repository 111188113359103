import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { t } from 'i18next';
import { clsx } from 'clsx';

import { Icon } from '../Icon';
import { ProgrammeDetails } from '../ProgrammeDetails';
import { Typography } from '../Typography';
import type { LcpnTheme } from '../../styles/theme';
import { useMapData } from '../../map-data/use-map-data';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';

import {
  POI_STUDY_LIST_HEADER_KEY,
  POI_STUDY_LIST_ITEM_ID_PREFIX,
  type SubDivision,
} from './constants';
import { getCountryListScrollContainer } from './get-list-scroll-container';

interface Props {
  selectedLocale: string;
  localeType: SubDivision;
  listCountry: string;
  subDivisionIndex: number;
  parentListHeight: number;
}

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  level: {
    // display: 'none',
  },
  programmeRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors['white'],
    padding: '8px 0px 8px 0px',
    cursor: 'pointer',
    width: '100%',
    '&:focus-visible': {
      outline: 'auto',
    },
    borderTop: `${theme.colors['grey-100']} solid 2px`,
  },
  selectedProgrammeRow: {
    backgroundColor: '#e6f4f3',
    borderBottom: 'none',
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 13,
  },
  subHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors['blue-100'],
    height: '31px',
    backgroundColor: '#d4dee7',
    padding: '0 13px 0 49px',
  },
  studyRow: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `${theme.colors['white']} solid 2px`,
  },
  icon: {
    minWidth: 24,
  },
  title: {
    color: theme.colors['green-100'],
    padding: 5,
    textAlign: 'left',
  },
  titleDefault: {
    color: theme.colors.darker,
  },
  status: {
    padding: '16px 16px 16px 0px',
  },
  listItem: {
    marginBottom: 0,
  },
  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    level: {
      '&&': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.colors['blue-100'],
        borderBottom: `solid 1px ${theme.colors['grey-100']}`,
        padding: '8px 4px 8px 0px',
        '& > div > p': {
          marginLeft: 16,
        },
      },
    },
    programmeRow: {
      '&&': {
        borderBottom: `solid 1px ${theme.colors['grey-100']}`,
        padding: '4px 0px 4px 0px',
      },
    },
    group: {
      '&&': {
        marginLeft: 16,
      },
    },
  },
}));

export const StudyByRegionList: FC<Props> = ({
  selectedLocale,
  localeType,
  listCountry,
  subDivisionIndex,
  parentListHeight,
}) => {
  const classes = useStyles();
  const { loading, poiStudyData, studiesById } = useMapData();

  const {
    selectedProgramId,
    setSelectedProgramId,
    setShowProgramCard,
    setShowCountryCard,
    setShowCityCard,
  } = usePoiSelectedLocations();

  const { currentListItemHeightFromTopOfList, listItemHeight } = useMemo(() => {
    // get list item height
    const listItemHeight = 40;

    // Take height from bottom of list item and will add the offset later so we
    // use the value to determine height of the study by region list sub items
    const currentListItemHeightFromTopOfList =
      (subDivisionIndex + 1) * listItemHeight + parentListHeight;

    return { currentListItemHeightFromTopOfList, listItemHeight };
  }, [subDivisionIndex, parentListHeight]);

  // handle scroll to list item on open and child study be region list doesn't have an
  // open item
  useEffect(() => {
    const scrollContainer = getCountryListScrollContainer();

    if (!selectedProgramId) {
      scrollContainer?.scrollTo({
        behavior: 'smooth',
        top: currentListItemHeightFromTopOfList - listItemHeight,
      });
    }
  }, [currentListItemHeightFromTopOfList, listItemHeight, selectedProgramId]);

  const localeStudies = useMemo(
    () =>
      poiStudyData?.studies.filter(
        poiStudyMeta =>
          poiStudyMeta[localeType] === selectedLocale &&
          poiStudyMeta.country === listCountry,
      ),
    [listCountry, localeType, poiStudyData?.studies, selectedLocale],
  );

  if (loading || !studiesById) {
    // handle loading here
    return null;
  }

  return (
    <div className={classes.studyRow}>
      <div className={classes.subHeading} id={POI_STUDY_LIST_HEADER_KEY}>
        <Typography variant="p2">{t('dataView.nameOfStudy')}</Typography>
        <Typography variant="p2">{t('dataView.status')}</Typography>
      </div>
      <ul>
        {localeStudies?.map(({ id }, index) => {
          const study = studiesById[id];

          const isActive = selectedProgramId === id;

          const handleClick = () => {
            setSelectedProgramId(isActive ? '' : id);
            setShowProgramCard(true);
            setShowCountryCard(false);
            setShowCityCard(false);
          };

          return (
            <li className={classes.listItem} key={id}>
              <button
                className={clsx(classes.programmeRow, {
                  [classes.selectedProgrammeRow]: isActive,
                })}
                id={`${POI_STUDY_LIST_ITEM_ID_PREFIX}-${index}`}
                onClick={handleClick}
              >
                <div className={classes.group}>
                  <Icon
                    alt={study.status}
                    variant={isActive ? 'CollapseList' : 'ExpandList'}
                    fill={isActive ? '#008b7f' : '#F2F2F2'}
                    stroke={isActive ? 'white' : '#fff'}
                    width={24}
                    height={24}
                    style={{ cursor: 'pointer', marginRight: '12px' }}
                    className={classes.icon}
                  />
                  <div
                    className={clsx(classes.title, {
                      [classes.titleDefault]: !isActive,
                    })}
                  >
                    <Typography variant="p2">{study.studyName}</Typography>
                  </div>
                </div>
                <div className={classes.status}>
                  <Typography variant="p3">
                    <p
                      style={{
                        color:
                          study.status === 'Active' ? '#2D3748' : '#A0AEC0',
                      }}
                    >
                      {study.status}
                    </p>
                  </Typography>
                </div>
              </button>
              {isActive && (
                <ProgrammeDetails
                  teaser={{
                    id,
                    ...study,
                  }}
                  parentListHeight={currentListItemHeightFromTopOfList}
                  studyIndex={index}
                  key={id}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
