import type { Palette, PaletteColor } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { lighten, sliderClasses } from '@mui/material';
import { createUseStyles } from 'react-jss';

export const theme = {
  colors: {
    'blue-100': '#155181',
    'blue-80': '#15518180',
    'blue-55': '#15518155',
    'blue-30': '#15518130',
    'blue-10': '#15518110',
    'green-100': '#008b7f',
    'green-90': '#128478',
    'green-80': '#008b7f80',
    'green-55': '#008b7f55',
    'green-30': '#008b7f30',
    'green-10': '#008b7f10',
    'grey-100': '#e4e5e6',
    'grey-80': '#e4e5e680',
    'grey-55': '#e4e5e655',
    'grey-30': '#e4e5e630',
    'grey-20': '#e2e2e2',
    'grey-10': '#f7f7f7',
    'red-55': '#e0746f',
    'yellow-100': '#F4B432',
    'purple-100': '#9E1242',
    white: '#ffffff',
    default: '#a2a2a2',
    darker: '#6a6a6a',
  },
  fontFamilies: {
    default: "'Be Vietnam Pro', sans-serif",
  },
  fontSizes: {
    h1: 48,
    h2: 30,
    h3: 22,
    h4: 18,
    p1: 16,
    p2: 14,
    p3: 12,
    p4: 8,
  },
  breakPoints: {
    tablet: 580,
    laptop: 850,
    desktop: 1050,
    widescreen: 1450,
  },
};

export type LcpnTheme = typeof theme;

export const useFontStyles = createUseStyles((_: LcpnTheme): unknown => ({
  '@global': {
    '@font-face': [
      {
        fontFamily: 'Be Vietnam Pro',
        src: `url("${process.env.PUBLIC_URL}/fonts/BeVietnamPro/BeVietnamPro-Regular.ttf") format('TrueType')`,
        fontWeight: 400,
        fontDisplay: 'swap',
      },
      {
        fontFamily: 'Be Vietnam Pro Medium',
        src: `url("${process.env.PUBLIC_URL}/fonts/BeVietnamPro/BeVietnamPro-Medium.ttf") format('TrueType')`,
        fontWeight: 500,
        fontDisplay: 'swap',
      },
      {
        fontFamily: 'Be Vietnam Pro Italic',
        src: `url("${process.env.PUBLIC_URL}/fonts/BeVietnamPro/BeVietnamPro-Italic.ttf") format('TrueType')`,
        fontWeight: 500,
        fontDisplay: 'swap',
      },
    ],
  },
}));

export const useResetStyles = createUseStyles((theme: LcpnTheme) => ({
  '@global': {
    body: {
      fontFamily: theme.fontFamilies.default,
      color: '#5a5a5a',
      margin: 0,
    },
    'h1, h2, h3, h4, h5, h6, p, dl ,dd': {
      margin: 0,
      padding: 0,
    },
    'p + p': {
      marginTop: '1em',
    },
    ul: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      color: theme.colors.default,
    },
    li: {
      marginBottom: '.5rem',
    },
    button: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
    },
  },
}));

const sliderThumbBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#008b7f',
      light: lighten('#d9e9fd', 0.2),
      lighter: lighten('#d9e9fd', 0.4),
      lightest: lighten('#d9e9fd', 0.6),
    },
    secondary: {
      main: '#343459',
    },
    error: {
      main: '#F04F4F',
      contrastText: '#fff',
    },
    warning: {
      main: '#F0B04F',
    },
    success: {
      main: '#3AD78C',
      contrastText: '#fff',
    },
    text: {
      primary: '#333740',
      secondary: '#5a5a5a',
    },
    common: {
      blue: '#3300FF',
    },
  },

  shape: {
    borderRadius: 8,
  },

  typography: {
    fontFamily: "'Be Vietnam Pro', sans-serif",
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 26,
      fontWeight: 700,
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      '& > b': {
        fontWeight: 700,
      },
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
    },
  },

  components: {
    MuiSlider: {
      styleOverrides: {
        root: ({ ownerState, theme }) =>
          theme.unstable_sx({
            '& .MuiSlider-thumb': {
              height: 28,
              width: 28,
              backgroundColor: '#fff',
              boxShadow: sliderThumbBoxShadow,
              '&:focus, &:hover, &.Mui-active': {
                boxShadow: sliderThumbBoxShadow,
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                  boxShadow: sliderThumbBoxShadow,
                },
              },
            },
            [`& .${sliderClasses.markLabel}`]: {
              py: 0.5,
              px: 1,
              typography: {
                fontSize: 10,
              },
            },
          }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) =>
          theme.unstable_sx({
            fontWeight: 400,
            boxShadow: 'none',
            py: 1,
            px: 2,
            ':hover': {
              boxShadow: 'none',
            },
            borderRadius: 32,
            lineHeight: '1.2rem',
            ...(ownerState.size === 'small' && {
              py: 1,
              lineHeight: '1rem',
            }),
          }),

        text: ({ ownerState = {}, theme }) => {
          const c =
            ownerState &&
            theme.palette[(ownerState.color || 'primary') as keyof Palette];

          return {
            ':hover': {
              opacity: c ? 1 : 0.8,
            },
          };
        },
        contained: ({ ownerState = {}, theme }) => {
          const c =
            ownerState &&
            theme.palette[(ownerState.color || 'primary') as keyof Palette];

          return {
            ':hover': {
              backgroundColor: theme.palette[
                (ownerState.color || 'primary') as keyof Palette
              ]
                ? lighten((c as PaletteColor).main ?? ownerState.color, 0.2)
                : ownerState.color,
              opacity: c ? 1 : 0.8,
            },
          };
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface CommonColors {
    blue: string;
  }
  interface PaletteColor {
    lighter?: string;
    lightest?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lightest?: string;
  }
}
