import type { FC, ReactNode } from 'react';
import { createContext, useMemo, useState, useEffect } from 'react';

import type {
  HeatMapData,
  HeatMapMetaData,
  PoiCityData,
  PoiCityMetaData,
  StudiesById,
  PoiCountryData,
  PoiStudyData,
  PoiRegionData,
} from './map-data-types';
import { fetchDataFromPublicDir } from './fetch-data-from-public-dir';

interface Props {
  children: ReactNode;
}

export interface MapDataProps {
  countryHeatMapData?: HeatMapData;
  countryHeatMapMetaData?: HeatMapMetaData;
  poiCityData?: PoiCityData;
  poiCityMetaData?: PoiCityMetaData;
  poiCountryData?: PoiCountryData;
  poiStudyData?: PoiStudyData;
  studiesById?: StudiesById;
  poiRegionData?: PoiRegionData;
}

interface MapDataContextProps extends MapDataProps {
  loading: boolean;
  error?: Error;
}

type MapDataContextPropsUndef = MapDataContextProps | undefined;
export const MapDataContext =
  createContext<MapDataContextPropsUndef>(undefined);

/**
 * Simple provider to load the map data json at run time.
 *
 * There are two advantage to this approach. The first is it allows us to swap out the data without an app rebuild.
 * Equally some of the JSON file are pretty large so this lets us load without blocking the first render.
 */
export const MapDataProvider: FC<Props> = ({ children }) => {
  const [mapData, setMapData] = useState<MapDataProps>();
  // defaulting to true as will be requesting map data on first render
  const [mapDataLoading, setMapDataLoading] = useState(true);
  const [mapDataError, setMapDataError] = useState<Error>();

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        // the only consideration to this approach is ensuring that we set a reasonable caching policy
        // when we upload the JSON files to the root of the bucket we end up using.
        const [
          countryHeatMapData,
          countryHeatMapMetaData,
          poiCityData,
          poiCityMetaData,
          poiCountryData,
          poiStudyData,
          studiesById,
          poiRegionData,
        ] = await Promise.all([
          fetchDataFromPublicDir('heatmap', 'country-heat-map-data.json'),
          fetchDataFromPublicDir('heatmap', 'country-heat-map-meta-data.json'),
          fetchDataFromPublicDir('poi', 'poi-city-data.json'),
          fetchDataFromPublicDir('poi', 'poi-city-meta-data.json'),
          fetchDataFromPublicDir('poi', 'poi-country-data.json'),
          fetchDataFromPublicDir('poi', 'poi-study-data.json'),
          fetchDataFromPublicDir('poi', 'studies-by-id.json'),
          fetchDataFromPublicDir('poi', 'poi-region-data.json'),
        ]);

        setMapData({
          countryHeatMapData,
          countryHeatMapMetaData,
          poiCityData,
          poiCityMetaData,
          poiCountryData,
          studiesById,
          poiStudyData,
          poiRegionData,
        });
        setMapDataLoading(false);
      } catch (exception) {
        console.error('Error loading map data', exception);
        if (exception instanceof Error) {
          setMapDataError(exception);
        } else {
          setMapDataError(new Error(String(exception)));
        }
      }
    };

    fetchMapData();
  }, []);

  // Memorises the state object so that if provider is re-rendered it doesn't cause a re-render of all consumers to the context.
  const memorizedMapDataValue = useMemo(
    () => ({
      ...mapData,
      loading: mapDataLoading,
      error: mapDataError,
    }),
    [mapData, mapDataError, mapDataLoading],
  );

  return (
    <MapDataContext.Provider value={memorizedMapDataValue}>
      {children}
    </MapDataContext.Provider>
  );
};
