import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../../styles/theme';
import { Typography } from '../../Typography';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  root: {
    backgroundColor: 'rgba(21, 81, 129, 0.1)',
    color: '#333740',
  },
  title: {
    textTransform: 'uppercase',
    padding: [16, 16],
    color: 'white',
    backgroundColor: 'rgba(21, 81, 129, 0.75)',
    '& p': {
      marginBottom: 0,
    },
  },
  body: {
    padding: [16, 16],
  },
}));

export function Disclaimer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Typography variant={'p3'} fontWeight={900} className={classes.title}>
        {t('comparisonTable.disclaimerTitle')}
      </Typography>
      <Typography variant={'p2'} className={classes.body}>
        {t('comparisonTable.disclaimerBody')}
      </Typography>
    </div>
  );
}
