import { clsx } from 'clsx';
import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Typography } from '../Typography';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  button: (bgColor?: string) => ({
    outline: 'none',
    border: 'none',
    backgroundColor: `rgba(${bgColor},0.5)`,
    borderRadius: 20,
    minHeight: 36,
    height: '100%',
    // minWidth: 85,
    padding: '2px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: bgColor
        ? `rgba(${bgColor}, 1)`
        : theme.colors['green-55'],
      color: theme.colors.white,
    },
    '& > div > p': {
      whiteSpace: 'nowrap',
    },
  }),

  active: (bgColor?: string) => ({
    backgroundColor: bgColor
      ? `rgba(${bgColor}, 1)`
      : theme.colors['green-100'],
    color: theme.colors.white,
    padding: '0px 16px',
  }),

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`@media screen and (max-width: ${theme.breakPoints.widescreen}px)`]: {
    button: {
      '&&': {
        // minWidth: 76,
        minHeight: 30,
        padding: '2px 8px',
      },
    },
  },
}));

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  children?: JSX.Element;
  selected?: boolean;
  bgColor?: string;
}

export function Button(props: ButtonProps) {
  const { children, label, selected, bgColor, ...htmlButtonProps } = props;
  const classes = useStyles(bgColor);

  return (
    <button
      className={clsx(classes.button, { [classes.active]: selected })}
      {...htmlButtonProps}
    >
      <div className={classes.buttonGroup}>
        {children}
        <Typography variant="p3">{label}</Typography>
      </div>
    </button>
  );
}
