const VIRTUAL_TOUR_KEY = 'virtual-tour-complete';
const EXTENDED_VIRTUAL_TOUR_KEY = 'extended-virtual-tour-status';

export const getVirtualTourCompleteStatusFromLocalStorage = ():
  | 'complete'
  | 'skipped'
  | null =>
  localStorage.getItem(VIRTUAL_TOUR_KEY) as 'complete' | 'skipped' | null;

export const setVirtualTourCompleteStatusFromLocalStorage = (
  status: 'complete' | 'skipped',
) => localStorage.setItem(VIRTUAL_TOUR_KEY, status);

export const getExtendedVirtualTourStatusFromLocalStorage = (): string[] => {
  const localStorageValue = localStorage.getItem(EXTENDED_VIRTUAL_TOUR_KEY);
  return localStorageValue ? JSON.parse(localStorageValue) : [];
};

export const setExtendedVirtualTourStatusFromLocalStorage = (
  status: string[],
) => localStorage.setItem(EXTENDED_VIRTUAL_TOUR_KEY, JSON.stringify(status));
