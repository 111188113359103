import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

import type { StepOptionButton } from './tour-types';
import {
  getExtendedVirtualTourStatusFromLocalStorage,
  setVirtualTourCompleteStatusFromLocalStorage,
  setExtendedVirtualTourStatusFromLocalStorage,
  getVirtualTourCompleteStatusFromLocalStorage,
} from './virtual-tour-local-storage';

/**
 * postPendProgressIndicator
 *
 * Uses the dom to retrieve the current active tour step title and post fixes the current progress
 * to after it
 */
const postPendProgressIndicator = () => {
  const activeTour = Shepherd.activeTour;
  const currentStep = activeTour?.getCurrentStep();
  const currentStepElement = currentStep?.getElement();
  // if tour step elements are present post pend the progress of the tour to after the title
  if (activeTour && currentStep && currentStepElement) {
    const footer = currentStepElement?.querySelector('.shepherd-title');
    const currentPageProgress = document.createElement('span');
    const remainingPagesProgress = document.createElement('span');
    currentPageProgress.className = 'shepherd-progress';
    remainingPagesProgress.className = 'shepherd-progress-remaining';
    currentPageProgress.textContent = `${
      (activeTour.steps.indexOf(currentStep) ?? 0) + 1
    } `;
    remainingPagesProgress.textContent = `\u00A0/ ${activeTour.steps.length}`;
    footer?.after(currentPageProgress);
    currentPageProgress.after(remainingPagesProgress);
  }
};

/**
 * Instantiated virtualTour using Shepherd.js https://github.com/shipshapecode/shepherd
 */
export const virtualTour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
    when: {
      show: () => {
        postPendProgressIndicator();
      },
    },
    floatingUIOptions: {
      middleware: [offset(12)],
    },
  },
});

// Handle on cancel events of the virtual tour
virtualTour.on('cancel', () => {
  if (!getVirtualTourCompleteStatusFromLocalStorage()) {
    setVirtualTourCompleteStatusFromLocalStorage('skipped');
  }
});

// Handle on complete events of the virtual tour
virtualTour.on('complete', () => {
  setVirtualTourCompleteStatusFromLocalStorage('complete');
});

/**
 * Instantiated virtualTour using Shepherd.js https://github.com/shipshapecode/shepherd
 */
export const extendedVirtualTour = new Shepherd.Tour({
  useModalOverlay: false,
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shepherd-one-button-footer',
    floatingUIOptions: {
      middleware: [offset(12)],
    },
  },
});

export const completeStepButton = (label: string) =>
  ({
    classes: 'shepherd-button-primary',
    text: label,
    action() {
      this.complete();
    },
  } as StepOptionButton);

export const cancelStepButton = (label: string) =>
  ({
    classes: 'shepherd-button-primary',
    text: label,
    action() {
      this.cancel();
    },
  } as StepOptionButton);

export const transitionToExtendTourButton = (label: string) =>
  ({
    classes: 'shepherd-button-primary',
    text: label,
    action() {
      this.complete();
      extendedVirtualTour.start();
    },
  } as StepOptionButton);

export const nextStepButton = (label: string) =>
  ({
    classes: 'shepherd-button-primary',
    text: label,
    action() {
      this.next();
    },
  } as StepOptionButton);

export const nextStepAndUpdateExtendedTourButton = (label: string) =>
  ({
    classes: 'shepherd-button-primary',
    text: label,
    action() {
      const currentStepId = this.getCurrentStep()?.id;
      const currentViewedUpdates =
        getExtendedVirtualTourStatusFromLocalStorage();

      if (currentStepId) {
        setExtendedVirtualTourStatusFromLocalStorage([
          ...(currentViewedUpdates ?? []),
          currentStepId,
        ]);
      }

      this.next();
    },
  } as StepOptionButton);
