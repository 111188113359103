import { createUseStyles } from 'react-jss';
import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';

import type { LcpnTheme } from '../../styles/theme';
import type { PointOfInterestRecord } from '../../map-data/map-data-types';
import type { Related } from '../DefinitionTable';
import { DefinitionTable } from '../DefinitionTable';
import { ShareLink } from '../DefinitionTable/ShareLink';
import { Icon } from '../Icon';
import { useFilterMapData } from '../../map-data/use-filter-poi-map-data';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { useMapData } from '../../map-data/use-map-data';
import { getRgb } from '../PinMap/get-rgb';
import { PoiPrintTable } from '../ComparisonTool/ComparisonTable/PoiPrintTable';
import { ClipboardButton } from '../ClipboardButton';
import {
  POI_STUDY_LIST_HEADER_KEY,
  POI_STUDY_LIST_ITEM_ID_PREFIX,
} from '../PointOfInterestCountryList/constants';
import { getCountryListScrollContainer } from '../PointOfInterestCountryList/get-list-scroll-container';

import { ProgrammeSummary } from './ProgrammeSummary';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  printContainer: {
    '& *': { boxSizing: 'border-box !important' },
    width: '100%',
    height: '100%',
    webkitPrintColorAdjust: 'exact !important',
    printColorAdjust: 'exact',
    padding: 0,
    display: 'block',
    '@media screen': {
      '&&': {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        width: 1,
        height: 1,
        top: 0,
        left: 0,
        overflow: 'hidden',
      },
    },
  },
}));

type Props = {
  teaser: PointOfInterestRecord;
  studyIndex: number;
  parentListHeight: number;
};

export const ProgrammeDetails: FC<Props> = ({
  teaser,
  studyIndex,
  parentListHeight,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // handle scroll to list item on open
  useEffect(() => {
    const scrollContainer = getCountryListScrollContainer();

    // get list item height
    const listItemHeight =
      document.querySelector<HTMLLIElement>(
        `#${POI_STUDY_LIST_ITEM_ID_PREFIX}-${studyIndex}`,
      )?.offsetHeight ?? 0;

    const listHeaderELement =
      document.querySelector<HTMLLIElement>(`#${POI_STUDY_LIST_HEADER_KEY}`)
        ?.offsetHeight ?? 0;

    const heightFromTopOfProgrammeList = studyIndex * listItemHeight;

    // determine height by height of item in study list and adding height within
    // parent list (sub division list) and add header element height and lastly add the border on top of the study list item
    const currentListItemHeightFromTopOfList =
      parentListHeight + listHeaderELement + heightFromTopOfProgrammeList + 2;

    scrollContainer?.scrollTo({
      behavior: 'smooth',
      top: currentListItemHeightFromTopOfList,
    });
  }, [parentListHeight, studyIndex]);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: classes.printContainer,
    pageStyle: '@page { size: auto, margin: 10mm;  }',
  });

  const { studiesById } = useMapData();
  const { filteredStudiesById } = useFilterMapData();

  const {
    selectedProgramId,
    setSelectedProgramId,
    setSelectedCountrySubDivisionName,
    setSelectedCountryName,
  } = usePoiSelectedLocations();

  // REFACTOR: Move this to somewhere more sane.
  const related = useMemo(() => {
    if (!studiesById || !selectedProgramId) return;
    if (selectedProgramId !== teaser.id) return;

    const c = studiesById[selectedProgramId];
    const relatedEntries =
      c.relatedEntries?.split(',').map(el => el.trim()) ?? [];

    const result: Related[] = [];
    if (relatedEntries)
      for (const id of relatedEntries) {
        const s = studiesById[id];
        if (s) {
          result.push({
            id: id,
            acronym: s.studyAcronym ?? '',
            city: s.city,
            country: s.country,
            action: () => {
              setSelectedCountryName(s.country);
              setSelectedCountrySubDivisionName({
                city: s.city,
                region: s.region,
              });
              setSelectedProgramId(id);
            },
          });
        }
      }

    return result;
  }, [
    selectedProgramId,
    setSelectedCountryName,
    setSelectedCountrySubDivisionName,
    setSelectedProgramId,
    studiesById,
    teaser.id,
  ]);

  if (!filteredStudiesById || !studiesById) {
    // handle loading here
    return null;
  }

  const isActive = selectedProgramId === teaser.id;
  const study = filteredStudiesById[teaser.id]; // pass this in

  // as filtering can remove studies then we need to handle this.
  if (!study) return null;

  const shareUrl = process.env.REACT_APP_CAMPAIGN_URL + '?poiId=' + teaser.id;

  const tweetUrl = new URL('https://twitter.com/intent/tweet?');
  tweetUrl.searchParams.append('text', t('programmeRow.tweetText'));
  tweetUrl.searchParams.append('url', shareUrl);
  tweetUrl.searchParams.append('hashtags', 'LDCT,LungCancer,LCSM');
  tweetUrl.searchParams.append('via', 'LungPolicy');

  const participant_outcomes_is_empty =
    study.yearsReported === ('N/A' || '-') &&
    study.screeningUptakeRate === ('N/A' || '-') &&
    study.casesPositiveFindings === ('N/A' || '-') &&
    study.stage1and2 === ('N/A' || '-');

  return (
    <div>
      <ProgrammeSummary
        rows={[
          {
            key: t('programmeRow.programmeDetail.acronym'),
            value: study.studyAcronym,
            fallback: '—',
          },
          {
            key: t('programmeRow.programmeDetail.region'),
            value: study.region,
          },
          {
            key: t('programmeRow.programmeDetail.design'),
            value: study.design,
            fallback: '—',
          },
          {
            key: t('programmeRow.programmeDetail.participantsTargeted'),
            value: study.targetParticipants,
          },
          {
            key: t('programmeRow.programmeDetail.participantsScreened'),
            value: study.screenedParticipants,
            fallback: '—',
          },
        ]}
      />
      <DefinitionTable
        studyDesign={study.design}
        backgroundColor={`rgb(${getRgb(study.design)})`}
        icon={'further_detail'}
        title={t('programmeRow.definitionTables.furtherDetail.title')}
        rows={[
          {
            key: t('programmeRow.definitionTables.furtherDetail.duration'),
            value: `${study.start} — ${study.finish}`,
          },
          {
            key: t('programmeRow.definitionTables.furtherDetail.centres'),
            value: study.studyCentersInCountry,
          },
          {
            key: t(
              'programmeRow.definitionTables.furtherDetail.screeningInterval',
            ),
            value: study.screeningInterval,
          },
          {
            key: t('programmeRow.definitionTables.furtherDetail.radiationDose'),
            value: study.radiationDoseReported,
          },
          {
            key: t('programmeRow.definitionTables.furtherDetail.researchFocus'),
            value: study.researchFocus,
          },
        ]}
      />
      <DefinitionTable
        studyDesign={study.design}
        backgroundColor={`rgb(${getRgb(study.design)})`}
        icon={'eligibility_checker'}
        title={t('programmeRow.definitionTables.eligibilityChecker.title')}
        rows={[
          {
            key: t('programmeRow.definitionTables.eligibilityChecker.ageRange'),
            value: `${study.minAge} - ${study.maxAge}`,
          },
          {
            key: t(
              'programmeRow.definitionTables.eligibilityChecker.smokingHistoryPYs',
            ),
            value: study.packYears,
          },
          {
            key: t(
              'programmeRow.definitionTables.eligibilityChecker.smokingHistoryYSQ',
            ),
            value: Number.isNaN(Number.parseInt(study.stoppedSmoking || ''))
              ? study.stoppedSmoking
              : t(
                  'programmeRow.definitionTables.eligibilityChecker.yearsSince',
                  {
                    replace: {
                      count: Number.parseInt(study.stoppedSmoking || ''),
                    },
                  },
                ),
          },
          {
            key: t(
              'programmeRow.definitionTables.eligibilityChecker.neverSmokers',
            ),
            value: study.smokersNeverEligible,
          },
          {
            key: t(
              'programmeRow.definitionTables.eligibilityChecker.otherRiskFactors',
            ),
            value: study.otherCriteriaSummary,
          },
        ]}
        footnotes={[
          {
            key: t(
              'programmeRow.definitionTables.eligibilityChecker.footnotesTitle',
            ),
            isOpen: true,
            value: study.minAge
              ?.concat(
                study.maxAge || '',
                study.packYears || '',
                study.stoppedSmoking || '',
              )
              .includes('*')
              ? study.smokingHistory
              : '',
          },
        ]}
      />
      <>
        {participant_outcomes_is_empty === false && (
          <DefinitionTable
            studyDesign={study.design}
            backgroundColor={`rgb(${getRgb(study.design)})`}
            icon={'participant_outcomes'}
            title={t('programmeRow.definitionTables.participantOutcomes.title')}
            rows={[
              {
                key: t(
                  'programmeRow.definitionTables.participantOutcomes.year',
                ),
                value: study.yearsReported,
              },
              {
                key: t(
                  'programmeRow.definitionTables.participantOutcomes.uptake',
                ),
                value: study.screeningUptakeRate,
              },
              {
                key: t(
                  'programmeRow.definitionTables.participantOutcomes.confirmedCases',
                ),
                value: study.casesPositiveFindings,
              },
              {
                key: t(
                  'programmeRow.definitionTables.participantOutcomes.stageCases',
                ),
                value: study.stage1and2,
              },
            ]}
            footnotes={[
              {
                key: t(
                  'programmeRow.definitionTables.participantOutcomes.footnotesTitle',
                ),
                isOpen: true,
                value: t(
                  'programmeRow.definitionTables.participantOutcomes.footnotesBody',
                ),
              },
            ]}
          />
        )}
      </>

      <DefinitionTable
        backgroundColor={`rgb(70, 76, 89)`}
        studyDesign=""
        icon={'participant_outcomes'}
        title={t(
          'programmeRow.definitionTables.participantOutcomesAdditional.title',
        )}
        rows={[
          {
            key: t(
              'programmeRow.definitionTables.participantOutcomesAdditional.updated',
            ),
            value: study.updated ? study.updated.toString() : '',
          },
          {
            key: t(
              'programmeRow.definitionTables.participantOutcomesAdditional.publicationApproved',
            ),
            value: study.publicationApproved === '3' ? 'Yes' : 'No',
          },
        ]}
        relatedTitle={t(
          'programmeRow.definitionTables.participantOutcomesAdditional.related',
        )}
        related={[...(related as Related[])]}
        footnotes={[
          {
            key: t(
              'programmeRow.definitionTables.participantOutcomesAdditional.description',
            ),
            value: study.description,
          },
          {
            key: t(
              'programmeRow.definitionTables.participantOutcomesAdditional.references',
            ),
            value: study.references,
          },
        ]}
      />

      <ShareLink>
        <ClipboardButton
          sx={{ textTransform: 'none' }}
          color={'inherit'}
          size={'small'}
          clipboardText={shareUrl}
          variant={'text'}
          startIcon={<Icon variant="ShareLink" width={24} height={24} />}
        >
          {t('programmeRow.share.shareLink')}
        </ClipboardButton>
        <Button
          sx={{ textTransform: 'none' }}
          color={'inherit'}
          size={'small'}
          onClick={() => {
            window.open(tweetUrl, '_blank')?.focus();
          }}
          variant={'text'}
          startIcon={<Icon variant="Twitter" width={22} height={22} />}
        >
          {t('programmeRow.share.tweet')}
        </Button>
        {isActive && (
          <>
            <Button
              sx={{ textTransform: 'none' }}
              color={'inherit'}
              size={'small'}
              onClick={handlePrint}
              variant={'text'}
              startIcon={<Icon variant="Print" width={22} height={22} />}
            >
              {t('programmeRow.share.print')}
            </Button>
            <div className={classes.printContainer} ref={componentRef}>
              <PoiPrintTable study={{ ...study, id: teaser.id }} />
            </div>
          </>
        )}
      </ShareLink>
    </div>
  );
};
