import type { FC, ReactNode, Dispatch, SetStateAction } from 'react';
import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useMap } from 'react-map-gl';

import type { SubDivision } from '../PointOfInterestCountryList/constants';

type SelectedCountrySubdivision = {
  [SubDivision.city]?: string;
  [SubDivision.region]?: string;
};
interface PoiSelectedLocationsContextProps {
  selectedProgramId?: string;
  setSelectedProgramId: Dispatch<SetStateAction<string | undefined>>;
  selectedCountryName?: string;
  setSelectedCountryName: Dispatch<SetStateAction<string | undefined>>;
  selectedCountrySubDivisionName?: SelectedCountrySubdivision;
  setSelectedCountrySubDivisionName: Dispatch<
    SetStateAction<
      { [SubDivision.city]?: string; [SubDivision.region]?: string } | undefined
    >
  >;
  showCountryCard: boolean;
  showCityCard: boolean;
  showProgramCard: boolean;
  setShowCountryCard: Dispatch<SetStateAction<boolean>>;
  setShowCityCard: Dispatch<SetStateAction<boolean>>;
  setShowProgramCard: Dispatch<SetStateAction<boolean>>;
  resetExperience: VoidFunction;
}
type PoiSelectedLocationsContextPropsUnDef =
  | PoiSelectedLocationsContextProps
  | undefined;
const PoiSelectedLocationsContext =
  createContext<PoiSelectedLocationsContextPropsUnDef>(undefined);

interface Props {
  children: ReactNode;
}

export const usePoiSelectedLocations = () => {
  const filteredMapData = useContext(PoiSelectedLocationsContext);

  if (!filteredMapData) {
    throw new Error(
      'useMapData hook it being used outside of the MapDataProvider',
    );
  }

  return filteredMapData;
};

export const PoiSelectedLocationsProvider: FC<Props> = ({ children }) => {
  const [selectedProgramId, setSelectedProgramId] = useState<string>();
  const [selectedCountryName, setSelectedCountryName] = useState<string>();
  const [selectedCountrySubDivisionName, setSelectedCountrySubDivisionName] =
    useState<SelectedCountrySubdivision>();
  const [showCountryCard, setShowCountryCard] = useState(false);
  const [showCityCard, setShowCityCard] = useState(false);
  const [showProgramCard, setShowProgramCard] = useState(false);

  const { poiMap } = useMap();

  const resetExperience = useCallback(() => {
    setSelectedProgramId('');
    setSelectedCountryName('');
    setSelectedCountrySubDivisionName({});
    setShowCountryCard(false);
    setShowCityCard(false);
    setShowProgramCard(false);
    poiMap?.flyTo({
      center: [0, 20],
      essential: true,
      zoom: 0.7,
      screenSpeed: 2,
    });
  }, [poiMap]);

  const memorizedContextValue = useMemo(
    () => ({
      selectedProgramId,
      setSelectedProgramId,
      selectedCountryName,
      setSelectedCountryName,
      selectedCountrySubDivisionName,
      setSelectedCountrySubDivisionName,
      showCountryCard,
      setShowCountryCard,
      showCityCard,
      setShowCityCard,
      showProgramCard,
      setShowProgramCard,
      resetExperience,
    }),
    [
      selectedProgramId,
      selectedCountryName,
      selectedCountrySubDivisionName,
      showCountryCard,
      showCityCard,
      showProgramCard,
      resetExperience,
    ],
  );

  return (
    <PoiSelectedLocationsContext.Provider value={memorizedContextValue}>
      {children}
    </PoiSelectedLocationsContext.Provider>
  );
};
