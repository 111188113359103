import type { FC } from 'react';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePoiFilterOptions } from '../../../map-data/use-filter-options';
import { useMapData } from '../../../map-data/use-map-data';
import type { LcpnTheme } from '../../../styles/theme';
import { Icon } from '../../Icon';
import { PoiSearch } from '../../PoiSearch';
import { PoiSearchResults } from '../../PoiSearch/PoiSearchResults';
import type { PointOfInterestRecord } from '../../../map-types';
import { PoiResultItem } from '../../PoiSearch/PoiResultItem';

import { PickerSelect } from './PickerSelect';
import {
  getPoiComparisonLocalStorage,
  setPoiComparisonLocalStorage,
} from './use-comparison-local-storage';

interface PickerProps {
  selectionNumber: 1 | 2;
  onStudySelect: (studyid: string, selectionNumber: number) => void;
}

const usePickerStyles = createUseStyles((theme: LcpnTheme) => ({
  pickerContainer: {
    height: '33.0625rem',
    background: 'white',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '29.125rem',
  },
  picker: {
    background: theme.colors['blue-100'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    height: '100%',
    width: '100%',
  },
  pickerActionText: {
    paddingTop: '1rem',
    color: 'white',
    fontSize: 20,
  },
  selectorHeader: {
    background: theme.colors['blue-100'],
    height: '2.875rem',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '0 1.25rem',
    fontSize: 15,
    justifyContent: 'space-between',
  },
  selectorContainer: {
    margin: '1rem',
    position: 'relative',
  },
  searchWrapper: {
    minHeight: '2.5rem',
  },
  dropDownsWrapper: {
    background:
      'linear-gradient(0deg, #E1E9E8, #E1E9E8), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #008B7F',
    height: '24.625rem',
    marginTop: '1rem',
    padding: '2rem',
    boxSizing: 'border-box',
    '& > div:not(:first-child)': {
      paddingTop: '2rem',
    },
  },
}));

const PoiSearchResultsList: FC<{
  onItemClick: (record: PointOfInterestRecord) => void;
}> = ({ onItemClick }) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();

  return (
    <PoiSearchResults
      resultItemComponent={props => (
        <PoiResultItem
          {...props}
          onResultClick={props => {
            onItemClick(props);
            setIndexUiState({
              ...indexUiState,
              query: undefined,
            });
          }}
        />
      )}
    />
  );
};

export const PoiPicker: FC<PickerProps> = ({
  selectionNumber,
  onStudySelect,
}) => {
  const classes = usePickerStyles();
  const { filterConfig: poiFilterDataOptions } = usePoiFilterOptions();
  const { poiStudyData, studiesById, poiCityMetaData } = useMapData();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedStudy, setSelectedStudy] = useState('');
  const [initialised, setInitialised] = useState(false);

  const [isPickingStudy, setIsPickingStudy] = useState(
    !!selectedStudy || !!selectedCity || !!selectedCountry,
  );
  const { t } = useTranslation();

  useEffect(() => {
    const poiComparisonInLocalStorage = getPoiComparisonLocalStorage();

    setSelectedCountry(
      poiComparisonInLocalStorage[selectionNumber - 1]?.country ?? '',
    );
    setSelectedCity(
      poiComparisonInLocalStorage[selectionNumber - 1]?.city ?? '',
    );
    setSelectedStudy(
      poiComparisonInLocalStorage[selectionNumber - 1]?.study ?? '',
    );
  }, [selectionNumber]);

  const filteredCities = useMemo(() => {
    if (!selectedCountry || !poiStudyData) return [];

    return [
      ...new Set(
        poiStudyData.studies
          .filter(study => study.country === selectedCountry)
          .map(study => study.city),
      ),
    ].map(city => ({ label: city, value: city }));
  }, [poiStudyData, selectedCountry]);

  const filteredStudies = useMemo(() => {
    if (!selectedCity || !studiesById || !poiCityMetaData) return [];

    return poiCityMetaData[selectedCity]
      .filter(
        // filter out studies when city is 'national'
        cityStudyId => studiesById[cityStudyId].country === selectedCountry,
      )
      .map(cityStudyId => ({
        label: studiesById[cityStudyId]?.studyName,
        value: cityStudyId,
      }));
  }, [poiCityMetaData, selectedCity, selectedCountry, studiesById]);

  useEffect(() => {
    const poiComparisonLocalStorage = getPoiComparisonLocalStorage();
    const { country, study, city } =
      poiComparisonLocalStorage[selectionNumber - 1];
    if (country) setSelectedCountry(country);
    if (city) setSelectedCity(city);
    if (study) setSelectedStudy(study);
    setIsPickingStudy(!!study);
    setInitialised(true);
  }, [selectionNumber]);

  useEffect(() => {
    if (!initialised) return;

    const currentLocalStorage = getPoiComparisonLocalStorage();
    currentLocalStorage[selectionNumber - 1] = {
      country: selectedCountry,
      city: selectedCity,
      study: selectedStudy,
    };
    setPoiComparisonLocalStorage(currentLocalStorage);
  }, [
    initialised,
    selectedCity,
    selectedCountry,
    selectedStudy,
    selectionNumber,
  ]);

  useEffect(() => {
    if (filteredCities.length === 1) {
      setSelectedCity(filteredCities[0]?.value);
    }
  }, [filteredCities]);

  useEffect(() => {
    if (filteredStudies.length === 1) {
      setSelectedStudy(filteredStudies[0].value);
    }
  }, [filteredStudies]);

  useEffect(() => {
    onStudySelect(selectedStudy, selectionNumber);
  }, [onStudySelect, selectedStudy, selectionNumber]);

  const resetPicker = useCallback(() => {
    setSelectedCity('');
    setSelectedCountry('');
    setSelectedStudy('');
  }, []);

  if (!isPickingStudy) {
    return (
      <div className={classes.pickerContainer}>
        <button
          className={classes.picker}
          onClick={() => setIsPickingStudy(true)}
        >
          <Icon variant="Add" width={64} height={64} />
          <p className={classes.pickerActionText}>
            {t('comparisonPicker.poi.picker.addEntry')}
          </p>
        </button>
      </div>
    );
  }

  if (!poiFilterDataOptions || !poiStudyData || !studiesById) {
    return (
      <div className={classes.pickerContainer}>
        <div className={classes.selectorHeader}>
          <p>{t('comparisonPicker.poi.picker.title', { selectionNumber })}</p>
        </div>
        <div className={classes.loadingContainer}>
          <CircularProgress size={60} disableShrink />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.pickerContainer}>
      <div className={classes.selectorHeader}>
        <p>{t('comparisonPicker.poi.picker.title', { selectionNumber })}</p>
        {selectedStudy && (
          <Button
            variant="text"
            sx={{
              color: 'white',
              fontSize: 12,
              fontFamily: 'Be Vietnam Pro',
              textTransform: 'none',
              textDecoration: 'underline',
            }}
            size="small"
            onClickCapture={resetPicker}
          >
            {t('comparisonPicker.clearSelection')}
          </Button>
        )}
      </div>
      <div className={classes.selectorContainer}>
        <div className={classes.searchWrapper}>
          <PoiSearch
            placeholder={t('comparisonPicker.poi.picker.searchPlaceHolder')}
          >
            <PoiSearchResultsList
              onItemClick={record => {
                setSelectedCountry(record.country);
                setSelectedCity(record.city);
                setSelectedStudy(record.id);
              }}
            />
          </PoiSearch>
        </div>
        <div className={classes.dropDownsWrapper}>
          <PickerSelect
            onUpdate={country => {
              setSelectedCountry(country);
              setSelectedCity('');
              setSelectedStudy('');
            }}
            onClear={resetPicker}
            label={t('comparisonPicker.poi.picker.countryLabel')}
            placeholder={t('comparisonPicker.poi.picker.countryPlaceholder')}
            selectedValue={selectedCountry}
            options={poiFilterDataOptions?.countries.map(countryName => ({
              label: countryName,
              value: countryName,
            }))}
          />
          <PickerSelect
            onUpdate={city => {
              setSelectedCity(city);
              setSelectedStudy('');
            }}
            onClear={() => {
              setSelectedCity('');
              setSelectedStudy('');
            }}
            label={t('comparisonPicker.poi.picker.cityLabel')}
            placeholder={t('comparisonPicker.poi.picker.cityPlaceholder')}
            selectedValue={selectedCity}
            options={filteredCities}
            disabled={!selectedCountry}
          />
          <PickerSelect
            onUpdate={studyId => setSelectedStudy(studyId)}
            onClear={() => {
              setSelectedStudy('');
            }}
            label={t('comparisonPicker.poi.picker.studyLabel')}
            placeholder={t('comparisonPicker.poi.picker.studyPlaceholder')}
            selectedValue={selectedStudy}
            options={filteredStudies}
            disabled={!selectedCountry || !selectedCity}
          />
        </div>
      </div>
    </div>
  );
};
