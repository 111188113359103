import { createContext, useState, useContext } from 'react';

export type HeatMapComparison = {
  fromCountryCode?: string;
  toCountryCode?: string;
  display?: boolean;
};

export type PoiComparison = {
  fromStudyId?: string;
  toStudyId?: string;
  display?: boolean;
};

type ComparisonContextType = {
  heatMapComparison: HeatMapComparison;
  poiComparison: PoiComparison;
  setHeatMapComparison: (heatMapComparison: HeatMapComparison) => void;
  setPoiComparison: (poiComparison: PoiComparison) => void;
};

export const ComparisonContext = createContext<ComparisonContextType>({
  heatMapComparison: {},
  poiComparison: {},
  setHeatMapComparison: () => {},
  setPoiComparison: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const useComparisonProviderContext = () => {
  const {
    heatMapComparison,
    poiComparison,
    setHeatMapComparison,
    setPoiComparison,
  } = useContext(ComparisonContext);

  return {
    heatMapComparison,
    poiComparison,
    setHeatMapComparison,
    setPoiComparison,
  };
};

export function ComparisonProvider(props: Props) {
  const [heatMapComparison, setHeatMapComparison] = useState<HeatMapComparison>(
    {},
  );
  const [poiComparison, setPoiComparison] = useState<PoiComparison>({});

  return (
    <ComparisonContext.Provider
      value={{
        heatMapComparison,
        poiComparison,
        setHeatMapComparison,
        setPoiComparison,
      }}
    >
      {props.children}
    </ComparisonContext.Provider>
  );
}
