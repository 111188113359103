import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

/*
import { convertLanguageJsonToObject } from '../translations';

import en from './en/translation.json';
import de from './de/translation.json';
import fr from './fr/translation.json';

const translationsJson = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);
convertLanguageJsonToObject(de);
convertLanguageJsonToObject(fr);

 */

// eslint-disable-next-line import/no-named-as-default-member
const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector);

// see - https://github.com/i18next/react-i18next/issues/1587
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// learn more: https://github.com/i18next/i18next-browser-languageDetector
// detect user language
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.init({
  //resources: translationsJson,
  fallbackLng: 'en',
  debug:
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  returnNull: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    skipOnVariables: false,
  },
  backend: {
    loadPath:
      process.env.REACT_APP_TRANSLATIONS_DATA_LOCATION + '/{{lng}}.json',
  },
});

export { i18n };
