/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import key from 'weak-key';
import type { CSSProperties } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { getRgb } from '../PinMap/get-rgb';

import { Footer } from './Footer';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  '@keyframes rotateUp': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(180deg)',
    },
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.white,
    '& > div:nth-child(even)': {
      backgroundColor: theme.colors['grey-55'],
    },
  },

  head: (props: CSSProperties) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: props.backgroundColor,
    color: theme.colors.white,
    padding: 8,
    cursor: 'pointer',
    width: '100%',
  }),

  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginLeft: 'clamp(32px, 5.5%, 32px)',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: 'solid 1px',
    borderWidth: 'thin',
    borderColor: theme.colors['grey-20'],
    padding: '12px 0px 12px 0px',
  },

  key: {
    width: '100%',
    marginLeft: 'clamp(75px, 5.5%, 75px)',
  },

  value: {
    width: '100%',
  },

  footer: {
    marginLeft: 'clamp(75px, 5.5%, 75px)',
  },

  rotateIconUp: {
    animation: '$rotateUp .4s ease-out forwards',
  },

  chevron: {
    marginRight: 20,
  },

  link: {
    paddingBottom: 5,
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    group: {
      '&&': {
        gap: 8,
        alignItems: 'center',
        marginLeft: 0,
        '& > img': {
          marginLeft: 8,
          marginRight: 8,
        },
      },
    },
    key: {
      '&&': {
        marginLeft: 16,
      },
    },
    footer: {
      '&&': {
        marginLeft: 16,
      },
    },
    chevron: {
      display: 'block',
      marginRight: 4,
    },
  },
}));

type Row = {
  key: string;
  value?: string;
  skip?: boolean;
  fallback?: string;
  isOpen?: boolean;
};

// REFACTOR: this should use one of the existing types
export type Related = {
  city: string;
  country: string;
  acronym: string;
  id: string;
  action: () => void;
};

type Props = {
  title: string;
  icon: string;
  rows: Row[];
  backgroundColor: string;
  footnotes?: Row[];
  relatedTitle?: string;
  related?: Related[];
  studyDesign: string;
};

function getIcon(value: string, color: string) {
  switch (value) {
    case 'Further details on entry': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23 23"
          height="23"
          width="23"
        >
          <g transform="translate(-47 -1379)">
            <circle
              cx={11.5}
              cy={11.5}
              r={11.5}
              transform="translate(47 1379)"
              fill="#fff"
            />
            <path
              fill="none"
              stroke={`rgb(${getRgb(color)})`}
              strokeWidth={1.3}
              d="M51.568 1395.32h13.168m-10.189-2.927h7.214m-7.214-2.196h7.214m-7.214-2.195h7.214m-10.193-3.074h13.168m-.578-.586v11.563m-12.009-11.563v11.563"
            />
          </g>
        </svg>
      );
    }
    case 'Eligibility criteria': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23 23"
          height="23"
          width="23"
        >
          <g transform="translate(-47 -1565)">
            <circle
              cx="11.5"
              cy="11.5"
              r="11.5"
              transform="translate(47 1565)"
              fill="#fff"
            />
            <path
              fill="none"
              stroke={`rgb(${getRgb(color)})`}
              strokeWidth="1.3"
              d="M51.568 1581.32h13.168M51.568 1570.928h13.168M64.738 1570.342v11.563M52.149 1570.342v11.563M60.953 1574.295l-3.639 3.658M56.047 1575.898l2.087 2.055"
            />
          </g>
        </svg>
      );
    }
    case 'Participant outcomes from screening': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23 23"
          height="23"
          width="23"
        >
          <g transform="translate(-47 -1814)">
            <circle
              cx="11.5"
              cy="11.5"
              r="11.5"
              transform="translate(47 1814)"
              fill="#fff"
            />
            <path
              fill="none"
              stroke={`rgb(${getRgb(color)})`}
              strokeWidth="1.3"
              d="M62.851 1819.342v11.563M54.149 1819.5v11.406M58.5 1822.5v8.406"
            />
          </g>
        </svg>
      );
    }
  }
}

export function DefinitionTable(props: Props) {
  const {
    title,
    rows,
    backgroundColor,
    footnotes,
    studyDesign,
    related,
    relatedTitle,
  } = props;
  const classes = useStyles({ backgroundColor });
  const [active, setActive] = useState(true);

  return (
    <div className={classes.definitionTable}>
      <button className={classes.head} onClick={() => setActive(!active)}>
        <div className={classes.group}>
          {getIcon(title, studyDesign)}
          {title}
        </div>
        <Icon
          variant={active ? 'CollapseList' : 'ExpandList'}
          stroke="#6a6a6a"
          fill="#fff"
          width={23}
          height={23}
        />
      </button>
      {active && (
        <div>
          <dl className={classes.table}>
            {rows.map(row => {
              if (row.skip === false) return;
              return (
                <Typography variant="p3" fontWeight={400}>
                  <div className={classes.row} key={key(row)}>
                    <dt className={classes.key}>{row.key}</dt>
                    <dd className={classes.value}>{row.value}</dd>
                  </div>
                </Typography>
              );
            })}
            {related && (
              <Typography variant="p3" fontWeight={400} disableMarkdown={true}>
                <div className={classes.row}>
                  <dt className={classes.key}>{relatedTitle}</dt>
                  <dd className={classes.value}>
                    <>
                      {related.map(study => (
                        <a
                          className={classes.link}
                          key={key(study)}
                          title="View"
                          onClick={study.action}
                        >
                          {study.acronym}
                        </a>
                      ))}
                    </>
                  </dd>
                </div>
              </Typography>
            )}

            {footnotes &&
              footnotes.map(row => {
                if (!row.value) return;
                return (
                  <div className={classes.row} key={key(row)}>
                    <div className={classes.footer}>
                      <Footer
                        title={row.key}
                        value={row.value}
                        isOpen={row.isOpen}
                      />
                    </div>
                  </div>
                );
              })}
          </dl>
        </div>
      )}
    </div>
  );
}
