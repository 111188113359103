const POI_COMPARISON_KEY = 'poi_comparison';
const HEAT_MAP_COMPARISON_KEY = 'heat_map_comparison';

const getPoiComparisonFromLocalStorage = () =>
  localStorage.getItem(POI_COMPARISON_KEY);

const setPoiComparisonFromLocalStorage = (updatedComparison: string) =>
  localStorage.setItem(POI_COMPARISON_KEY, updatedComparison);

const getHeatMapComparisonFromLocalStorage = () =>
  localStorage.getItem(HEAT_MAP_COMPARISON_KEY);

const setHeatMapComparisonFromLocalStorage = (updatedComparison: string) =>
  localStorage.setItem(HEAT_MAP_COMPARISON_KEY, updatedComparison);

interface PoiComparisonSelection {
  country?: string;
  city?: string;
  study?: string;
}

interface HeatMapComparisonSelection {
  countryCode?: string;
}

type PoiComparisonLocalStorage = [
  PoiComparisonSelection,
  PoiComparisonSelection,
];

type HeatMapComparisonLocalStorage = [
  HeatMapComparisonSelection,
  HeatMapComparisonSelection,
];

export const getPoiComparisonLocalStorage = (): PoiComparisonLocalStorage => {
  const localAuthConfigString = getPoiComparisonFromLocalStorage();

  if (!localAuthConfigString) return [{}, {}];

  return JSON.parse(localAuthConfigString);
};

export const setPoiComparisonLocalStorage = (
  updatedComparison: PoiComparisonLocalStorage,
): void => {
  setPoiComparisonFromLocalStorage(JSON.stringify(updatedComparison));
};

export const getHeatMapComparisonLocalStorage =
  (): HeatMapComparisonLocalStorage => {
    const localAuthConfigString = getHeatMapComparisonFromLocalStorage();

    if (!localAuthConfigString) return [{}, {}];

    return JSON.parse(localAuthConfigString);
  };

export const setHeatMapComparisonLocalStorage = (
  updatedComparison: HeatMapComparisonLocalStorage,
): void => {
  setHeatMapComparisonFromLocalStorage(JSON.stringify(updatedComparison));
};
