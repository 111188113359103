import { createUseStyles } from 'react-jss';
import type { ReactNode } from 'react';

import type { LcpnTheme } from '../../styles/theme';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  shareLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
    backgroundColor: theme.colors['grey-100'],
    padding: '16px 16px',
    cursor: 'pointer',
    color: theme.colors.darker,
  },

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    buttonGroup: {
      paddingBottom: 26,
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    border: 'none',
    backgroundColor: theme.colors.white,
    borderRadius: 20,
    padding: '0px 32px 0px 32px',
    minHeight: 36,
  },
}));

type Props = {
  children: ReactNode;
};

export function ShareLink(props: Props) {
  const classes = useStyles();
  const { children } = props;
  return <div className={classes.shareLink}>{children}</div>;
}
