import { createUseStyles } from 'react-jss';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { Stack } from '@mui/material';
import key from 'weak-key';
import { Fragment } from 'react';

import { Typography } from '../../Typography';
import type { LcpnTheme } from '../../../styles/theme';
import { Icon } from '../../Icon';

import { ReactComponent as Logo } from './lcpn-logo.svg';
import type { Heading, Section } from './types';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  root: {
    color: '#333740',
    width: '100%',
    border: 'none',
    borderSpacing: 0,
    '&.is-comparison': {
      tableLayout: 'fixed',
    },
    '& tr': {},
    '& th, & td': {
      border: 'none',
      borderWidth: 0,
      borderBottom: '8px solid white',
      textAlign: 'left',
      verticalAlign: 'top',
      position: 'relative',

      '&.image-cell': {
        verticalAlign: 'middle',
        borderBottom: 'none',
        '& > *': {
          width: '100%',
          maxWidth: 400,
          paddingRight: 32,
          height: 'auto',
          '&::after': {
            display: 'none',
          },
        },
      },

      '&.header-cell': {
        borderBottom: '1px solid white',
        '&:last-child': {
          borderLeft: '1px solid white',
        },
        '& > *': {
          paddingTop: 16,
          paddingBottom: 16,
        },
      },

      '&.divider-cell': {
        height: 32,
      },

      '& > *': {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 20,
        paddingRight: 20,
        display: 'block',
        overflow: 'auto',
        '&::after': {
          content: '""',
          borderBottom: '1px solid #6B6B6B',
          position: 'absolute',
          width: '100%',
          left: 0,
          bottom: 0,
        },
      },
      '& ol': {
        paddingLeft: 16,
        marginTop: 0,
        marginBottom: 16,
      },
    },
    '& th': {
      color: 'white',
    },
    '& td': {
      width: '33.3333%',
      '&.double-width': {
        width: '66.6666%',
      },
      '&.full-width': {
        width: '100%',
      },
      '&.title': {
        backgroundColor: 'rgba(228, 229, 230, 0.3)',
      },
      '&.to > *': {
        borderLeft: '1px solid #6B6B6B',
      },
      '&.notesTitle': {
        textAlign: 'center',
        backgroundColor: 'rgba(228, 229, 230, 0.75)',
        textTransform: 'uppercase',
      },
    },
    '@media print': {
      '&&': {
        '& th, & td': {
          wordBreak: 'break-word',
          '& > *': {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
          },
          '&.header-cell': {
            '& > *': {
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 8,
              paddingRight: 8,
            },
          },
        },
      },
    },
  },
}));

interface Props<T> {
  headings: Heading<T>[];
  sections: Section<T>[];
  items: [T, T] | T;
  type: 'heatmap' | 'poi';
}

function isComparisonTable<T>(items: unknown): items is [T, T] {
  return Array.isArray(items);
}

function isPrintTable<T>(items: unknown): items is T {
  return typeof items === 'object' && items !== null;
}

export function DataDisplayTable<T>(props: Props<T>) {
  const { headings, sections, items } = props;

  const classes = useStyles();

  let item1: T;
  let item2: T;
  if (isComparisonTable<T>(items)) {
    [item1, item2] = items;
  } else if (isPrintTable<T>(items)) {
    item1 = items;
  } else {
    return <></>;
  }

  const isComparison = isComparisonTable<T>(items);

  return (
    <table className={clsx(classes.root, { 'is-comparison': isComparison })}>
      <tbody>
        {headings.map((h, i) => {
          const getValue = (item: T) =>
            (typeof h.key === 'function'
              ? h.key(item)
              : item[h.key]) as ReactNode;

          const getColor = (
            item: T,
            type: 'color' | 'backgroundColor' = 'color',
          ) =>
            (typeof h[type] === 'function'
              ? (h[type] as Function)(item)
              : h[type]) as string;

          return (
            <tr key={key(h)}>
              {!i && isComparison && (
                <td className={clsx('image-cell')} rowSpan={headings.length}>
                  <div>
                    <Logo />
                  </div>
                </td>
              )}

              <td
                colSpan={
                  !isComparison || (h.mergeCells && isComparison) ? 2 : 1
                }
                className={clsx('header-cell')}
                style={{
                  backgroundColor: getColor(item1, 'backgroundColor'),
                  color: getColor(item1),
                }}
              >
                <Typography variant={h.typographyVariant || 'p1'}>
                  {getValue(item1)}
                </Typography>
              </td>
              {!h.mergeCells && isComparison && (
                <td
                  className={clsx('header-cell')}
                  style={{
                    backgroundColor: getColor(item2, 'backgroundColor'),
                    color: getColor(item2),
                  }}
                >
                  <Typography variant={h.typographyVariant || 'p1'}>
                    {getValue(item2)}
                  </Typography>
                </td>
              )}
            </tr>
          );
        })}
        <tr>
          <th colSpan={isComparison ? 3 : 2} className={clsx('divider-cell')} />
        </tr>

        {sections.map(s => (
          <Fragment key={key(s)}>
            {!!s.title && (
              <tr>
                <th
                  colSpan={isComparison ? 3 : 2}
                  style={{ backgroundColor: s.backgroundColor }}
                >
                  <Typography variant={'p1'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      {!!s.icon && (
                        <Icon width={24} height={24} variant={s.icon} />
                      )}
                      <div>{s.title}</div>
                    </Stack>
                  </Typography>
                </th>
              </tr>
            )}
            {s.rows.map(r => {
              const getValue = (item: T) =>
                (typeof r.key === 'function' ? r.key(item) : item[r.key]) as
                  | string
                  | number
                  | undefined;
              const format = r.format || (v => (v || r.fallback) as string);
              if (isComparison && !getValue(item1) && !getValue(item2))
                return null;
              return (
                <tr key={key(r)}>
                  <td className={'title'}>
                    <Typography variant={'p1'}>{r.title}</Typography>
                  </td>
                  <td
                    className={clsx('from', { 'double-width': !isComparison })}
                  >
                    <Typography variant={r.typographyVariant || 'p1'}>
                      {format(getValue(item1))}
                    </Typography>
                  </td>
                  {isComparison && (
                    <td className={'to'}>
                      <Typography variant={r.typographyVariant || 'p1'}>
                        {format(getValue(item2))}{' '}
                      </Typography>
                    </td>
                  )}
                </tr>
              );
            })}
            {!!s.notes &&
              s.notes.map(n => (
                <Fragment key={key(n)}>
                  <tr>
                    <td colSpan={isComparison ? 3 : 2} className={'notesTitle'}>
                      <Typography
                        variant={n.typographyVariant || 'p3'}
                        fontWeight={900}
                      >
                        {n.title}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={isComparison ? 3 : 2}>
                      <Typography variant={n.typographyVariant || 'p3'}>
                        {typeof n.body === 'function' ? n.body(item1) : n.body}{' '}
                      </Typography>
                    </td>
                  </tr>
                </Fragment>
              ))}
            <>
              <tr>
                <th
                  colSpan={isComparison ? 3 : 2}
                  className={clsx('divider-cell')}
                />
              </tr>
            </>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
