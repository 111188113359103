import type { FC, PropsWithChildren } from 'react';
import {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import { fetchDataFromPublicDir } from '../../map-data/fetch-data-from-public-dir';
import type { VirtualTourConfig } from '../../map-types';

import type { StepOptions } from './tour-types';
import {
  getExtendedVirtualTourStatusFromLocalStorage,
  setExtendedVirtualTourStatusFromLocalStorage,
} from './virtual-tour-local-storage';
import {
  cancelStepButton,
  completeStepButton,
  extendedVirtualTour,
  nextStepAndUpdateExtendedTourButton,
  nextStepButton,
  transitionToExtendTourButton,
  virtualTour,
} from './shepherd-tours';

interface ExtendedVirtualTour {
  initialMessage: StepOptions;
  updates: StepOptions[];
  unViewedUpdates: StepOptions[];
}

interface VirtualTourContext {
  virtualTourConfig: StepOptions[];
  extendedVirtualTourConfig: ExtendedVirtualTour;
  isReady: boolean;
  hasUpdates: boolean;
  clearUpdates: VoidFunction;
  refreshExtendedTour: VoidFunction;
}

const virtualTourContext = createContext<VirtualTourContext | undefined>(
  undefined,
);

/**
 * getVirtualTourStepsByLanguage
 *
 * Parse the virtual tour config and retrieve the context by user language
 */
const getVirtualTourStepsByLanguage = (
  tourSteps: VirtualTourConfig,
  resolvedLanguage: 'en' | 'fr',
) => {
  return tourSteps.virtualTour.steps.map(tourStep => {
    const { id, attachTo, canClickTarget, ...contentByLanguageMap } = tourStep;

    const contentByLanguage =
      contentByLanguageMap[resolvedLanguage] ?? contentByLanguageMap['en'];

    const enhanceTourStep = {
      id,
      attachTo,
      canClickTarget,
      ...contentByLanguage,
    } as StepOptions;

    return enhanceTourStep;
  });
};

/**
 * getExtendedTourStepsByLanguage
 *
 * Parse the extended virtual tour config and retrieve the context by user language
 */
const getExtendedTourStepsByLanguage = (
  tourSteps: VirtualTourConfig,
  resolvedLanguage: 'en' | 'fr',
): Omit<ExtendedVirtualTour, 'unViewedUpdates'> => {
  const { id, attachTo, ...initialMessageByLanguageMap } =
    tourSteps.extendedVirtualTour.initialMessage;
  const initialMessageByLanguage =
    initialMessageByLanguageMap[resolvedLanguage] ??
    initialMessageByLanguageMap['en'];

  const updates = tourSteps.extendedVirtualTour.updates.map(tourStep => {
    const { id, ...contentByLanguageMap } = tourStep;

    const contentByLanguage =
      contentByLanguageMap[resolvedLanguage] ?? contentByLanguageMap['en'];

    const enhanceTourStep = {
      id,
      ...contentByLanguage,
    } as StepOptions;

    return enhanceTourStep;
  });

  const initialMessage = {
    id,
    attachTo,
    ...initialMessageByLanguage,
  } as StepOptions;

  return {
    initialMessage,
    updates,
  };
};

/**
 * enhanceExtendedTourWithUnViewedSteps
 *
 * Retrieve the viewed extended tour steps by ID and filter the extended tour steps to remove those
 * already viewed so the user only sees updates.
 */
const enhanceExtendedTourWithUnViewedSteps = (
  extendedTour: Omit<ExtendedVirtualTour, 'unViewedUpdates'>,
): ExtendedVirtualTour => {
  const viewedUpdates = getExtendedVirtualTourStatusFromLocalStorage();

  const unViewedUpdates = extendedTour.updates.filter(
    ({ id }) => id && !viewedUpdates.includes(id),
  );

  return {
    ...extendedTour,
    unViewedUpdates,
  };
};

/**
 * VirtualTourProvider
 *
 * Holds state for fetched configurations and filtered value thereof dependant on if steps
 * previously viewed or not.
 */
export const VirtualTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const [virtualTourConfigLoading, setVirtualTourConfigLoading] =
    useState(true);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();
  const [tourConfigs, setTourConfigs] = useState<
    Omit<
      VirtualTourContext,
      'isReady' | 'hasUpdates' | 'clearUpdates' | 'refreshExtendedTour'
    >
  >({
    virtualTourConfig: [],
    extendedVirtualTourConfig: {
      updates: [],
      initialMessage: {},
      unViewedUpdates: [],
    },
  });
  const [hasUpdates, setHasUpdates] = useState(false);
  const { i18n } = useTranslation();

  const clearUpdates = useCallback(() => {
    setHasUpdates(false);
  }, []);

  /**
   * Refreshes tour to update the number of updates on initial message and remove any steps that have been viewed since last navigate
   */
  const refreshExtendedTour = useCallback(() => {
    const viewedUpdates = getExtendedVirtualTourStatusFromLocalStorage();

    const unViewedUpdates =
      tourConfigs.extendedVirtualTourConfig.updates.filter(
        ({ id }) => id && !viewedUpdates.includes(id),
      );

    const initialMessage = extendedVirtualTour.getById(
      tourConfigs.extendedVirtualTourConfig.initialMessage.id ?? '',
    );

    if (
      !initialMessage ||
      typeof tourConfigs.extendedVirtualTourConfig.initialMessage.text !==
        'string'
    )
      return;

    initialMessage.updateStepOptions({
      text: tourConfigs.extendedVirtualTourConfig.initialMessage.text?.replace(
        /{{number}}/,
        String(unViewedUpdates.length),
      ),
    });

    for (const tourStepId of getExtendedVirtualTourStatusFromLocalStorage()) {
      extendedVirtualTour.removeStep(tourStepId);
    }
  }, [tourConfigs.extendedVirtualTourConfig]);

  useEffect(() => {
    fetchDataFromPublicDir('virtualTour', 'virtual-tour-config.json').then(
      (tourConfig: VirtualTourConfig) => {
        const virtualTour = getVirtualTourStepsByLanguage(
          tourConfig,
          i18n.resolvedLanguage as 'en' | 'fr',
        );

        const extendedTourByLanguage = getExtendedTourStepsByLanguage(
          tourConfig,
          i18n.resolvedLanguage as 'en' | 'fr',
        );

        const extendedTour = enhanceExtendedTourWithUnViewedSteps(
          extendedTourByLanguage,
        );

        if (extendedTour.unViewedUpdates.length > 0) {
          setHasUpdates(true);
        }

        setTourConfigs({
          virtualTourConfig: virtualTour,
          extendedVirtualTourConfig: extendedTour,
        });

        setVirtualTourConfigLoading(false);
      },
    );
  }, [i18n.resolvedLanguage]);

  const configVirtualTour = useCallback(() => {
    // if tour has already being configured then to not re-configure again
    if (virtualTour.steps.length > 0) return;

    for (const [index, tourStep] of tourConfigs.virtualTourConfig.entries()) {
      // update buttons on last step to save status as complete
      if (index === tourConfigs.virtualTourConfig.length - 1) {
        tourStep.buttons =
          tourConfigs.extendedVirtualTourConfig.unViewedUpdates.length > 0
            ? [transitionToExtendTourButton(t('virtualTour.cta.next'))]
            : [completeStepButton(t('virtualTour.cta.finish'))];
        tourStep.classes = 'shepherd-one-button-footer';
      } else {
        tourStep.buttons = [
          cancelStepButton(t('virtualTour.cta.skip')),
          nextStepButton(t('virtualTour.cta.next')),
        ];
      }

      // add step to tour
      virtualTour.addStep(tourStep);
    }
  }, [
    t,
    tourConfigs.extendedVirtualTourConfig.unViewedUpdates.length,
    tourConfigs.virtualTourConfig,
  ]);

  const configExtendedVirtualTour = useCallback(() => {
    // if extended tour has already being configured then to not re-configure again
    if (extendedVirtualTour.steps.length > 0) return;

    if (tourConfigs.extendedVirtualTourConfig.unViewedUpdates.length > 0) {
      const initialMessage = {
        ...tourConfigs.extendedVirtualTourConfig.initialMessage,
      };

      initialMessage.buttons = [
        nextStepButton(t('virtualTour.cta.startExtended')),
      ];

      initialMessage.classes = 'shepherd-one-button-footer';

      initialMessage.text = (
        tourConfigs.extendedVirtualTourConfig.initialMessage.text as string
      ).replace(
        /{{number}}/,
        String(tourConfigs.extendedVirtualTourConfig.unViewedUpdates.length),
      );

      extendedVirtualTour.addStep(initialMessage);

      for (const [
        index,
        extendedTourStep,
      ] of tourConfigs.extendedVirtualTourConfig.unViewedUpdates.entries()) {
        extendedTourStep.buttons =
          index ===
          tourConfigs.extendedVirtualTourConfig.unViewedUpdates.length - 1
            ? [completeStepButton(t('virtualTour.cta.finish'))]
            : [nextStepAndUpdateExtendedTourButton(t('virtualTour.cta.next'))];

        extendedVirtualTour.addStep(extendedTourStep);
      }

      const allUpdateIds = tourConfigs.extendedVirtualTourConfig.updates
        .map(({ id }) => id)
        .filter(Boolean) as string[];

      extendedVirtualTour.on('complete', () => {
        clearUpdates();
        setExtendedVirtualTourStatusFromLocalStorage(allUpdateIds);
      });
    }
  }, [
    clearUpdates,
    t,
    tourConfigs.extendedVirtualTourConfig.initialMessage,
    tourConfigs.extendedVirtualTourConfig.unViewedUpdates,
    tourConfigs.extendedVirtualTourConfig.updates,
  ]);

  // instantiate and configure the virtual tour/s so ready to start
  useEffect(() => {
    if (!virtualTourConfigLoading) {
      configVirtualTour();
      configExtendedVirtualTour();
      setIsReady(true);
    }
  }, [configExtendedVirtualTour, configVirtualTour, virtualTourConfigLoading]);

  const memorizedContext = useMemo(
    () => ({
      isReady,
      hasUpdates,
      clearUpdates,
      refreshExtendedTour,
      ...tourConfigs,
    }),
    [clearUpdates, hasUpdates, isReady, refreshExtendedTour, tourConfigs],
  );

  return (
    <virtualTourContext.Provider value={memorizedContext}>
      {children}
    </virtualTourContext.Provider>
  );
};

/**
 * useVirtualTourConfig
 *
 * Simple hook to check if used within the virtualTourContext and if so returns the value thereof
 */
export const useVirtualTourConfig = () => {
  const context = useContext(virtualTourContext);

  if (!context) {
    throw new Error(
      'useVirtualTourConfig: Used outside of the VirtualTourProvider',
    );
  }

  return context;
};
