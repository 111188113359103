import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../../styles/theme';

import { ReactComponent as Pattern } from './lcpn-vector.svg';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#043565',
    color: 'white',
    textAlign: 'right',
    padding: [48, 16],
    '& a': {
      color: 'white',
      textDecoration: 'none',
    },
    '& .pattern': {
      top: 0,
      left: 0,
      height: '100%',
      width: 245,
      overflow: 'hidden',
      position: 'absolute',
      '& svg': {
        position: 'absolute',
        bottom: '50%',
        transform: 'translate(-12%, 63%)',
        left: 0,
        width: '100%',
        height: 'auto',
      },
    },
  },
}));
export function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="pattern">
        <Pattern />
      </div>
      <a href={'https://www.lungcancerpolicynetwork.com'}>
        www.lungcancerpolicynetwork.com
      </a>
    </div>
  );
}
