import React from 'react';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Typography } from '../Typography';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.white,
    paddingBottom: 16,
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
  },

  key: {
    width: '100%',
    marginLeft: 'clamp(67px, 5.5%, 67px)',
  },

  value: {
    width: '100%',
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    key: {
      '&&': {
        marginLeft: 8,
      },
    },
  },
}));

type Row = {
  key: string;
  value?: string;
  fallback?: string;
};

type Props = {
  rows: Row[];
};

export function ProgrammeSummary(props: Props) {
  const classes = useStyles();
  const { rows } = props;

  return (
    <>
      <dl className={classes.table}>
        {rows
          .filter(c => c.value || c.fallback)
          .map((row, index) => (
            <div className={classes.row} key={index}>
              <dt className={classes.key}>
                <Typography variant="p3" fontWeight={400}>
                  {row.key}
                </Typography>
              </dt>
              <dd className={classes.value}>
                <Typography variant="p3" fontWeight={400}>
                  {row.value ?? row.fallback}
                </Typography>
              </dd>
            </div>
          ))}
      </dl>
    </>
  );
}
