import ReactGA4 from 'react-ga4';
import { useEffect, useState } from 'react';

import { useNavigationBarData } from '../NavigationBar/NavigationBarProvider';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { useHeatMapSelectedLocations } from '../HeatMapExperience/HeatMapSelectedLocationsProvider';

export function useAnalytics() {
  const [initialised, setInitialised] = useState<boolean>(false);
  const { selectedExperience } = useNavigationBarData();
  const {
    selectedProgramId,
    selectedCountryName,
    selectedCountrySubDivisionName,
  } = usePoiSelectedLocations();

  const { selectedCountryCode } = useHeatMapSelectedLocations();

  useEffect(() => {
    if (process.env.REACT_APP_GA4_ID) {
      ReactGA4.initialize(process.env.REACT_APP_GA4_ID, {
        gtagOptions: { debug_mode: !!process.env.REACT_APP_GA4_DEBUG },
      });

      setInitialised(true);
    }
  }, []);

  useEffect(() => {
    let action, label;
    switch (true) {
      case !!selectedProgramId: {
        action = 'program';
        label = selectedProgramId;
        break;
      }
      case !!selectedCountrySubDivisionName: {
        action = 'city/region';
        label =
          selectedCountrySubDivisionName?.city ??
          selectedCountrySubDivisionName?.region;
        break;
      }
      case !!selectedCountryName: {
        action = 'country';
        label = selectedCountryName;
        break;
      }
      default:
      //
    }

    if (initialised && label && action) {
      ReactGA4.event({
        category: selectedExperience || '',
        action: `click-${action}`,
        label,
      });
    }
  }, [
    selectedExperience,
    selectedCountryName,
    selectedProgramId,
    initialised,
    selectedCountrySubDivisionName,
  ]);

  useEffect(() => {
    let action, label;
    switch (true) {
      case !!selectedCountryCode: {
        action = 'country';
        label = selectedCountryCode;
        break;
      }
      default:
      //
    }

    if (initialised && label && action) {
      ReactGA4.event({
        category: selectedExperience || '',
        action: `click-${action}`,
        label,
      });
    }
  }, [selectedExperience, selectedCountryCode, initialised]);

  return ReactGA4;
}
