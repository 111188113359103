import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Button } from '../Button';
import { Typography } from '../Typography';

import { useUpdateNavigationBar } from './NavigationBarProvider';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  dropDownMenu: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors['grey-100'],
    margin: '8px 8px 0px 8px',
    padding: 8,
  },

  head: {
    display: 'flex',
    width: '100',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0px 0px 0px',
  },

  body: {
    width: '100%',
    backgroundColor: theme.colors.white,
    marginTop: 8,
  },

  [`@media screen and (max-width: ${theme.breakPoints.desktop}px)`]: {
    dropDownMenu: {
      '&&': { margin: 0, padding: 0 },
    },
    head: {
      '&&': {
        padding: '8px 0  0 16px',
      },
    },
  },
}));

type Props = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

export function DropDownMenu(props: Props) {
  const { resetNavigationBar } = useUpdateNavigationBar();

  const classes = useStyles();
  const { title, children } = props;
  return (
    <div className={classes.dropDownMenu}>
      <div className={classes.head}>
        <Typography variant="h4">{title}</Typography>
        <Button label="Close" onClick={() => resetNavigationBar()} />
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
}
