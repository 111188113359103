import { useTranslation } from 'react-i18next';

import { Filter } from '../Filter';
import { useNavigationBarData } from '../NavigationBar/NavigationBarProvider';
import { useHeatMapFilterOptions } from '../../map-data/use-heat-map-filter-options';
import type { FilterConfig } from '../Filter/filter-types';

export function HeatMapFilters() {
  const { t } = useTranslation();
  const { selectedNavigationItem } = useNavigationBarData();
  const { resetFilterOptions, updateFilterOptions } = useHeatMapFilterOptions();

  const heatmapFilterConfig: FilterConfig = [
    [
      {
        icon: 'StudyDesignCheck',
        title: t('heatMapExperience.filters.title'),
        key: 'status',
        subtitle: '',
        keyOptions: [
          {
            label: t('heatMapExperience.filters.options.seeAll'),
            value: 'AllStatuses',
            color: '25, 25, 25',
          },
          {
            value: 'UnknownStatus',
            label: t('heatMapExperience.filters.options.unknown'),
            color: '222, 112, 107',
          },
          {
            value: 'ImplementationResearch',
            label: t(
              'heatMapExperience.filters.options.implementationResearch',
            ),
            color: '158, 18, 66',
          },
          {
            value: 'FormalCommitment',
            label: t('heatMapExperience.filters.options.formalCommitment'),
            color: `75, 171, 214`,
          },
          // Hidden (but supported)
          // {
          //   value: 'RegionalRollout',
          //   label: t('heatMapExperience.filters.options.regionalRollout'),
          //   color: '222, 112, 107',
          // },
          // {
          //   value: 'NationalRollout',
          //   label: t('heatMapExperience.filters.options.nationalRollout'),
          //   color: '244, 180, 50',
          // },
          {
            value: 'ImplementationRollout',
            label: t('heatMapExperience.filters.options.implementationRollout'),
            color: '244, 180, 50',
          },
        ],
        onUpdate: updateFilterOptions,
        filterType: 'multiChoice',
      },
    ],
  ];

  if (selectedNavigationItem !== 'filter') {
    return null;
  }

  return (
    <Filter
      filterConfig={heatmapFilterConfig}
      resetFilterOptions={resetFilterOptions}
    />
  );
}
