import type { Dispatch, FC, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import type { HeatMapRange } from '../../map-data/map-data-types';
import { Button } from '../Button';
import type { LcpnTheme } from '../../styles/theme';

interface Props {
  setRangeFilterType: Dispatch<SetStateAction<HeatMapRange>>;
  rangeFilterType: HeatMapRange;
}

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  buttonGroup: {
    position: 'absolute',
    top: '8px',
    right: '0rem',
    display: 'flex',
    gap: 8,
  },

  button: {
    maxWidth: '7em',
    '& > button': {
      backgroundColor: theme.colors.white,
      color: 'black',
    },
    '& > button:hover': {
      backgroundColor: theme.colors.white,
      color: 'black',
    },
  },

  buttonIncidence: {
    maxWidth: '7em',
    '& > button': {
      backgroundColor: '#6aaed5',
      color: theme.colors.white,
    },
    '& > button:hover': {
      backgroundColor: '#6aaed5',
      color: theme.colors.white,
    },
  },

  buttonMortality: {
    maxWidth: '7em',
    '& > button, button:hover': {
      backgroundColor: '#fc694b',
      color: theme.colors.white,
    },
  },
}));

export const HeatMapCancerSeverityFilter: FC<Props> = ({
  setRangeFilterType,
  rangeFilterType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.buttonGroup}>
      <div
        className={clsx(
          {
            [classes.buttonIncidence]: rangeFilterType === 'incidence',
          },
          { [classes.button]: rangeFilterType !== 'incidence' },
        )}
      >
        <Button
          label={t('rangeFilter.incidence')}
          value={'incidence'}
          onClick={() => setRangeFilterType('incidence')}
        />
      </div>
      <div
        className={clsx(
          {
            [classes.buttonMortality]: rangeFilterType === 'mortality',
          },
          { [classes.button]: rangeFilterType !== 'mortality' },
        )}
      >
        <Button
          label={t('rangeFilter.mortality')}
          value={'mortality'}
          onClick={() => setRangeFilterType('mortality')}
        />
      </div>
      ;
    </div>
  );
};
