/* eslint-disable unicorn/prefer-module */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { HelmetProvider } from 'react-helmet-async';
import { MapProvider } from 'react-map-gl';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';

import reportWebVitals from './reportWebVitals';
import { App } from './App';
import './markets/default/i18n';
import { MapDataProvider } from './map-data/MapDataProvider';
import { muiTheme, theme } from './styles/theme';
import { GlobalStyles } from './styles/global-styles';
import { NavigationBarProvider } from './components/NavigationBar/NavigationBarProvider';
import { FilteredHeatMapDataProvider } from './map-data/FilteredHeatMapDataProvider';
import { FilteredPoiMapDataProvider } from './map-data/FilteredPoiMapDataProvider';
import { HeatMapSelectedLocationsProvider } from './components/HeatMapExperience/HeatMapSelectedLocationsProvider';
import { PoiSelectedLocationsProvider } from './components/PointOfInterestExperience/PoiSelectedLocationsProvider';
import { ComparisonProvider } from './providers/ComparisonProvider';
import { VirtualTourProvider } from './components/VirtualTour/VirtualTourProvider';

const container = document.querySelector('#root');

const root = createRoot(container as HTMLElement);

// using render from React <= v17 as there are compatibility issues with algolia search using the createRoot from react v18+
root.render(
  <React.StrictMode>
    <MemoryRouter>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <NavigationBarProvider>
              <MapDataProvider>
                <MapProvider>
                  <VirtualTourProvider>
                    <FilteredHeatMapDataProvider>
                      <FilteredPoiMapDataProvider>
                        <HeatMapSelectedLocationsProvider>
                          <PoiSelectedLocationsProvider>
                            <ComparisonProvider>
                              <GlobalStyles />
                              <App />
                            </ComparisonProvider>
                          </PoiSelectedLocationsProvider>
                        </HeatMapSelectedLocationsProvider>
                      </FilteredPoiMapDataProvider>
                    </FilteredHeatMapDataProvider>
                  </VirtualTourProvider>
                </MapProvider>
              </MapDataProvider>
            </NavigationBarProvider>
          </HelmetProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </MemoryRouter>
  </React.StrictMode>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./markets/default/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

reportWebVitals();
