import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import { clsx } from 'clsx';
import { renderToString } from 'react-dom/server';
import rehypeRaw from 'rehype-raw';
import type { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import type { ReactElement } from 'react-markdown/lib/react-markdown';

import { theme } from '../../styles/theme';

const useStyles = createUseStyles({
  typography: (props: CSSProperties) => ({
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    fontFamily: 'Be Vietnam Pro',
    '@media print': {
      '&&': {
        fontSize: (props.fontSize as number) * 0.7,
      },
    },
  }),
});

const typographyVariant = {
  h1: {
    name: 'h1',
    fontSize: theme.fontSizes.h1,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  h2: {
    name: 'h2',
    fontSize: theme.fontSizes.h2,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  h3: {
    name: 'h3',
    fontSize: theme.fontSizes.h3,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  h4: {
    name: 'h4',
    fontSize: theme.fontSizes.h4,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  p1: {
    name: 'p1',
    fontSize: theme.fontSizes.p1,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  p2: {
    name: 'p2',
    fontSize: theme.fontSizes.p2,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  p3: {
    name: 'p3',
    fontSize: theme.fontSizes.p3,
    fontWeight: 500,
    lineHeight: 1.27,
  },
  p4: {
    name: 'p4',
    fontSize: theme.fontSizes.p4,
    fontWeight: 500,
    lineHeight: 1.27,
  },
};

export type TypographyVariant = keyof typeof typographyVariant;

type typographyProps = HTMLAttributes<Element> & {
  variant: TypographyVariant;
  className?: string;
  children: ReactNode | string;
  fontWeight?: number;
  disableMarkdown?: boolean;
};

const isReactElement = (element: unknown): element is ReactElement => {
  return !!(
    typeof element === 'object' &&
    element?.hasOwnProperty('type') &&
    element?.hasOwnProperty('props')
  );
};

export function Typography(props: typographyProps) {
  const selectedVariant = typographyVariant[props.variant];
  const { children, className, fontWeight, disableMarkdown } = props;
  const classes = useStyles({ ...selectedVariant, fontWeight });

  if (!children) return null;

  if (disableMarkdown)
    return (
      <div className={clsx(classes.typography, className)}>{children}</div>
    );

  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      // eslint-disable-next-line react/no-children-prop
      children={
        children === typeof String || children === typeof Number
          ? `${children}`
          : renderToString(
              isReactElement(children) ? children : <>{children}</>,
            )
      }
      linkTarget="_blank" // applies to all links
      className={clsx(classes.typography, className)}
    />
  );
}
