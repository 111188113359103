import { useContext, useMemo } from 'react';

import { FilteredPoiMapDataContext } from './FilteredPoiMapDataProvider';

/**
 * useFilterOptions
 *
 * Simple hook to expose the filter options as held in the FilteredMapDataContext
 */
export const usePoiFilterOptions = () => {
  const filteredMapData = useContext(FilteredPoiMapDataContext);

  if (!filteredMapData) {
    throw new Error(
      'useMapData hook it being used outside of the MapDataProvider',
    );
  }

  const {
    updateFilterOptions,
    filterOptions,
    resetFilterOptions,
    filterConfig,
  } = useMemo(
    () => ({
      updateFilterOptions: filteredMapData.updateFilterOptions,
      filterOptions: filteredMapData.filterOptions,
      resetFilterOptions: filteredMapData.resetFilterOptions,
      filterConfig: filteredMapData.filterConfig,
    }),
    [
      filteredMapData.updateFilterOptions,
      filteredMapData.filterOptions,
      filteredMapData.resetFilterOptions,
      filteredMapData.filterConfig,
    ],
  );

  return {
    updateFilterOptions,
    filterOptions,
    resetFilterOptions,
    filterConfig,
  };
};
