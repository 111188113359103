import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';

import type { HeatMapCountryMetaData } from '../../../../lcpn-uploader/server/services/parse-map-records/heat-map/heat-map-types';
import { Typography } from '../../Typography';

import { ReactComponent as Logo } from './lcpn-logo.svg';
import { DataDisplayTable } from './DataTable';
import { useHeatMapDataTableDefinitions } from './UseHeatMapDataTableDefinitions';
import { Disclaimer } from './Disclaimer';
import { Footer } from './Footer';

export function HeatmapPrintTable(props: { country: HeatMapCountryMetaData }) {
  const { t } = useTranslation();
  const { country } = props;

  const { sections, headings } = useHeatMapDataTableDefinitions();

  return (
    <Stack spacing={4}>
      <Box>
        <Stack spacing={4}>
          <div style={{ color: '#008B7F' }}>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={4}
            >
              <Grid item xs={4}>
                <Logo />
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'h2'}>
                  <strong>{t('comparisonTable.heatMap.title')}</strong>
                </Typography>
              </Grid>
            </Grid>
          </div>
          {!!country && (
            <DataDisplayTable<HeatMapCountryMetaData>
              headings={headings}
              type={'heatmap'}
              items={country}
              sections={sections}
            />
          )}
        </Stack>
      </Box>

      <Disclaimer />
      <Footer />
    </Stack>
  );
}
