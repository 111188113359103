import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Typography } from '../Typography';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  listHeading: {
    backgroundColor: theme.colors['grey-100'],
    padding: '16px 0px 16px 16px',
  },

  textGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
  },

  link: {
    textDecoration: 'underline',
    textUnderlineOffset: 10,
    marginRight: 16,
    cursor: 'pointer',
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    listHeading: {
      '&&': {
        padding: '8px 0px 8px 0px',
      },
    },
  },
}));

type Props = {
  label?: string;
  navigationLabel?: string;
  navigationAction: () => void;
  navigationVisible?: boolean;
};

export function ListHeading(props: Props) {
  const classes = useStyles();
  const {
    navigationAction,
    navigationLabel,
    navigationVisible = true,
    label = '',
  } = props;
  return (
    <div className={classes.listHeading}>
      <div className={classes.textGroup}>
        <Typography variant="p2" fontWeight={400}>
          {label}
        </Typography>
        {navigationVisible && (
          <button className={classes.link} onClick={navigationAction}>
            <Typography variant="p2" fontWeight={400}>
              {navigationLabel}
            </Typography>
          </button>
        )}
      </div>
    </div>
  );
}
