import { ReactCountryFlag } from 'react-country-flag';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  flag: {},
});

type Props = {
  countryCode: string;
};

/* get the flags from this repository - https://github.com/lipis/flag-icons */
export function FlagIcon(props: Props) {
  const classes = useStyles();
  const { countryCode } = props;
  return (
    <ReactCountryFlag
      countryCode={countryCode}
      cdnUrl={`${process.env.PUBLIC_URL}/assets/flags/4x3/`}
      svg
      className={classes.flag}
      style={{
        width: '50%',
        height: '50%',
        display: 'block',
      }}
    />
  );
}
