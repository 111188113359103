import styled from 'styled-components';
import type { FC } from 'react';

import { useMapData } from '../../map-data/use-map-data';
import type { IsoCountryCodes } from '../../map-types';

export interface HeatMapResultItemComponentProps {
  hit: {
    objectID: string;
  };
  onResultClick: (countryCode: IsoCountryCodes) => void;
}

const ResultWrapper = styled.div`
  cursor: pointer;
  padding: 0.9rem;
  width: 100%;
`;

export const HeatMapResultItem: FC<HeatMapResultItemComponentProps> = ({
  hit,
  onResultClick,
}) => {
  const { loading, countryHeatMapMetaData } = useMapData();

  const record = countryHeatMapMetaData?.[hit.objectID];

  if (loading || !record) {
    return null;
  }

  return (
    <ResultWrapper
      onClick={() => onResultClick(hit.objectID)}
    >{`${record.countryName}`}</ResultWrapper>
  );
};
