import { clsx } from 'clsx';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

type Props = {
  title: string;
  value?: string;
  fallback?: string;
  isOpen?: boolean;
};

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },

  '@keyframes expand': {
    from: {
      height: 0,
    },
    to: {
      height: 200,
    },
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  },

  rotateIconDown: {
    transform: 'rotate(180deg)',
  },

  icon: {
    transition: 'transform .4s ease-out',
  },

  information: {
    opacity: 0,
  },

  footNote: {
    paddingRight: '2em',
    '& > a': {
      textDecoration: 'underline',
    },
  },

  fadeIn: {
    animation: '$fadeIn .4s ease-out forwards',
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    information: {
      '&&': {
        overflow: 'hidden',
      },
    },
  },
}));

export function Footer(props: Props) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(props.isOpen ?? false);

  return (
    <div className={classes.footer}>
      <button className={classes.button} onClick={() => setToggle(!toggle)}>
        <Typography variant="p3" fontWeight={400}>
          {props.title}
        </Typography>
        <div
          className={clsx(classes.icon, {
            [classes.rotateIconDown]: toggle === true,
          })}
        >
          <Icon variant="BlackTriangle" width={6} height={6} />
        </div>
      </button>

      <div className={clsx(classes.information, { [classes.fadeIn]: toggle })}>
        {toggle && (
          <div className={classes.footNote}>
            <Typography variant="p3">{props.value}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}
