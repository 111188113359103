import { memo, useCallback, type FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

import { theme } from '../../styles/theme';
import { PointOfInterestCountryList } from '../PointOfInterestCountryList';
import { PinMap } from '../PinMap';
import { PoiSearch } from '../PoiSearch';
import { PoiSearchResults as SearchResults } from '../PoiSearch/PoiSearchResults';
import { PoiResultItem } from '../PoiSearch/PoiResultItem';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import type { PointOfInterestRecord } from '../../map-types';

import { usePoiSelectedLocations } from './PoiSelectedLocationsProvider';

const SearchWrapper = styled.div`
  position: absolute;
  margin: 1rem;
  z-index: 1;
  width: calc(50% - 3.625rem);
  overflow: visible;
  display: none;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    display: block;
  }
`;

const MapContainer = styled.div<{ isVisible: boolean }>`
  height: 100%;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  @media (min-width: ${theme.breakPoints.desktop}px) {
    padding: 1rem;
    box-sizing: border-box;
  }
`;

const ListWrapper = styled.div`
  background: white;
  padding: 1rem;
  box-sizing: border-box;
  @media (min-width: ${theme.breakPoints.desktop}px) {
    padding: 4.125rem 1rem 1rem 1rem;
  }
`;

const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: 0px;

  > div {
    flex: 50%;
  }

  > div:last-of-type {
    display: none;
  }

  @media (min-width: ${theme.breakPoints.laptop}px) {
    flex-direction: row;
    gap: 20px;
    > div:last-of-type {
      display: block;
    }
  }
  @media (max-width: ${theme.breakPoints.desktop}px) {
    height: 100vh;
    max-height: '100vh';
    margin-top: 0px;
    flex-direction: column-reverse;
    gap: 0;
    max-height: 100%;
  }
`;

type Props = {
  visible?: boolean;
};

const PoiSearchResults: FC = () => {
  const {
    setSelectedProgramId,
    setSelectedCountrySubDivisionName,
    setSelectedCountryName,
    setShowCountryCard,
    setShowCityCard,
    setShowProgramCard,
  } = usePoiSelectedLocations();
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const { resetNavigationBar } = useUpdateNavigationBar();

  const handleResultClick = useCallback(
    (record: PointOfInterestRecord) => {
      setIndexUiState({
        ...indexUiState,
        query: undefined,
      });
      setSelectedProgramId(record.id);
      setSelectedCountryName(record.country);
      setSelectedCountrySubDivisionName({
        city: record.city,
        region: record.region,
      });
      setShowCountryCard(false);
      setShowCityCard(false);
      setShowProgramCard(true);
      resetNavigationBar();
    },
    [
      indexUiState,
      resetNavigationBar,
      setIndexUiState,
      setSelectedCountryName,
      setSelectedCountrySubDivisionName,
      setSelectedProgramId,
      setShowCityCard,
      setShowCountryCard,
      setShowProgramCard,
    ],
  );

  return (
    <SearchResults
      resultItemComponent={props => (
        <PoiResultItem {...props} onResultClick={handleResultClick} />
      )}
    />
  );
};

export const PointOfInterestExperience = memo(({ visible = true }: Props) => {
  const { t } = useTranslation();

  return (
    <MapContainer isVisible={visible}>
      {visible && (
        <SearchWrapper id="point-of-interest-search-wrapper">
          <PoiSearch
            placeholder={t('poiSearch.placeholder', {
              defaultValue: 'Search the entire data set',
            })}
          >
            <PoiSearchResults />
          </PoiSearch>
        </SearchWrapper>
      )}
      <MapContent>
        {visible && (
          <ListWrapper>
            <PointOfInterestCountryList />
          </ListWrapper>
        )}
        <PinMap visible={visible} />
      </MapContent>
    </MapContainer>
  );
});

PointOfInterestExperience.displayName = 'PointOfInterestExperience';
