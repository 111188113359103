import { useMemo, useEffect } from 'react';
import { clsx } from 'clsx';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { Typography } from '../Typography';
import { ListHeading } from '../ListHeading';
import type { LcpnTheme } from '../../styles/theme';
import { Scrollbar } from '../Scrollbar';
import { Icon } from '../Icon';
import { useMapData } from '../../map-data/use-map-data';
import { useHeatMapSelectedLocations } from '../HeatMapExperience/HeatMapSelectedLocationsProvider';
import { useHeatMapFilterOptions } from '../../map-data/use-heat-map-filter-options';
import type {
  HeatMapCountryStatuses,
  HeatMapStatusFilterOption,
} from '../../map-data/map-data-types';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';

import { CountryRowAccordion } from './CountryRowAccordion';
import { COUNTRY_LIST_ID, COUNTRY_LIST_SUB_HEADING } from './constants';
import { getHeatmapCountryListScrollContainer } from './get-heatmap-country-list-scroll-container';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  countryListHeatMap: {
    height: '93.5%',
  },

  heading: {
    backgroundColor: theme.colors['blue-10'],
    padding: '1% 0% 1% 0%',
    borderTop: `${theme.colors['grey-100']} solid 2px`,
    color: theme.colors['blue-100'],

    '& > div > p': {
      marginLeft: 'clamp(32px, 5.5%, 32px)',
    },
    '& > div:first-child > p': {
      display: 'none',
    },
  },

  headingActive: {
    color: theme.colors.white,
    backgroundColor: theme.colors['blue-100'],
    borderTop: `${theme.colors['blue-100']} solid 2px`,
  },

  subHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors['blue-100'],
    marginLeft: 34,
    padding: '16px 24px 16px 0px',
  },

  mobileHeading: {
    display: 'none',
  },

  level: {
    display: 'none',
  },

  subHeadingGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 24,
    marginRight: 24,
  },

  [`@media screen and (max-width: ${theme.breakPoints.tablet}px)`]: {
    heading: {
      '&&': {
        display: 'flex',
        alignItems: 'center',
        '& > div > p': {
          marginLeft: 0,
        },
        '& > div:first-child > p': {
          display: 'block',
          marginLeft: 16,
        },
      },
    },
    link: {
      '&&': {
        display: 'none',
      },
    },
    level: {
      '&&': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.colors['blue-100'],
        borderBottom: `solid 1px ${theme.colors['grey-100']}`,
        padding: '8px 4px 8px 0px',
        '& > div > p': {
          marginLeft: 16,
        },
      },
    },
    subHeading: {
      '&&': {
        marginTop: 16,
        marginLeft: 16,
        padding: '16px 4px 16px 0px',
        '& > div > p': {
          fontSize: 10,
          color: theme.colors['blue-100'],
        },
      },
    },
    subHeadingGroup: {
      '&&': {
        '& > div > p': {
          marginLeft: -16,
        },
        '& > img': {
          display: 'none',
        },
      },
    },
  },
}));

export const headMapStatusMap: Record<
  HeatMapCountryStatuses,
  HeatMapStatusFilterOption
> = {
  'Implementation research': 'ImplementationResearch',
  'Formal commitment': 'FormalCommitment',
  'Regional roll-out': 'RegionalRollout',
  'National roll-out': 'NationalRollout',
  'Implementation roll-out': 'ImplementationRollout',
  'Unknown status': 'UnknownStatus',
  all: 'AllStatuses',
};

export function HeatMapCountryList() {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loading, countryHeatMapMetaData } = useMapData();
  const { filterOptions, resetFilterOptions } = useHeatMapFilterOptions();
  const { resetNavigationBar, updateSelectedNavigationItem } =
    useUpdateNavigationBar();

  const hasFiltersApplied = Object.keys(filterOptions).length > 0;

  const {
    setSelectedCountryCode,
    selectedCountryCode,
    setShowCountryCard,
    resetExperience,
  } = useHeatMapSelectedLocations();

  const countryMetaCollection = useMemo(() => {
    const countryMetaData = countryHeatMapMetaData
      ? Object.values(countryHeatMapMetaData)
      : [];

    if (!filterOptions.status || filterOptions.status === headMapStatusMap.all)
      return countryMetaData;

    return countryMetaData.filter(metaData => {
      return headMapStatusMap[metaData.countryStatus] === filterOptions.status;
    });
  }, [countryHeatMapMetaData, filterOptions]);

  // handle navigation via address bar query strings
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const qsId = params.get('heatmapId');
    if (qsId?.length === 2) {
      setSelectedCountryCode(qsId);
    }
  }, [setSelectedCountryCode]);

  const handleReset = () => {
    getHeatmapCountryListScrollContainer()?.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    resetExperience();
    updateSelectedNavigationItem('map');
    resetFilterOptions();
  };

  if (loading || !countryHeatMapMetaData) {
    // handle loading here
    return null;
  }

  const handleCountryClick = (countryCode: string) => {
    const active = selectedCountryCode === countryCode;
    // toggle visibility of country information
    setSelectedCountryCode(active ? '' : countryCode);
    setShowCountryCard(active ? false : true);
    resetNavigationBar();
  };

  return (
    <div className={classes.countryListHeatMap}>
      <ListHeading
        navigationAction={() => {
          handleReset();
        }}
        navigationLabel={t('dataViewCountries.backToQuickStart')}
        navigationVisible={!!selectedCountryCode || hasFiltersApplied}
        label={t('dataViewCountries.counter', {
          replace: {
            count: countryMetaCollection.length,
          },
        })}
      />
      <Scrollbar>
        <div id={COUNTRY_LIST_SUB_HEADING}>
          <div
            className={clsx(classes.heading, { [classes.headingActive]: true })}
          >
            <Typography variant="h3">
              {t('dataViewCountries.heatmap')} &gt;
            </Typography>
            <span className={classes.subHeadingGroup}>
              <Typography variant="h3">
                {t('dataViewCountries.countries')}
              </Typography>
              <Icon variant="CircleChevron" width={33} height={33} />
            </span>
          </div>
          <div className={classes.subHeading}>
            <Typography variant="p2">
              {t('dataViewCountries.countryName')}
            </Typography>
            <Typography variant="p2">
              {t('dataViewCountries.status')}
            </Typography>
          </div>
        </div>
        <ul id={COUNTRY_LIST_ID}>
          {countryMetaCollection?.map((countryMetaData, index) => (
            <CountryRowAccordion
              active={
                selectedCountryCode === countryMetaData.countryIso3166_1_2Name
              }
              record={countryMetaData}
              key={
                countryMetaData.countryName +
                '_' +
                countryMetaData.countryIso3166_1_2Name
              }
              handleClick={() =>
                handleCountryClick(countryMetaData.countryIso3166_1_2Name)
              }
              countryIndex={index}
            />
          ))}
        </ul>
      </Scrollbar>
    </div>
  );
}
