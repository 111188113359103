import { createUseStyles } from 'react-jss';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Stack, Popover } from '@mui/material';
import { useRef, useMemo } from 'react';

import { Icon } from '../Icon';
import { theme } from '../../styles/theme';
import { useHeatMapFilterOptions } from '../../map-data/use-heat-map-filter-options';
import { usePoiFilterOptions } from '../../map-data/use-filter-options';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { useHeatMapSelectedLocations } from '../HeatMapExperience/HeatMapSelectedLocationsProvider';
import { Typography } from '../Typography';
import { PointOfInterestFilters } from '../PointOfInterestExperience/PointOfInterestFilters';
import { HeatMapFilters } from '../HeatMapExperience/HeatMapFilters';

import { DropDownMenu } from './DropDownMenu';
import { MapSelection } from './MapSelection';
import type { MapExperience } from './NavigationBarProvider';
import {
  useNavigationBarData,
  useUpdateNavigationBar,
} from './NavigationBarProvider';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > *': { width: 'calc(50% - 1px)' },
    '&.bottom': {
      '& > *': { width: 'calc(50% - 0.5px)' },
    },
  },

  navigationButton: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16,
    padding: 16,
    height: '100%',
    backgroundColor: theme.colors['grey-100'],
    textAlign: 'left',
    '&:focus': {
      backgroundColor: theme.colors['green-100'],
      color: theme.colors.white,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '& > div:last-child': {
      textAlign: 'start',
    },
  },

  navigationButtonActive: {
    background: `linear-gradient(180deg, #16887D 0%, #008074 100%)`,
    color: theme.colors.white,
  },

  navigationButtonLight: {
    backgroundColor: theme.colors['green-100'],
    color: theme.colors.white,
    '&.active': {
      backgroundColor: 'rgba(0,139,127, 0.8)',
    },
    '&[disabled]': {
      color: 'rgba(255, 255, 255, 0.4)',
      backgroundColor: theme.colors['darker'],
      '& img': {
        opacity: 0.4,
      },
    },
  },

  dropDown: {
    display: 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 9,
  },

  showOnTabletPlus: {
    display: 'none',
  },

  desktopOnly: {
    display: 'none',
  },

  wideScreenOnly: {
    display: 'none',
  },

  mobileNavigation: {
    display: 'none',
  },

  mobileNavigationButton: {
    flex: 1,
    backgroundColor: theme.colors['white'],
    padding: 8,
    color: theme.colors['green-100'],
    '&.active': {
      backgroundColor: theme.colors['green-100'],
      color: theme.colors.white,
    },
  },

  buttonTitle: {
    '.bottom &': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'start',
    '& .expand': {
      marginLeft: 'auto',
    },
  },

  /* mobile and tablet styling */
  [`@media screen and (max-width: ${theme.breakPoints.desktop}px)`]: {
    container: {
      '&&': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors['grey-100'],
        zIndex: 9,
        position: 'fixed',
        maxHeight: '100vh',
        overflowY: 'scroll',
        bottom: 0,
        left: 0,
        right: 0,
      },
    },

    navigationButton: {
      '&&': {
        display: 'none',
      },
    },
    mobileNavigation: {
      '&&': {
        backgroundColor: theme.colors['grey-100'],
        padding: 8,
        display: 'flex',
        gap: 4,
      },
    },
    dropDown: {
      '&&': {
        display: 'block',
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakPoints.tablet}px)`]: {
    showOnTabletPlus: {
      '&&': {
        display: 'block',
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakPoints.desktop}px)`]: {
    desktopOnly: {
      '&&': {
        display: 'block',
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakPoints.widescreen}px)`]: {
    wideScreenOnly: {
      '&&': {
        display: 'block',
      },
    },
  },
});

export function NavigationBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const { selectedNavigationItem, selectedExperience, setSelectedExperience } =
    useNavigationBarData();
  const { updateSelectedNavigationItem } = useUpdateNavigationBar();

  const { resetExperience: resetPoiMap } = usePoiSelectedLocations();
  const { resetExperience: resetHeatMap } = useHeatMapSelectedLocations();
  const {
    resetFilterOptions: resetHeatMapFilters,
    filterOptions: heatmapFilterOptions,
  } = useHeatMapFilterOptions();
  const {
    resetFilterOptions: resetPoiMapFilters,
    filterOptions: poiFilterOptions,
  } = usePoiFilterOptions();

  const isPinMap = selectedExperience !== 'heatMap';

  const handleNavigationToMap = (view: MapExperience) => {
    setSelectedExperience(view);
    updateSelectedNavigationItem('map');

    if (view === 'heatMap') {
      resetPoiMap();
      resetPoiMapFilters();
    }

    if (view === 'pinMap') {
      resetHeatMap();
      resetHeatMapFilters();
    }
  };

  const filterCount = useMemo(() => {
    const filters: { [key: string]: any } =
      selectedExperience === 'pinMap' ? poiFilterOptions : heatmapFilterOptions;

    return Object.keys(filters || {}).filter(
      k => filters[k] !== undefined && filters[k] !== 'AllStatuses',
    ).length;
  }, [selectedExperience, poiFilterOptions, heatmapFilterOptions]);

  return (
    <>
      <div className={classes.dropDown}>
        {selectedNavigationItem === 'map' && (
          <DropDownMenu title={'Map'}>
            <MapSelection />
          </DropDownMenu>
        )}
        {selectedNavigationItem === 'filter' && (
          <DropDownMenu title="Filter">
            {isPinMap ? <PointOfInterestFilters /> : <HeatMapFilters />}
          </DropDownMenu>
        )}

        <div className={classes.mobileNavigation}>
          <button
            onClick={() =>
              updateSelectedNavigationItem(
                selectedNavigationItem === 'map' ? undefined : 'map',
              )
            }
            className={clsx(classes.mobileNavigationButton, {
              active: selectedNavigationItem === 'map',
            })}
          >
            <Stack spacing={0.5}>
              <div className={'icon'}>
                <Icon
                  variant={
                    selectedNavigationItem === 'map' ? 'ActiveMap' : 'Map'
                  }
                  width={24}
                  height={24}
                />
              </div>
              <Typography variant={'p4'} className="title">
                Map
              </Typography>
            </Stack>
          </button>
          <button
            onClick={() =>
              updateSelectedNavigationItem(
                selectedNavigationItem === 'filter' ? undefined : 'filter',
              )
            }
            className={clsx(classes.mobileNavigationButton, {
              active: selectedNavigationItem === 'filter',
            })}
          >
            <Stack spacing={0.5}>
              <div className={'icon'}>
                <Icon
                  variant={
                    selectedNavigationItem === 'filter'
                      ? 'ActiveFilter'
                      : 'Filter'
                  }
                  width={24}
                  height={24}
                />
              </div>
              <Typography variant={'p4'} className="title">
                {selectedExperience === 'pinMap'
                  ? t('navigationBar.poiFiltersTitle')
                  : t('navigationBar.heatMapFiltersTitle')}
              </Typography>
            </Stack>
          </button>
        </div>
      </div>
      <div className={classes.container} id="map-navigation-buttons">
        <button
          className={clsx(classes.navigationButton, {
            [classes.navigationButtonActive]: selectedExperience !== 'heatMap',
          })}
          onClick={() => handleNavigationToMap('pinMap')}
        >
          <div className={classes.buttonTitle}>
            {selectedExperience === 'pinMap' ? (
              <Icon variant="ActiveMap" width={24} height={24} />
            ) : (
              <Icon variant="Map" width={24} height={24} />
            )}
            <Typography variant="h3" fontWeight={400}>
              {t('navigationBar.poiTitle', { defaultValue: 'Pin map' })}
            </Typography>
          </div>
          <Typography variant="p3">
            {t('navigationBar.poiDescription', {
              defaultValue: 'Implementation studies and programmes',
            })}
          </Typography>
        </button>
        <button
          className={clsx(classes.navigationButton, {
            [classes.navigationButtonActive]: selectedExperience === 'heatMap',
          })}
          onClick={() => handleNavigationToMap('heatMap')}
          id="heat-map-navigation-button"
        >
          <div className={classes.buttonTitle}>
            {selectedExperience === 'heatMap' ? (
              <Icon variant="ActiveWorld" width={24} height={24} />
            ) : (
              <Icon variant="World" width={24} height={24} />
            )}
            <Typography variant="h3">
              {t('navigationBar.heatMapTitle', {
                defaultValue: 'Heat map',
              })}
            </Typography>
          </div>
          <Typography variant="p3">
            {t('navigationBar.heatMapDescription', {
              defaultValue: 'The policy context for implementation',
            })}
          </Typography>
        </button>
      </div>
      <div className={clsx(classes.container, 'bottom')}>
        <button
          className={clsx(
            classes.navigationButton,
            classes.navigationButtonLight,
            {
              active: selectedNavigationItem === 'filter',
            },
          )}
          ref={anchorEl}
          onClick={() => {
            updateSelectedNavigationItem('filter');
          }}
          id="open-filter-button"
        >
          <div className={classes.buttonTitle}>
            <Icon variant="ActiveFilter" width={24} height={24} />
            <Typography variant="p1" fontWeight={600}>
              {t('navigationBar.mapFiltersTitle', {
                defaultValue: 'Map filters',
              })}
            </Typography>
            <Typography
              variant="p3"
              fontWeight={600}
              className={classes.showOnTabletPlus}
            >
              {t('navigationBar.mapFiltersDescription', {
                defaultValue: '{{ count }} filters added',
                replace: {
                  count: filterCount,
                },
              })}
            </Typography>
            <Icon
              className={'expand'}
              variant={
                selectedNavigationItem === 'filter' ? 'ActiveExpand' : 'Expand'
              }
              width={24}
              height={24}
            />
          </div>
        </button>
        <button
          className={clsx(
            classes.navigationButton,
            classes.navigationButtonLight,
            { active: selectedNavigationItem === 'comparison' },
          )}
          onClick={() =>
            updateSelectedNavigationItem(
              selectedNavigationItem === 'comparison'
                ? undefined
                : 'comparison',
            )
          }
          id="open-comparison-tool-button"
        >
          <div className={classes.buttonTitle}>
            <Icon variant="ActiveComparison" width={24} height={24} />
            <Typography variant="p1" fontWeight={600}>
              {t('navigationBar.comparisonTitle', {
                defaultValue: 'Comparison tool',
              })}
            </Typography>
            <Typography
              variant="p3"
              fontWeight={600}
              className={classes.wideScreenOnly}
            >
              {selectedExperience === 'pinMap'
                ? t('navigationBar.poiComparisonDescription', {
                    defaultValue:
                      'Compare records of data by selecting studies/programmes',
                  })
                : t('navigationBar.heatMapComparisonDescription', {
                    defaultValue:
                      'Compare records of data by selecting countries',
                  })}
            </Typography>
            <Icon
              className={'expand'}
              variant={
                selectedNavigationItem === 'comparison'
                  ? 'ActiveExpand'
                  : 'Expand'
              }
              width={24}
              height={24}
            />
          </div>
        </button>
      </div>

      <Popover
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={selectedNavigationItem === 'filter'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => updateSelectedNavigationItem(undefined)}
        PaperProps={{
          style: { maxWidth: '100vW', width: '100vW' },
        }}
        marginThreshold={0}
        transitionDuration={0}
        className={classes.desktopOnly}
      >
        {isPinMap ? <PointOfInterestFilters /> : <HeatMapFilters />}
      </Popover>
    </>
  );
}
