import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { useMapData } from '../../../map-data/use-map-data';
import { useComparisonProviderContext } from '../../../providers/ComparisonProvider';
import type { PointOfInterestRecord } from '../../../../lcpn-uploader/types';
import { Typography } from '../../Typography';

import { DataDisplayTable } from './DataTable';
import { usePoiDataTableDefinitions } from './UsePoiDataTableDefinitions';

export function PoiComparisonTable() {
  const { t } = useTranslation();
  const { studiesById } = useMapData();
  const [studies, setStudies] = useState<
    [PointOfInterestRecord | undefined, PointOfInterestRecord | undefined]
  >([undefined, undefined]);
  const { poiComparison, setPoiComparison } = useComparisonProviderContext();

  const { headings, sections } = usePoiDataTableDefinitions();

  useEffect(() => {
    if (!!studiesById && !!poiComparison) {
      if (
        studiesById[poiComparison.fromStudyId!] &&
        studiesById[poiComparison.toStudyId!]
      ) {
        setStudies([
          {
            id: poiComparison.fromStudyId!,
            ...studiesById[poiComparison.fromStudyId!],
          },
          {
            id: poiComparison.toStudyId!,
            ...studiesById[poiComparison.toStudyId!],
          },
        ]);
      } else {
        setPoiComparison({ fromStudyId: undefined, toStudyId: undefined });
      }
      /*
       */
    }
  }, [poiComparison, setPoiComparison, studiesById]);

  /*
   */
  const [study1, study2] = studies;

  return (
    <Stack spacing={4}>
      <div style={{ color: '#155181' }}>
        <Typography variant={'h2'}>
          <strong>{t('comparisonTable.poi.title')}:</strong>
          <span>
            <br />
            {t('comparisonTable.poi.subtitle')}
          </span>
        </Typography>
      </div>
      {study1 && study2 && (
        <DataDisplayTable<PointOfInterestRecord>
          headings={headings}
          type={'poi'}
          items={[study1, study2]}
          sections={sections}
        />
      )}
    </Stack>
  );
}
