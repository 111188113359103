import { useContext } from 'react';

import { MapDataContext } from './MapDataProvider';

/**
 * useMapData
 *
 * Simple hook to expose the state as held in the MapDataContext
 */
export const useMapData = () => {
  const mapData = useContext(MapDataContext);

  if (!mapData) {
    throw new Error(
      'useMapData: hook it being used outside of the MapDataProvider',
    );
  }

  return mapData;
};
