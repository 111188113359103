import type { CSSProperties } from 'react';
import { clsx } from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@keyframes accordionExpand': {
    from: {
      height: '50%',
      width: 300,
    },
    to: {
      height: '85%',
      width: '94%',
    },
  },

  '@keyframes accordionShrink': {
    from: {
      height: '85%',
      width: '94%',
    },
    to: {
      height: '50%',
      width: 300,
    },
  },

  expando: (props: CSSProperties) => ({
    overflow: 'hidden',
    position: 'absolute',
    inset: props.inset,
    width: props.width,
    minHeight: props.minHeight,
    boxSizing: 'border-box',
    borderRadius: 12.5,
  }),

  accordionExpand: {
    animation: `$accordionExpand 0.5s ease-out forwards`,
  },

  accordionShrink: {
    animation: '$accordionShrink 0.5s ease-out forwards',
  },
});

type Props = {
  width: number;
  inset?: string | number;
  minHeight: string;
  children: JSX.Element | JSX.Element[];
  expand: boolean | null;
};

export function ExpandoBox(props: Props) {
  const { children, expand = null, width, minHeight, inset = 0 } = props;
  const classes = useStyles({ width, minHeight, inset });
  return (
    <div
      className={clsx(
        classes.expando,
        { [classes.accordionExpand]: expand === true },
        { [classes.accordionShrink]: expand === false && expand != null },
      )}
    >
      {children}
    </div>
  );
}
