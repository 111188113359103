import type { FC } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useComparisonProviderContext } from '../../../providers/ComparisonProvider';

import { PoiPicker } from './PoiPicker';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 120px)',
    background: '#F0F0F0',
    overflowY: 'scroll',
  },
  helpTextContainer: {
    background: '#3D434F',
    minHeight: '3.6875rem',
    maxHeight: '3.6875rem',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '0 1.25rem',
    fontSize: 15,
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  ctaWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

export const PoiStudyComparisonPicker: FC = () => {
  const classes = useStyles();
  const [selectionOneStudy, setSelectionOneStudy] = useState<string>();
  const [selectionTwoStudy, setSelectionTwoStudy] = useState<string>();
  const { t } = useTranslation();
  const { setPoiComparison } = useComparisonProviderContext();

  const disableGenerateButton =
    !selectionOneStudy ||
    !selectionTwoStudy ||
    selectionOneStudy === selectionTwoStudy;

  return (
    <div className={classes.container}>
      <div className={classes.helpTextContainer}>
        <p>{t('comparisonPicker.poi.helpText')}</p>
        {!disableGenerateButton && (
          <Button
            onClick={() => {
              setPoiComparison({
                fromStudyId: selectionOneStudy,
                toStudyId: selectionTwoStudy,
                display: true,
              });
            }}
          >
            {t('comparisonPicker.poi.generateCta')}
          </Button>
        )}
      </div>
      <Box sx={{ margin: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PoiPicker
              selectionNumber={1}
              onStudySelect={studyId => {
                setSelectionOneStudy(studyId);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PoiPicker
              selectionNumber={2}
              onStudySelect={studyId => {
                setSelectionTwoStudy(studyId);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          paddingBottom: '2rem',
        }}
      >
        <Button
          disabled={disableGenerateButton}
          onClick={() => {
            setPoiComparison({
              fromStudyId: selectionOneStudy,
              toStudyId: selectionTwoStudy,
              display: true,
            });
          }}
          sx={{
            mt: 2,
          }}
        >
          {t('comparisonPicker.poi.generateCta')}
        </Button>
      </Box>
    </div>
  );
};
