import type { PoiFilterConfig } from './map-data-types';

const equalsToComparator = (
  _?: PoiFilterConfig,
  studyValue?: string | number,
  filterValue?: string | number,
) =>
  typeof studyValue === 'string' && typeof filterValue === 'string'
    ? studyValue.toLowerCase() === filterValue.toLowerCase()
    : studyValue === filterValue;

export const poiFilterOptionComparatorMap = {
  status: equalsToComparator,
  sex: equalsToComparator,
  riskModellingUsed: equalsToComparator,
  smokersNeverEligible: equalsToComparator,
  targetOutreach: equalsToComparator,
  biomarkersCollected: equalsToComparator,
  smokingCessationOffered: equalsToComparator,
  useCade: equalsToComparator,
  resultsAvailable: equalsToComparator,
  design: equalsToComparator,
  startFinish: (
    _?: PoiFilterConfig,
    studyValue?: string | number,
    filterValue?: string | number,
  ) => {
    // ignore studies that have not finished
    if (studyValue === '–' || studyValue === 'N/A') return true;

    return (
      studyValue !== undefined &&
      filterValue !== undefined &&
      studyValue >= filterValue
    );
  },
  endFinish: (
    filterConfig?: PoiFilterConfig,
    studyValue?: string | number,
    filterValue?: string | number,
  ) => {
    // if the study finish value
    if (
      (studyValue === '–' || studyValue === 'N/A') &&
      !!filterValue &&
      Number(filterValue) === filterConfig?.dateRange.maxFinish
    ) {
      return true;
    }

    return (
      studyValue !== undefined &&
      filterValue !== undefined &&
      studyValue <= filterValue
    );
  },
  continent: equalsToComparator,
  politicalRegion: (
    _?: PoiFilterConfig,
    studyValue?: string | number,
    filterValue?: string | number,
  ) => {
    if (typeof studyValue !== 'string' || typeof filterValue !== 'string')
      return;

    // return simple comparison if study region does not include multiple values
    if (!studyValue.includes(','))
      return studyValue.toLowerCase() === filterValue.toLowerCase();

    return studyValue
      .split(',')
      .map(region => region.trim().toLowerCase())
      .includes(filterValue.toLowerCase());
  },
} as const;
