import type { FC } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useComparisonProviderContext } from '../../../providers/ComparisonProvider';
import type { HeatMapCountryMetaData } from '../../../../lcpn-uploader/server/services/parse-map-records/heat-map/heat-map-types';

import { HeatMapPicker } from './HeatMapPicker';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 120px)',
    background: '#F0F0F0',
    overflowY: 'scroll',
  },
  helpTextContainer: {
    background: '#3D434F',
    minHeight: '3.6875rem',
    maxHeight: '3.6875rem',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '0 1.25rem',
    fontSize: 15,
    justifyContent: 'space-between',
    alignContent: 'center',
  },
});

export const HeatMapComparisonPicker: FC = () => {
  const classes = useStyles();
  const [selectionOneCountryCode, setSelectionOneCountryCode] =
    useState<string>();
  const [selectionTwoCountryCode, setSelectionTwoCountryCode] =
    useState<string>();
  const { t } = useTranslation();

  const { setHeatMapComparison } = useComparisonProviderContext();

  const disableGenerateButton =
    !selectionOneCountryCode ||
    !selectionTwoCountryCode ||
    selectionOneCountryCode === selectionTwoCountryCode;

  return (
    <div className={classes.container}>
      <div className={classes.helpTextContainer}>
        <p>{t('comparisonPicker.heatMap.helpText')}</p>
        {!disableGenerateButton && (
          <Button
            onClick={() => {
              setHeatMapComparison({
                display: true,
                fromCountryCode:
                  selectionOneCountryCode as keyof HeatMapCountryMetaData,
                toCountryCode:
                  selectionTwoCountryCode as keyof HeatMapCountryMetaData,
              });
            }}
          >
            {t('comparisonPicker.heatMap.generateCta')}
          </Button>
        )}
      </div>
      <Box sx={{ margin: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <HeatMapPicker
              selectionNumber={1}
              onCountryCodeSelect={countryCode => {
                setSelectionOneCountryCode(countryCode);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HeatMapPicker
              selectionNumber={2}
              onCountryCodeSelect={countryCode => {
                setSelectionTwoCountryCode(countryCode);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          paddingBottom: '2rem',
        }}
      >
        <Button
          disabled={disableGenerateButton}
          onClick={() => {
            setHeatMapComparison({
              display: true,
              fromCountryCode:
                selectionOneCountryCode as keyof HeatMapCountryMetaData,
              toCountryCode:
                selectionTwoCountryCode as keyof HeatMapCountryMetaData,
            });
          }}
          sx={{
            mt: 2,
          }}
          color={'secondary'}
        >
          {t('comparisonPicker.heatMap.generateCta')}
        </Button>
      </Box>
    </div>
  );
};
