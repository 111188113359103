import type { FC, ReactNode, SetStateAction, Dispatch } from 'react';
import { createContext, useState, useMemo, useCallback } from 'react';

import type {
  HeatMapFilterOptions,
  HeatMapStatusFilterOption,
  HeatMapSexFilterOption,
  HeatMapRange,
} from './map-data-types';
interface Props {
  children: ReactNode;
}
interface FilteredHeatMapData {
  // needs refactoring (e.g. generic filter interface)
  filterOptions: HeatMapFilterOptions;
  updateFilterOptions: (newOptions: HeatMapFilterOptions) => void;
  // basic filtering (should be above)
  rangeType: HeatMapRange;
  setRangeType: Dispatch<SetStateAction<HeatMapRange>>;
  filterTypeStatus: HeatMapStatusFilterOption;
  setFilterTypeStatus: Dispatch<SetStateAction<HeatMapStatusFilterOption>>;
  filterTypeSex: HeatMapSexFilterOption;
  setFilterTypeSex: Dispatch<SetStateAction<HeatMapSexFilterOption>>;
  resetFilterOptions: VoidFunction;
}
type FilteredHeatMapDataUndef = FilteredHeatMapData | undefined;
export const FilteredHeatMapDataContext =
  createContext<FilteredHeatMapDataUndef>(undefined);

/**
 * FilteredHeatMapDataProvider
 *
 * Hold state on all things filtered map data. This includes:
 * Options:
 *   filterOptions: filters as set by the user
 *   updateFilterOptions: setter for the filter options
 * FilterMapData:
 *   filteredHeatMapData: filtered heat map data
 *
 * Both states kept in same provider as they will always update together.
 */
export const FilteredHeatMapDataProvider: FC<Props> = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<HeatMapFilterOptions>({}); // needs refactoring
  const [rangeType, setRangeType] = useState<HeatMapRange>('incidence');
  const [filterTypeStatus, setFilterTypeStatus] =
    useState<HeatMapStatusFilterOption>('AllStatuses');
  const [filterTypeSex, setFilterTypeSex] =
    useState<HeatMapSexFilterOption>('Combined');

  // updates filter options with a merge so consumer does not need to know
  // context of current state to update
  const updateFilterOptions = useCallback(
    (newPartialFilterOptions: Partial<HeatMapFilterOptions>) => {
      setFilterOptions(prevOptions => ({
        ...prevOptions,
        ...newPartialFilterOptions,
      }));

      if (newPartialFilterOptions.status) {
        setFilterTypeStatus(newPartialFilterOptions.status);
      }
    },
    [setFilterOptions],
  );

  const resetFilterOptions = useCallback(() => {
    setFilterOptions({});
    setFilterTypeStatus('AllStatuses');
    setFilterTypeSex('Combined');
    setRangeType('incidence');
  }, []);

  // memorize state object in case parent is re-rendered.
  const memorizedFilteredContextState = useMemo(
    () => ({
      updateFilterOptions,
      resetFilterOptions,
      filterOptions,
      filterTypeStatus,
      setRangeType,
      rangeType,
      setFilterTypeStatus,
      filterTypeSex,
      setFilterTypeSex,
    }),
    [
      updateFilterOptions,
      resetFilterOptions,
      filterOptions,
      filterTypeStatus,
      rangeType,
      filterTypeSex,
    ],
  );

  return (
    <FilteredHeatMapDataContext.Provider value={memorizedFilteredContextState}>
      {children}
    </FilteredHeatMapDataContext.Provider>
  );
};
