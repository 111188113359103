import type { FC } from 'react';
import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import type {
  PoiFilterOptions,
  PoiStudyData,
  StudiesById,
} from '../../map-data/map-data-types';

import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from './layers';
import { getRgb } from './get-rgb';

interface Props {
  filteredPoiStudyData: PoiStudyData | undefined;
  filteredStudiesById: StudiesById;
  filterOptions: PoiFilterOptions;
}

export const PinMapDataLayers: FC<Props> = (props: Props) => {
  const { filteredPoiStudyData, filteredStudiesById, filterOptions } = props;

  const studiesFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.Point> =
    useMemo(() => {
      return {
        type: 'FeatureCollection',
        features: filteredPoiStudyData?.studies.map(item => {
          const color =
            filteredStudiesById && getRgb(filteredStudiesById[item.id].design);
          return {
            type: 'Feature',
            properties: {
              ...item,
              color: `rgb(${color})`,
              border: `rgba(${color}, 0.3)`,
            },
            geometry: {
              type: 'Point',
              coordinates: [item.longitude, item.latitude, 0],
            },
          };
        }) as GeoJSON.Feature<GeoJSON.Point>[],
      };
    }, [filteredPoiStudyData, filteredStudiesById]);

  return (
    <Source
      type={'geojson'}
      data={studiesFeatureCollection}
      cluster={true}
      clusterMinPoints={3}
      clusterMaxZoom={50}
      clusterRadius={100}
      id={'data-studies'}
    >
      <Layer {...clusterLayer(filterOptions.design)} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
  );
};
