import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../Icon';
import type { PointOfInterestRecord } from '../../../../lcpn-uploader/types';

import type { Heading, Section } from './types';

export function usePoiDataTableDefinitions() {
  const { t } = useTranslation();

  const sections: Section<PointOfInterestRecord>[] = [
    {
      title: '',
      backgroundColor: '',
      rows: [
        {
          title: t('programmeRow.programmeDetail.acronym'),
          key: 'studyAcronym',
          fallback: '—',
        },
        {
          title: t('programmeRow.programmeDetail.region'),
          key: 'region',
        },
        {
          title: t('programmeRow.programmeDetail.design'),
          key: 'design',
          fallback: '—',
        },
        {
          title: t('programmeRow.programmeDetail.participantsTargeted'),
          key: 'targetParticipants',
        },
        {
          title: t('programmeRow.programmeDetail.participantsScreened'),
          key: 'screenedParticipants',
          fallback: '—',
        },
      ],
    },
    {
      icon: 'FurtherDetails',
      backgroundColor: '#9E1242',
      title: t('programmeRow.definitionTables.furtherDetail.title'),
      rows: [
        {
          title: t('programmeRow.definitionTables.furtherDetail.duration'),
          key: row => `${row.start} — ${row.finish}`,
        },
        {
          title: t('programmeRow.definitionTables.furtherDetail.centres'),
          key: 'studyCentersInCountry',
        },
        {
          title: t(
            'programmeRow.definitionTables.furtherDetail.screeningInterval',
          ),
          key: 'screeningInterval',
        },
        {
          title: t('programmeRow.definitionTables.furtherDetail.radiationDose'),
          key: 'radiationDoseReported',
        },
        {
          title: t('programmeRow.definitionTables.furtherDetail.researchFocus'),
          key: 'researchFocus',
        },
      ],
    },
    {
      icon: 'EligibilityChecker',
      title: t('programmeRow.definitionTables.eligibilityChecker.title'),
      backgroundColor: '#9E1242',
      rows: [
        {
          title: t('programmeRow.definitionTables.eligibilityChecker.ageRange'),
          key: row => `${row.minAge} - ${row.maxAge}`,
        },
        {
          title: t(
            'programmeRow.definitionTables.eligibilityChecker.smokingHistoryPYs',
          ),
          key: 'packYears',
        },
        {
          title: t(
            'programmeRow.definitionTables.eligibilityChecker.smokingHistoryYSQ',
          ),
          key: 'stoppedSmoking',
          format: v => {
            const value = Number.parseInt((v || '') as string);
            if (Number.isNaN(value)) {
              return v;
            }
            return t(
              'programmeRow.definitionTables.eligibilityChecker.yearsSince',
              {
                replace: {
                  count: value,
                },
              },
            );
          },
        },
        {
          title: t(
            'programmeRow.definitionTables.eligibilityChecker.neverSmokers',
          ),
          key: 'smokersNeverEligible',
        },
        {
          title: t(
            'programmeRow.definitionTables.eligibilityChecker.otherRiskFactors',
          ),
          key: 'otherCriteriaSummary',
        },
        {
          title: t(
            'programmeRow.definitionTables.eligibilityChecker.footnotesTitle',
          ),
          key: study =>
            study.minAge
              ?.concat(
                study.maxAge || '',
                study.packYears || '',
                study.stoppedSmoking || '',
              )
              .includes('*')
              ? study.smokingHistory
              : '',
        },
      ],
    },
    {
      icon: 'ParticipantOutcomes',
      title: t('programmeRow.definitionTables.participantOutcomes.title'),
      backgroundColor: '#9E1242',
      rows: [
        {
          title: t('programmeRow.definitionTables.participantOutcomes.year'),
          key: 'yearsReported',
        },
        {
          title: t('programmeRow.definitionTables.participantOutcomes.uptake'),
          key: 'screeningUptakeRate',
        },
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomes.confirmedCases',
          ),
          key: 'casesPositiveFindings',
        },
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomes.stageCases',
          ),
          key: 'stage1and2',
        },
      ],
      notes: [
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomes.footnotesTitle',
          ),
          body: t(
            'programmeRow.definitionTables.participantOutcomes.footnotesBody',
          ),
        },
      ],
    },
    {
      title: t(
        'programmeRow.definitionTables.participantOutcomesAdditional.title',
      ),
      backgroundColor: '#333740',
      rows: [
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomesAdditional.updated',
          ),
          key: row => (row.updated ? row.updated.toString() : ''),
        },
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomesAdditional.related',
          ),
          key: row => row.relatedEntries,
          fallback: '—',
        },
        {
          title: t(
            'programmeRow.definitionTables.participantOutcomesAdditional.references',
          ),
          typographyVariant: 'p3',

          key: 'references',
        },
      ],
    },
  ];

  const headings: Heading<PointOfInterestRecord>[] = [
    {
      backgroundColor: '#155181',
      color: 'white',
      typographyVariant: 'h3',
      key: item => (
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Icon width={32} height={32} variant={'MapInvertedBlue'} />
            <div>{item.city}</div>
          </Stack>
        </>
      ),
    },
    {
      backgroundColor: '#155181',
      color: 'white',
      typographyVariant: 'h4',
      key: 'country',
    },
    {
      backgroundColor: '#008B7F',
      color: 'white',
      typographyVariant: 'h4',
      key: 'studyName',
    },
    {
      backgroundColor: 'rgba(0, 139, 127, 0.6)',
      color: 'white',
      typographyVariant: 'p1',
      key: item =>
        t(
          `comparisonTable.poi.${
            item.status === 'Active' ? 'active' : 'inactive'
          }`,
        ),
    },
  ];

  return { headings, sections };
}
