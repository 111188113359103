import { createUseStyles } from 'react-jss';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@mui/material';

//import { Button } from '../Button';
import type { LcpnTheme } from '../../styles/theme';
import { useNavigationBarData } from '../NavigationBar/NavigationBarProvider';
import { usePoiFilterOptions } from '../../map-data/use-filter-options';
import { useHeatMapFilterOptions } from '../../map-data/use-heat-map-filter-options';

import { RangeFilter } from './RangeFilter';
import { MultiChoiceFilter } from './MultiChoiceFilter';
import { SelectFilter } from './SelectFilter';
import type { FilterConfig } from './filter-types';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'scroll',
  },

  filter: {
    backgroundColor: '#E3EBE9',
    padding: '0 8px 0 0',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    '& > div:last-child > div > div:last-child': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '& > button': {
        flexGrow: 1,
        minHeight: 26,
      },
    },
  },

  item: {
    width: '100%',
    '&&:last-child': {
      width: '100% !important',
    },
  },

  group: {
    display: 'flex',
    alignItems: 'center',
  },

  row: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `${theme.colors.white} solid 2px`,
  },

  clearFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    alignItems: 'center',
    padding: '16px 8px 24px 0px',
    marginLeft: 'auto',
    '& > button': {
      backgroundColor: theme.colors['green-100'],
      color: theme.colors.white,
    },
  },

  [`@media screen and (min-width: ${theme.breakPoints.tablet}px)`]: {
    filterContainer: {
      '&&': {
        maxHeight: 'calc(100vh - 147px)',
      },
    },
    filter: {
      '&&': {
        '& > div:last-child > div:first-child': {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    item: {
      '&&': {
        width: '50%',
      },
    },
    clearFilter: {
      '&&': {
        padding: '24px 0px 24px 0px',
      },
    },
  },

  [`@media screen and (min-width: ${theme.breakPoints.widescreen}px)`]: {
    item: {
      '&&': {
        width: '33.333%',
      },
    },
    clearFilter: {
      '&&': {
        padding: '24px 0px 24px 0px',

        marginLeft: 'auto',
      },
    },
  },

  [`@media screen and (min-height: 677px)`]: {
    filterContainer: {
      '&&': {
        overflow: 'hidden',
      },
    },
    filter: {
      '&&': {
        overflow: 'hidden',
      },
    },
  },
}));

// type guard to remove TS error if generic is not instantiated with an object
const isObject = (obj: unknown): obj is Record<string, unknown> =>
  String(obj) === '[object Object]' && !Array.isArray(obj) && obj !== null;

type Props = {
  filterConfig: FilterConfig;
  resetFilterOptions: VoidFunction;
};

export const Filter: FC<Props> = ({ filterConfig, resetFilterOptions }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { filterOptions: currentFiltersPoi } = usePoiFilterOptions();
  const { filterOptions: currentFiltersHeatMap } = useHeatMapFilterOptions();

  const { selectedNavigationItem } = useNavigationBarData();

  if (selectedNavigationItem !== 'filter') {
    return null;
  }

  const currentFilters = { ...currentFiltersPoi, ...currentFiltersHeatMap };

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filter}>
        {filterConfig
          .map(optionGroup =>
            optionGroup.map(filterConfig => {
              if (filterConfig.filterType === 'multiChoice') {
                return (
                  <MultiChoiceFilter
                    multiChoiceFilterConfig={filterConfig}
                    key={filterConfig.key}
                    selectedValue={
                      isObject(currentFilters)
                        ? (currentFilters[
                            filterConfig.key as keyof typeof currentFilters
                          ] as string)
                        : ''
                    }
                  />
                );
              }

              if (filterConfig.filterType === 'range') {
                return (
                  <RangeFilter
                    rangeFilterConfig={filterConfig}
                    key={filterConfig.key}
                    rangeFinish={currentFilters.endFinish}
                    rangeStart={currentFilters.startFinish}
                  />
                );
              }

              if (filterConfig.filterType === 'select') {
                // We have a select filter that is combining multiple filterable properties. We therefore
                // have to attempt to find the correct property that is currently filtered by the various keys
                const getSelectedValue = (): string => {
                  if (!isObject(currentFilters)) return '';

                  const filterType = filterConfig.key.find(
                    k => currentFilters[k as keyof typeof currentFilters],
                  );

                  if (!filterType) return '';

                  return (
                    (currentFilters[
                      filterType as keyof typeof currentFilters
                    ] as string) ?? ''
                  );
                };

                return (
                  <SelectFilter
                    selectFilterConfig={filterConfig}
                    key={filterConfig.key[0]}
                    selectedValue={getSelectedValue()}
                  />
                );
              }

              return;
            }),
          )
          .filter(Boolean)}
        <div className={classes.clearFilter}>
          <Button
            onClick={() => resetFilterOptions()}
            sx={{
              backgroundColor: `${theme.palette.grey['300']} !important`,
              color: `${theme.palette.grey[900]} !important`,
            }}
          >
            {t('filters.clearFilters')}
          </Button>
        </div>
      </div>
    </div>
  );
};
