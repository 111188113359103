import styled from 'styled-components';

const Rectangle = styled.div<{ color: string }>`
  width: 25px;
  height: 16px;
  background: ${({ color }) => color};
  opacity: 1;
`;

type Props = {
  color: string;
};

export function KeyColor(props: Props) {
  const { color } = props;
  return <Rectangle color={color} />;
}
