import type { GeoJSONSource, MapRef } from 'react-map-gl';
import { Map as MapBox, NavigationControl, useMap } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import type mapboxgl from 'mapbox-gl';

import { ExpandoBox } from '../ExpandoBox';
import { CountryCard } from '../Card/CountryCard';
import { CityCard } from '../Card/CityCard';
import { ProgrammeCard } from '../Card/ProgrammeCard';
import { useMapData } from '../../map-data/use-map-data';
import { usePoiFilterOptions } from '../../map-data/use-filter-options';
import { useFilterMapData } from '../../map-data/use-filter-poi-map-data';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import { Button } from '../Button';

import { PinMapLayers } from './PinMapLayers';
import { PinMapDataLayers } from './PinMapDataLayers';
import { clusterLayerId, unclusteredPointLayer } from './layers';

const useStyles = createUseStyles({
  filterButtons: {
    position: 'absolute',
    top: 8,
    left: 8,
    right: 50,
    display: 'flex',
    flexFlow: 'row',
    gap: 8,
    '& > button': {
      color: 'white',
    },
  },
  container: {
    position: 'relative',
  },
});

const mapBoxToken = process.env.REACT_APP_MAP_BOX_TOKEN;

interface Props {
  visible?: boolean;
}

const ZOOM_PROGRAMME_THRESHOLD = 12.5;

export function PinMap(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const studyDesigns = [
    {
      id: 0,
      label: t('pinMap.studyDesigns.seeAll'),
      value: undefined,
      color: '4, 139, 127',
      key: 'design',
      selected: true,
    },
    {
      id: 1,
      label: t('pinMap.studyDesigns.clinicalTrial'),
      value: 'Clinical trial',
      color: `75, 171, 214`,
      key: 'design',
      selected: false,
    },
    {
      id: 2,
      label: t('pinMap.studyDesigns.implementationStudy'),
      value: 'Implementation study',
      color: '158, 18, 66',
      key: 'design',
      selected: false,
    },
    {
      id: 3,
      label: t('pinMap.studyDesigns.regionalProgramme'),
      value: 'Regional programme',
      color: '244, 180, 50',
      key: 'design',
      selected: false,
    },
    {
      id: 4,
      label: t('pinMap.studyDesigns.nationalProgramme'),
      value: 'National programme',
      color: '222, 112, 107',
      key: 'design',
      selected: false,
    },
  ];

  const [expand, setExpand] = useState<boolean | null>(null);

  const { loading, poiCountryData, poiStudyData, poiCityData, poiRegionData } =
    useMapData();
  const { filteredPoiCityMetaData, filteredStudiesById, filteredPoiStudyData } =
    useFilterMapData();
  const { poiMap } = useMap();

  const {
    selectedProgramId,
    setSelectedProgramId,
    selectedCountryName,
    setSelectedCountryName,
    selectedCountrySubDivisionName,
    setSelectedCountrySubDivisionName,
    showCountryCard,
    setShowCountryCard,
    showCityCard,
    setShowCityCard,
    showProgramCard,
    setShowProgramCard,
  } = usePoiSelectedLocations();

  const { resetNavigationBar } = useUpdateNavigationBar();
  const { updateFilterOptions, filterOptions } = usePoiFilterOptions();

  const selectedCountry = useMemo(
    () => poiCountryData?.countries?.find(c => c.name === selectedCountryName),
    [poiCountryData, selectedCountryName],
  );

  const selectedCity = useMemo(
    () =>
      poiCityData?.cities?.find(c => c.name === selectedCountrySubDivisionName),
    [poiCityData, selectedCountrySubDivisionName],
  );

  const selectedRegion = useMemo(
    () =>
      poiRegionData?.regions?.find(
        c => c.name === selectedCountrySubDivisionName,
      ),
    [poiRegionData?.regions, selectedCountrySubDivisionName],
  );

  const selectedStudy = useMemo(
    () => poiStudyData?.studies.find(e => e.id === selectedProgramId),
    [poiStudyData, selectedProgramId],
  );

  const [filterButtons, setFilterButtons] = useState(studyDesigns);

  const mapRef = useRef<MapRef>(null);
  const handleMapClick = (event: mapboxgl.MapLayerMouseEvent) => {
    if (!event.features) {
      return null;
    }
    const feature = event.features[0];
    if (!feature) return null;
    if (!feature.properties) return null;

    if (feature.properties.cluster) {
      const clusterId = feature.properties.cluster_id;

      const mapboxSource = mapRef.current?.getSource(
        'data-studies',
      ) as GeoJSONSource;

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        if (feature.geometry.type === 'Point') {
          mapRef.current?.easeTo({
            center: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ],
            zoom,
            duration: 500,
          });
        }

        /*
         */
      });
    } else {
      const { properties: program } = feature;
      setSelectedProgramId(program.id);
      setSelectedCountryName(program.country);
      setSelectedCountrySubDivisionName({
        city: program.city,
        region: program.region,
      });
      setShowCityCard(false);
      setShowCountryCard(false);
      setShowProgramCard(true);
    }
  };

  const handleButtonFocus = (id: number) => {
    setFilterButtons(
      filterButtons.map(study =>
        study.id === id
          ? { ...study, selected: !study.selected }
          : { ...study, selected: false },
      ),
    );
    resetNavigationBar();
  };

  // reset map is resized when it becomes visible
  useEffect(() => {
    if (props.visible) {
      poiMap?.resize();
    }
  }, [props.visible, poiMap]);

  // fly to the study being viewed
  useEffect(() => {
    if (selectedStudy) {
      poiMap?.flyTo({
        center: [selectedStudy.longitude, selectedStudy.latitude],
        essential: true,
        zoom: ZOOM_PROGRAMME_THRESHOLD,
        screenSpeed: 2,
      });
    }
  }, [selectedStudy, poiMap]);

  // fly to the city being viewed
  useEffect(() => {
    let boundaryBox;

    if (
      selectedCity?.name.toLowerCase() === 'national' &&
      selectedCountry?.boundaryBox
    ) {
      // just focus on the country
      boundaryBox = selectedCountry?.boundaryBox;
    } else if (selectedCity?.boundaryBox) {
      // it would be nice to use the bounds of the markers, have than the city boundary
      boundaryBox = selectedCity?.boundaryBox;
    }

    if (boundaryBox) {
      poiMap?.fitBounds(
        [
          [boundaryBox[0] ?? 0, boundaryBox[1] ?? 0],
          [boundaryBox[2] ?? 0, boundaryBox[3] ?? 0],
        ],
        { padding: 40, duration: 1000 },
      );
    }
  }, [selectedCity, selectedCountry, poiMap]);

  // fly to the region being viewed
  useEffect(() => {
    let boundaryBox;

    if (
      selectedRegion?.name.toLowerCase() === 'national' &&
      selectedCountry?.boundaryBox
    ) {
      // just focus on the country
      boundaryBox = selectedCountry?.boundaryBox;
    } else if (selectedRegion?.boundaryBox) {
      // it would be nice to use the bounds of the markers, have than the city boundary
      boundaryBox = selectedRegion?.boundaryBox;
    }

    if (boundaryBox) {
      poiMap?.fitBounds(
        [
          [boundaryBox[0] ?? 0, boundaryBox[1] ?? 0],
          [boundaryBox[2] ?? 0, boundaryBox[3] ?? 0],
        ],
        { padding: 40, duration: 1000 },
      );
    }
  }, [selectedRegion, selectedCountry, poiMap]);

  // fly to the country being viewed
  useEffect(() => {
    const { boundaryBox } = selectedCountry ?? {};

    if (boundaryBox && showCountryCard) {
      poiMap?.fitBounds(
        [
          [boundaryBox[0] ?? 0, boundaryBox[1] ?? 0],
          [boundaryBox[2] ?? 0, boundaryBox[3] ?? 0],
        ],
        { padding: 50, duration: 1000 },
      );
    }
  }, [selectedCountry, poiMap, showCountryCard]);

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    const pulseMarker = () => {
      if (!shouldAnimate || !mapRef.current) return;

      const map = mapRef.current.getMap();

      const radius = map.getPaintProperty(
        unclusteredPointLayer.id,
        'circle-radius',
      );

      map.setPaintProperty(
        unclusteredPointLayer.id,
        'circle-radius',
        radius > 10 ? 8 : 11,
      );

      const stroke = map.getPaintProperty(
        clusterLayerId,
        'circle-stroke-width',
      );

      map.setPaintProperty(
        clusterLayerId,
        'circle-stroke-width',
        stroke > 8 ? 8 : 12,
      );
    };

    const intervalTimer = setInterval(pulseMarker, 1000);

    return () => clearInterval(intervalTimer);
  }, [shouldAnimate]);

  const [cursor, setCursor] = useState<string>('auto');
  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  if (
    loading ||
    !poiCountryData ||
    !filteredPoiCityMetaData ||
    !poiStudyData ||
    !filteredStudiesById ||
    !poiCityData
  ) {
    // handle loading here
    return null;
  }

  const interactiveLayerIds = [clusterLayerId, unclusteredPointLayer.id].filter(
    item => !!item,
  );

  return (
    <div id="point-of-interest-map-wrapper" className={classes.container}>
      <MapBox
        initialViewState={{
          longitude: 0,
          latitude: 20,
          zoom: 0.7,
        }}
        id="poiMap"
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/lcpn-interactive-map/cl70hv5t8000v14p7f89wptcu"
        mapboxAccessToken={mapBoxToken}
        dragRotate={false}
        maxZoom={16}
        minZoom={0.7}
        // onClick={resetNavigationBar}
        interactiveLayerIds={interactiveLayerIds}
        onClick={handleMapClick}
        ref={mapRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onSourceData={() => {
          setShouldAnimate(true);
        }}
        cursor={cursor}
      >
        <div className={classes.filterButtons}>
          {filterButtons.map((study, index) => (
            <Button
              selected={study.selected}
              key={index}
              label={study.label}
              bgColor={study.color}
              value={study.value}
              onClick={() => {
                updateFilterOptions({ [study.key]: study.value });
                handleButtonFocus(index);
              }}
            />
          ))}
        </div>

        <PinMapLayers />
        <NavigationControl showCompass={false} />

        <PinMapDataLayers
          filteredPoiStudyData={filteredPoiStudyData}
          filteredStudiesById={filteredStudiesById}
          filterOptions={filterOptions}
        />

        {showProgramCard &&
          selectedProgramId &&
          filteredStudiesById[selectedProgramId] && (
            <ExpandoBox
              inset={'7em 0px 10px 30px'}
              expand={expand}
              width={300}
              minHeight={'30em'}
            >
              <ProgrammeCard
                cityName={filteredStudiesById[selectedProgramId].city}
                countryName={filteredStudiesById[selectedProgramId].country}
                implementation={filteredStudiesById[selectedProgramId].design}
                information={
                  filteredStudiesById[selectedProgramId].description ?? ''
                }
                closeCard={value => {
                  setShowProgramCard(value);
                  setExpand(false);
                }}
                handleExpand={value => setExpand(!!value)}
                region={filteredStudiesById[selectedProgramId].region}
                expand={expand}
              />
            </ExpandoBox>
          )}

        {showCityCard &&
          selectedCity &&
          filteredPoiCityMetaData[selectedCity.name].length > 0 && (
            <div style={{ position: 'absolute', top: '20%', left: '2.25%' }}>
              <CityCard
                cityName={selectedCity?.name}
                countryName={selectedCity?.countryLocated}
                totalNationalProgrammes={selectedCity.nationalProgrammes ?? 0}
                totalRegionalProgrammes={selectedCity.regionalProgrammes ?? 0}
                totalStudiesProgrammes={selectedCity.studyProgrammes ?? 0}
                totalClinicalProgrammes={selectedCity.clinicalProgrammes ?? 0}
                closeCard={value => {
                  setShowCityCard(value);
                }}
              />
            </div>
          )}

        {showCountryCard && selectedCountry && (
          <div style={{ position: 'absolute', top: '20%', left: '2.25%' }}>
            <CountryCard
              countryName={selectedCountry.name}
              countryCode={selectedCountry.iso3166_1_2_Code}
              totalNationalProgrammes={selectedCountry.nationalProgrammes ?? 0}
              totalRegionalProgrammes={selectedCountry.regionalProgrammes ?? 0}
              totalClinicalProgrammes={selectedCountry.clinicalProgrammes ?? 0}
              totalStudiesProgrammes={selectedCountry.studyProgrammes ?? 0}
              closeCard={value => {
                setShowCountryCard(value);
              }}
            />
          </div>
        )}
      </MapBox>
    </div>
  );
}
