export const SubDivision = {
  city: 'city',
  region: 'region',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SubDivision = (typeof SubDivision)[keyof typeof SubDivision];

// in the country list view, if the country has more than this studies it lists regions instead of cities
export const STUDIES_TO_SHOW_REGION = 11;
export const POI_COUNTRY_LIST_ID = 'poi-country-list';
export const POI_COUNTRY_LIST_ITEM_ID_PREFIX = 'poi-country-list-item';
export const POI_SUBDIVISION_LIST_ITEM_ID_PREFIX = 'poi-subdivision-list-item';
export const POI_STUDY_LIST_ITEM_ID_PREFIX = 'poi-study-list-item';
export const POI_STUDY_LIST_HEADER_KEY = 'poi-study-list-header';
