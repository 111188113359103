import type { FC } from 'react';

import { PoiStudyComparisonPicker } from './ComparisonPicker/PoiStudyComparisonPicker';
import { HeatMapComparisonPicker } from './ComparisonPicker/HeatMapComparisonPicker';
import { ComparisonTable } from './ComparisonTable';

interface Props {
  isPinMapView: boolean;
  showPoiTable: boolean;
  showHeatMapTable: boolean;
}

export const ComparisonTool: FC<Props> = ({
  isPinMapView,
  showPoiTable,
  showHeatMapTable,
}) => {
  if (isPinMapView && showPoiTable) {
    return <ComparisonTable type={'poi'} />;
  }

  if (isPinMapView) {
    return <PoiStudyComparisonPicker />;
  }

  if (showHeatMapTable) {
    return <ComparisonTable type={'heatmap'} />;
  }

  return <HeatMapComparisonPicker />;
};
