import { clsx } from 'clsx';
import { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import type { LcpnTheme } from '../../styles/theme';
import { useFilterMapData } from '../../map-data/use-filter-poi-map-data';
import { useMapData } from '../../map-data/use-map-data';
import { usePoiSelectedLocations } from '../PointOfInterestExperience/PoiSelectedLocationsProvider';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { Scrollbar } from '../Scrollbar';
import { useUpdateNavigationBar } from '../NavigationBar/NavigationBarProvider';
import { usePoiFilterOptions } from '../../map-data/use-filter-options';

import { SubDivisionList } from './SubDivisionList';
import {
  POI_COUNTRY_LIST_ID,
  POI_COUNTRY_LIST_ITEM_ID_PREFIX,
  STUDIES_TO_SHOW_REGION,
  SubDivision,
} from './constants';
import { getCountryListScrollContainer } from './get-list-scroll-container';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    overflow: 'hidden',
    height: '100%',
  },

  listHeading: {
    backgroundColor: theme.colors['grey-10'],
    borderBottom: `${theme.colors['grey-80']} solid 1px`,
    height: '2.4375rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.8125rem',
    boxSizing: 'border-box',
    fontWeight: '700',
    marginBottom: '0.5rem',
    justifyContent: 'space-between',
  },

  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 13px',
    height: '3.0625rem',
    cursor: 'pointer',
    '& > div': {
      borderBottom: `${theme.colors['white']} solid 1px`,
    },
    '& > div:last-child': {
      borderBottom: `none`,
    },
    '&:hover': {
      backgroundColor: theme.colors['green-100'],
      color: theme.colors.white,
    },
    '&:focus-visible': {
      outline: 'auto',
    },
    borderBottom: `${theme.colors['white']} solid 2px`,
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  listCollapse: {
    backgroundColor: theme.colors['green-10'],
  },

  listExpand: {
    backgroundColor: theme.colors['green-100'],
    color: theme.colors.white,
    borderBottom: 'none',
    '& > div': {
      borderBottom: 'none',
    },
  },

  icon: {
    marginRight: 32,
  },

  row: {
    display: 'flex',
    flexDirection: 'column',

    marginBottom: 0,
  },
}));

export interface CountryCount {
  country: number;
  [SubDivision.region]: Record<string, number>;
  [SubDivision.city]: Record<string, number>;
}

export function PointOfInterestCountryList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, poiCountryData, poiCityData } = useMapData();
  const { filteredPoiStudyData } = useFilterMapData();
  const { resetFilterOptions: resetPoiMapFilters, filterOptions } =
    usePoiFilterOptions();
  const {
    selectedCountryName,
    setSelectedCountryName,
    setShowCountryCard,
    setShowProgramCard,
    setShowCityCard,
    resetExperience,
  } = usePoiSelectedLocations();
  const { resetNavigationBar } = useUpdateNavigationBar();

  const hasFiltersApplied = Object.keys(filterOptions).length > 0;

  const uniqueStudyCountries = useMemo(() => {
    const filteredCountries = filteredPoiStudyData?.studies.map(
      study => study.country,
    );
    const dedupedCountries = [...new Set(filteredCountries)];
    return dedupedCountries.sort((countryA, countryB) =>
      countryA < countryB ? -1 : 1,
    );
  }, [filteredPoiStudyData]);

  const { filteredSubDivisions, subDivisionType } = useMemo<{
    filteredSubDivisions?: string[];
    subDivisionType?: SubDivision;
  }>(() => {
    if (!selectedCountryName)
      return {
        filteredSubDivisions: undefined,
      };

    const countryStudies = filteredPoiStudyData?.studies.filter(study => {
      return study.country === selectedCountryName;
    });

    const hasLargeStudySet =
      (countryStudies?.length ?? 0) > STUDIES_TO_SHOW_REGION;

    const countrySubDivision = [
      ...new Set(
        countryStudies?.map(study => {
          if (hasLargeStudySet) return study.region;
          return study.city;
        }),
      ),
    ];

    const filteredSubDivisions = countrySubDivision?.sort((a, b) => {
      if (a.toLowerCase() === 'national') {
        return -1;
      }

      if (b.toLowerCase() === 'national') {
        return 1;
      }

      return a < b ? -1 : 1;
    });

    return {
      filteredSubDivisions,
      subDivisionType: hasLargeStudySet ? SubDivision.region : SubDivision.city,
    };
  }, [filteredPoiStudyData, selectedCountryName]);

  const studyCount = useMemo(() => {
    if (!filteredPoiStudyData) return {};
    return (
      // eslint-disable-next-line unicorn/no-array-reduce
      filteredPoiStudyData?.studies.reduce(
        (accumulator: Record<string, CountryCount>, study) => {
          const countryCount = accumulator[study.country]?.country ?? 0;
          const regionCount =
            accumulator[study.country]?.[SubDivision.region]?.[study.region] ??
            0;
          const cityCount =
            accumulator[study.country]?.[SubDivision.city]?.[study.city] ?? 0;

          accumulator[study.country] = {
            ...accumulator[study.country],
            country: countryCount + 1,
            [SubDivision.region]: {
              ...accumulator[study.country]?.[SubDivision.region],
              [study.region]: regionCount + 1,
            },
            [SubDivision.city]: {
              ...accumulator[study.country]?.[SubDivision.city],
              [study.city]: cityCount + 1,
            },
          };

          return accumulator;
        },
        {},
      )
    );
  }, [filteredPoiStudyData]);

  if (loading || !poiCountryData || !poiCityData) {
    // handle loading here
    return null;
  }

  const handleToggle = (name: string) => {
    setSelectedCountryName(name === selectedCountryName ? '' : name);
    setShowCountryCard(true);
    setShowProgramCard(false);
    setShowCityCard(false);
    resetNavigationBar();
  };

  const handleReset = () => {
    resetExperience();
    resetPoiMapFilters();
    getCountryListScrollContainer()?.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  return (
    <div
      className={classes.list}
      id="point-of-interest-country-navigation-list"
    >
      <div className={classes.listHeading}>
        <Typography variant="p2">
          {t('countryListView.counter', {
            defaultValue: 'Showing {{ count }} countries with entries',
            replace: {
              count: uniqueStudyCountries?.length,
            },
          })}
        </Typography>
        {(!!selectedCountryName || hasFiltersApplied) && (
          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={() => handleReset()}
          >
            <strong>{t('countryListView.reset').toUpperCase()}</strong>
          </Button>
        )}
      </div>
      <Scrollbar>
        <ul id={POI_COUNTRY_LIST_ID}>
          {uniqueStudyCountries?.map((studyCountry, index) => (
            <li className={classes.row} key={studyCountry}>
              <button
                className={clsx(
                  classes.listItem,
                  {
                    [classes.listCollapse]:
                      studyCountry !== selectedCountryName,
                  },
                  {
                    [classes.listExpand]: studyCountry === selectedCountryName,
                  },
                )}
                id={`${POI_COUNTRY_LIST_ITEM_ID_PREFIX}-${index}`}
                onClick={() => {
                  handleToggle(studyCountry);
                }}
                key={studyCountry}
              >
                <div className={classes.group}>
                  <Icon
                    variant={
                      studyCountry === selectedCountryName
                        ? 'CollapseList'
                        : 'ExpandList'
                    }
                    fill="#fff"
                    stroke="#6a6a6a"
                    width={24}
                    height={24}
                  />
                  <>
                    <Typography variant="h3">{studyCountry}</Typography>
                    <Typography variant="p1">
                      ({studyCount[studyCountry].country ?? ''})
                    </Typography>
                  </>
                </div>
              </button>
              {studyCountry === selectedCountryName &&
                filteredSubDivisions &&
                subDivisionType && (
                  <SubDivisionList
                    subDivisions={filteredSubDivisions}
                    subDivisionType={subDivisionType}
                    studyCountry={studyCountry}
                    studyCountByCountry={studyCount}
                    countryIndex={index}
                  />
                )}
            </li>
          ))}
        </ul>
      </Scrollbar>
    </div>
  );
}
