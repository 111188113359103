import { useContext, useMemo } from 'react';

import { FilteredHeatMapDataContext } from './FilteredHeatMapDataProvider';

/**
 * useFilterOptions
 *
 * Simple hook to expose the filter options as held in the FilteredMapDataContext
 */
export const useHeatMapFilterOptions = () => {
  const filteredMapData = useContext(FilteredHeatMapDataContext);

  if (!filteredMapData) {
    throw new Error(
      'useMapData hook it being used outside of the MapDataProvider',
    );
  }

  const {
    updateFilterOptions,
    filterOptions,
    resetFilterOptions,
    setRangeType,
    rangeType,
    setFilterTypeSex,
    filterTypeSex,
    setFilterTypeStatus,
    filterTypeStatus,
  } = useMemo(
    () => ({
      updateFilterOptions: filteredMapData.updateFilterOptions,
      filterOptions: filteredMapData.filterOptions,
      resetFilterOptions: filteredMapData.resetFilterOptions,
      setRangeType: filteredMapData.setRangeType,
      rangeType: filteredMapData.rangeType,
      setFilterTypeSex: filteredMapData.setFilterTypeSex,
      filterTypeSex: filteredMapData.filterTypeSex,
      setFilterTypeStatus: filteredMapData.setFilterTypeStatus,
      filterTypeStatus: filteredMapData.filterTypeStatus,
    }),
    [
      filteredMapData.updateFilterOptions,
      filteredMapData.filterOptions,
      filteredMapData.resetFilterOptions,
      filteredMapData.setRangeType,
      filteredMapData.rangeType,
      filteredMapData.setFilterTypeSex,
      filteredMapData.filterTypeSex,
      filteredMapData.setFilterTypeStatus,
      filteredMapData.filterTypeStatus,
    ],
  );

  return {
    updateFilterOptions,
    filterOptions,
    resetFilterOptions,
    setRangeType,
    rangeType,
    setFilterTypeSex,
    filterTypeSex,
    setFilterTypeStatus,
    filterTypeStatus,
  };
};
