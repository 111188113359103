import algoliasearch from 'algoliasearch/lite';

const algoliaProjectId = process.env.REACT_APP_ALGOLIA_PROJECT_ID ?? '';
const algoliaApiToken = process.env.REACT_APP_ALGOLIA_API_KEY ?? '';

if (!algoliaProjectId || !algoliaApiToken) {
  console.error(
    'Missing algolia credentials. Please add or search functionality will not work',
  );
}

export const algoliaSearchClient = algoliasearch(
  algoliaProjectId,
  algoliaApiToken,
);
