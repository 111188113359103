import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack } from '@mui/material';

import { Typography } from '../../Typography';
import type { PointOfInterestRecord } from '../../../../lcpn-uploader/types';

import { ReactComponent as Logo } from './lcpn-logo.svg';
import { DataDisplayTable } from './DataTable';
import { usePoiDataTableDefinitions } from './UsePoiDataTableDefinitions';
import { Disclaimer } from './Disclaimer';
import { Footer } from './Footer';

export function PoiPrintTable(props: { study: PointOfInterestRecord }) {
  const { t } = useTranslation();
  const { study } = props;

  const { sections, headings } = usePoiDataTableDefinitions();

  return (
    <Stack spacing={4}>
      <Box>
        <Stack spacing={4}>
          <div style={{ color: '#008B7F' }}>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={4}
            >
              <Grid item xs={4}>
                <Logo />
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'h2'}>
                  <strong>{t('comparisonTable.poi.title')}</strong>
                </Typography>
              </Grid>
            </Grid>
          </div>
          {!!study && (
            <DataDisplayTable<PointOfInterestRecord>
              headings={headings}
              type={'poi'}
              items={study}
              sections={sections}
            />
          )}
        </Stack>
      </Box>
      <Disclaimer />
      <Footer />
    </Stack>
  );
}
