import type { FC } from 'react';
import { Source, Layer } from 'react-map-gl';

import { useMapData } from '../../map-data/use-map-data';
import { useFilterMapData } from '../../map-data/use-filter-poi-map-data';

const generateLayerProps = (key: number, values: string[]) => ({
  filter: [
    'all',
    [
      'any',
      ['==', 'all', ['get', 'worldview']],
      ['in', 'US', ['get', 'worldview']],
    ],
    ['match', ['get', 'iso_3166_1_alpha_3'], values, true, false],
  ],
  id: `country-boundaries-${key}`,
  'source-layer': 'country_boundaries',
  paint: { 'fill-color': '#128478', 'fill-opacity': 0.2 },
  type: 'fill' as const,
});

export const PinMapLayers: FC = () => {
  const { loading } = useMapData();
  const { filteredStudiesById } = useFilterMapData();

  if (!filteredStudiesById || loading) {
    // handle loading state here.
    return null;
  }

  const filteredMapData = [
    ...new Set(
      Object.values(filteredStudiesById).map(
        study => study.countryIso3166_1_3Name,
      ),
    ),
  ];

  return (
    <Source type="vector" url="mapbox://mapbox.country-boundaries-v1">
      {filteredMapData.length > 0 && (
        // TODO: Customer to supply data-set with ISO 3 letter column
        //       for the study data, and then update to consume here.
        <Layer {...generateLayerProps(10, filteredMapData)} />
      )}
    </Source>
  );
};
