import type { FC } from 'react';
import { createUseStyles } from 'react-jss';

import type { LcpnTheme } from '../../styles/theme';

import styledUpdateTabSvgUrl from './update-tab.svg';
import starIcon from './star-icon.svg';
import { useVirtualTourConfig } from './VirtualTourProvider';
import { extendedVirtualTour, virtualTour } from './shepherd-tours';

const useStyles = createUseStyles((theme: LcpnTheme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 16,
    zIndex: 1000,
    right: '25%',
    marginRight: 68.5,
    height: '33px',
  },
  [`@media screen and (max-width: ${theme.breakPoints.desktop}px)`]: {
    wrapper: {
      display: 'none',
    },
  },
  extendTourButton: {
    position: 'absolute',
    bottom: 5,
    margin: 'auto',
    padding: 0,
    left: 53.5,
    height: '24px',
    width: '30px',
    cursor: 'pointer',
  },
  styledTabImage: {
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
  },
  notificationCircle: {
    position: 'absolute',
    margin: 'auto',
    left: 73.5,
    height: '0.5rem',
    width: '0.5rem',
    borderRadius: '0.25rem',
    background: '#F23B5C',
    bottom: '1.4rem',
  },
}));

export const TourUpdateTab: FC = () => {
  const classes = useStyles();
  const { hasUpdates, isReady, refreshExtendedTour } = useVirtualTourConfig();

  return (
    <div className={classes.wrapper}>
      <img
        src={styledUpdateTabSvgUrl}
        alt="updateTab"
        className={classes.styledTabImage}
      />
      <button
        className={classes.extendTourButton}
        onClick={() => {
          if (!isReady) return;

          if (!hasUpdates && !virtualTour.isActive()) {
            virtualTour.start();
          }

          if (hasUpdates && !extendedVirtualTour.isActive()) {
            refreshExtendedTour();
            extendedVirtualTour.start();
          }
        }}
        id="extended-virtual-tour-tab"
        style={{ cursor: isReady ? 'pointer' : 'default' }}
        disabled={!isReady}
      >
        <img src={starIcon} alt="star icon" />
      </button>
      {hasUpdates && <div className={classes.notificationCircle} />}
    </div>
  );
};
