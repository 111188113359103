import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useMapData } from '../../../map-data/use-map-data';
import type { LcpnTheme } from '../../../styles/theme';
import { Icon } from '../../Icon';
import { HeatMapSearch } from '../../HeatMapSearch';
import { HeatMapSearchResults } from '../../HeatMapSearch/HeatMapSearchResults';
import { HeatMapResultItem } from '../../HeatMapSearch/HeatMapResultItem';

import { PickerSelect } from './PickerSelect';
import {
  getHeatMapComparisonLocalStorage,
  setHeatMapComparisonLocalStorage,
} from './use-comparison-local-storage';

interface PickerProps {
  selectionNumber: 1 | 2;
  onCountryCodeSelect: (countryCode: string, selectionNumber: number) => void;
}

const usePickerStyles = createUseStyles((theme: LcpnTheme) => ({
  pickerContainer: {
    height: '17.9375rem',
    background: 'white',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '14rem',
  },
  picker: {
    background: theme.colors['blue-100'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    height: '100%',
    width: '100%',
  },
  pickerActionText: {
    paddingTop: '1rem',
    color: 'white',
    fontSize: 20,
  },
  selectorHeader: {
    background: theme.colors['blue-100'],
    height: '2.875rem',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '0 1.25rem',
    fontSize: 15,
    justifyContent: 'space-between',
  },
  selectorContainer: {
    margin: '1rem',
    position: 'relative',
  },
  searchWrapper: {
    minHeight: '2.5rem',
  },
  dropDownsWrapper: {
    background:
      'linear-gradient(0deg, #E1E9E8, #E1E9E8), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #008B7F',
    height: '9.625rem',
    marginTop: '1rem',
    padding: '2rem',
    boxSizing: 'border-box',
    '& > div:not(:first-child)': {
      paddingTop: '2rem',
    },
  },
}));

const HeatMapSearchResultsList: FC<{
  onItemClick: (countryCode: string) => void;
}> = ({ onItemClick }) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();

  return (
    <HeatMapSearchResults
      resultItemComponent={props => (
        <HeatMapResultItem
          {...props}
          onResultClick={props => {
            onItemClick(props);
            setIndexUiState({
              ...indexUiState,
              query: undefined,
            });
          }}
        />
      )}
    />
  );
};

export const HeatMapPicker: FC<PickerProps> = ({
  selectionNumber,
  onCountryCodeSelect,
}) => {
  const classes = usePickerStyles();
  const { countryHeatMapMetaData } = useMapData();
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [initialised, setInitialised] = useState<boolean>(false);

  const [isPickingStudy, setIsPickingStudy] = useState(!!selectedCountryCode);
  const { t } = useTranslation();

  const countryCodes = useMemo(
    () => Object.keys(countryHeatMapMetaData ?? {}),
    [countryHeatMapMetaData],
  );

  useEffect(() => {
    const heatMapComparisonInLocalStorage = getHeatMapComparisonLocalStorage();
    const val =
      heatMapComparisonInLocalStorage[selectionNumber - 1]?.countryCode ?? '';
    setSelectedCountryCode(val);
    setIsPickingStudy(!!val);
    setInitialised(true);
  }, [selectionNumber]);

  useEffect(() => {
    if (!initialised) return;
    const currentLocalStorage = getHeatMapComparisonLocalStorage();

    currentLocalStorage[selectionNumber - 1] = {
      countryCode: selectedCountryCode ?? null,
    };

    setHeatMapComparisonLocalStorage(currentLocalStorage);
  }, [selectedCountryCode, selectionNumber, initialised]);

  useEffect(() => {
    onCountryCodeSelect(selectedCountryCode, selectionNumber);
  }, [onCountryCodeSelect, selectedCountryCode, selectionNumber]);

  if (!isPickingStudy) {
    return (
      <div className={classes.pickerContainer}>
        <button
          className={classes.picker}
          onClick={() => setIsPickingStudy(true)}
        >
          <Icon variant="Add" width={64} height={64} />
          <p className={classes.pickerActionText}>
            {t('comparisonPicker.heatMap.picker.addEntry')}
          </p>
        </button>
      </div>
    );
  }

  if (!countryHeatMapMetaData) {
    return (
      <div className={classes.pickerContainer}>
        <div className={classes.selectorHeader}>
          <p>
            {t('comparisonPicker.heatMap.picker.title', { selectionNumber })}
          </p>
        </div>
        <div className={classes.loadingContainer}>
          <CircularProgress size={60} disableShrink />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.pickerContainer}>
      <div className={classes.selectorHeader}>
        <p>{t('comparisonPicker.heatMap.picker.title', { selectionNumber })}</p>
      </div>
      <div className={classes.selectorContainer}>
        <div className={classes.searchWrapper}>
          <HeatMapSearch
            placeholder={t('comparisonPicker.heatMap.picker.searchPlaceHolder')}
          >
            <HeatMapSearchResultsList
              onItemClick={countryCode => {
                setSelectedCountryCode(countryCode);
              }}
            />
          </HeatMapSearch>
        </div>
        <div className={classes.dropDownsWrapper}>
          <PickerSelect
            onUpdate={countryCode => {
              setSelectedCountryCode(countryCode);
            }}
            onClear={() => setSelectedCountryCode('')}
            label={t('comparisonPicker.heatMap.picker.countryLabel')}
            placeholder={t(
              'comparisonPicker.heatMap.picker.countryPlaceholder',
            )}
            selectedValue={selectedCountryCode}
            options={countryCodes.map(code => ({
              label: countryHeatMapMetaData[code].countryName,
              value: code,
            }))}
          />
        </div>
      </div>
    </div>
  );
};
