export const getRgb = (design: string) => {
  switch (design) {
    case 'Regional programme': {
      return '244, 180, 50';
    } // gold
    case 'Implementation study': {
      return '158, 18, 66';
    } // red
    case 'Clinical trial': {
      return '75, 171, 214';
    } // blue
    default: {
      return '222, 112, 107';
    } // coral
  }
};
