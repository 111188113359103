import type { AnyLayer } from 'mapbox-gl';

import type { PoiFilterOptionsDesign } from '../../map-data/map-data-types';

export const clusterLayerId = 'clusters';

const getColorByStudyType = (studyType?: PoiFilterOptionsDesign) => {
  switch (studyType) {
    case 'Clinical trial': {
      return '75, 171, 214';
    }
    case 'Regional programme': {
      return '244, 180, 50';
    }
    case 'Implementation study': {
      return '158, 18, 66';
    }
    case 'National programme': {
      return '222, 112, 107';
    }
    default: {
      return '4, 139, 127';
    }
  }
};

export const clusterLayer = (studyType?: PoiFilterOptionsDesign) => {
  const clusterColor = getColorByStudyType(studyType);

  return {
    id: clusterLayerId,
    type: 'circle',
    source: 'data-studies',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        `rgb(${clusterColor})`,
        100,
        `rgb(${clusterColor})`,
        750,
        `rgb(${clusterColor})`,
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        20,
        5,
        25,
        15,
        30,
        20,
        35,
        30,
        40,
        50,
        50,
      ],
      'circle-stroke-width-transition': { duration: 1000 },
      'circle-stroke-width': 8,
      'circle-stroke-color': `rgba(${clusterColor}, 0.3)`,
    },
  } as AnyLayer;
};

export const clusterCountLayer: AnyLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'data-studies',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 14,
  },
  paint: {
    'text-color': '#ffffff',
  },
};

export const unclusteredPointLayer: AnyLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'data-studies',

  filter: ['!', ['has', 'point_count']],

  paint: {
    'circle-radius-transition': { duration: 1000 },
    'circle-color': ['get', 'color'],
    'circle-radius': 12,
    'circle-stroke-width': 8,
    'circle-stroke-color': ['get', 'border'],
  },
};
